import { isEmailValidate, IsValidMobile, IsValidOTP, IsValidPassword } from './../validation';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomLoaderService } from '../services/custom-loader.service';
import { MainHeaderMenuService } from '../services/main-header-menu.service';
import { EncrdecrService } from './../Auth/encrdecr.service';
import { LoginService } from '../services/login.service';
import { ClientDataService } from '../services/client-data.service';
import { environment } from 'src/environments/environment';
import { isNullOrUndefined } from '../validation';
declare var $: any;

@Component({
  selector: 'app-login-nse',
  templateUrl: './login-nse.component.html',
  styleUrls: ['./login-nse.component.scss']
})
export class LoginNseComponent implements OnInit {

  UserPassword: string = "";
  txtLength = 0;
  LoginForm: FormGroup;
  LoginSubmitted: boolean = false;
  UserType: number = 3;
  IsShowLoginOption: boolean = true;
  WelcomeText: any = '';
  logo: string;
  NavHeight: string;
  IsMobile: boolean = false;
  logoWidth: string;
  logoHeight: string;
  IsAllowLogin: boolean = true;
  MobileLoginForm: FormGroup;
  isMobileLoginFormSubbmitted: boolean = false;
  isMobileListDisplay: boolean = false;
  EmailLoginForm: FormGroup;
  isEmailFormSubbmitted: boolean = false;
  isEmailListDisplay: boolean = false;
  ClientData: any = [];
  ClientDataBasedOnEmail: any = [];
  utm_source: string;
  characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  constructor(private LoginService: LoginService,
    private EncrdecrService: EncrdecrService,
    private ShowHideHeaderMenu: MainHeaderMenuService,
    private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private Loader: CustomLoaderService,
    private ClientDataService: ClientDataService) {
    this.ShowHideHeaderMenu.hide();
    this.LoginForm = this.formBuilder.group({
      Username: ['', Validators.required],
      //Password1: ['', [Validators.required]],
      Password: ['', [Validators.required, Validators.pattern(IsValidPassword)]],
    });

    this.MobileLoginForm = this.formBuilder.group({
      Mobile: ['', [Validators.required, Validators.pattern(IsValidMobile)]],
      MobileOTP: ['', [Validators.required, Validators.pattern(IsValidOTP)]],
    });

    this.EmailLoginForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.pattern(isEmailValidate)]],
      EmailOTP: ['', [Validators.required, Validators.pattern(IsValidOTP)]],
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.SetViewPort(event.target.innerWidth)
  }
  ngOnInit() {
    this.route.queryParams.subscribe((queryParam) => {
      if (queryParam.utm_source != undefined) {
        this.utm_source = queryParam.utm_source;
        sessionStorage.setItem("3erT@Qr65TdXwq1", this.utm_source);
      }
    });
    this.SetViewPort(window.innerWidth);
    sessionStorage.setItem('nvjsd212djskd$#21ksdj', 'C')
    this.WelcomeText = this.ClientDataService.GetWelcomeText();
    this.logo = sessionStorage.getItem('logo');

    this.getCompanyDetails();
    const CorporateFag = sessionStorage.getItem('Vbhs@#%LMKIo=');
    if (CorporateFag == 'C') {
      //$('.MainOuter').css('min-height', window.innerHeight - 75);
      sessionStorage.removeItem('F44sGAGh2xwkpUL'); // Session_SubBrokerId
      sessionStorage.removeItem('Hldq31TLYwRbLJ8'); // Session_SubBrokerCode
      sessionStorage.removeItem('UZT6qHaDZSz66kx'); // Session_ClientBasicInfoId
      sessionStorage.removeItem('N1QJHdOkO7Hz8QC'); // Session_ClientBasicInfoId
      sessionStorage.removeItem('L2D3506kIHSk3E0'); // Session_UserType
      sessionStorage.removeItem('pOWgAF62qFXnZMn'); // Session_Razor
      sessionStorage.removeItem('W113TKilrF1u1gL'); // ucc code - client
      sessionStorage.removeItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
      sessionStorage.removeItem('nbj%98LS74nkjsn~@&4njrkd`!kd*294');
      sessionStorage.removeItem('hn0bHyGhfpGtrPe'); // family id
      sessionStorage.removeItem('E#$%#XCha7%*=');
      // sessionStorage.removeItem('Vbhs@#%LMKIo=');

      if (sessionStorage.getItem('Mnuoisns6wqgswj2$#') != null &&
        sessionStorage.getItem('Mnuoisns6wqgswj2$#') != "") {
        if (sessionStorage.getItem('Mnuoisns6wqgswj2$#').toString() == "1") {
          this.IsAllowLogin = false;
        }
        else {
          this.IsAllowLogin = true;
        }

      }

    }
    else {
      return this.router.parseUrl("**");
    }
  }

  get j() {
    return this.MobileLoginForm.controls;
  }

  get e() {
    return this.EmailLoginForm.controls;
  }
  get r() {
    return this.LoginForm.controls;
  }
  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ResendOtpToMobile() {
    let mobile = this.MobileLoginForm.controls["Mobile"].value;
    if (mobile == "") {
      this.CallAlertModal("Please Enter Mobile Number");
    } else {
      if (mobile.length == 10) {
        var pattern = new RegExp(IsValidMobile);
        var result = pattern.test(mobile);
        console.log(result);
        if (result == true) {
          this.Loader.show();
          const MobileRequest = {
            "FullName": "",
            "VERIFICATION_ID": 'ClientOTPLoginMobile',
            "USER_TYPE": "3",
            "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
            "Trans_Type": "Mobile",
            "Flag": "G",
          };
          var json = JSON.stringify(MobileRequest);
          this.sendMobileOTP(json);

        } else {
          this.CallAlertModal("Invalid Mobile Number");
          this.MobileLoginForm.reset();
        }
      } else {
        this.CallAlertModal("Invalid Mobile Number");
      }
    }
  }

  SendOtpToMobile() {
    let mobile = this.MobileLoginForm.controls["Mobile"].value;
    if (mobile.length == 10) {
      var pattern = new RegExp(IsValidMobile);
      var result = pattern.test(mobile);
      console.log(result);
      if (result == true) {
        this.Loader.show();
        const MobileRequest = {
          "FullName": "",
          "VERIFICATION_ID": 'ClientOTPLoginMobile',
          "USER_TYPE": "3",
          "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
          "Trans_Type": "Mobile",
          "Flag": "G",
        };
        var json = JSON.stringify(MobileRequest);
        this.sendMobileOTP(json);

      } else {
        this.CallAlertModal("Invalid Mobile Number");
        this.MobileLoginForm.reset();
      }
    } else {
      this.CallAlertModal("Invalid Mobile Number");
      this.MobileLoginForm.reset();
    }
  }

  sendMobileOTP(MobileRequest: any) {
    this.LoginService.JMOTPMobile(MobileRequest).subscribe(
      (response: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(response))

        this.Loader.hide();
        if (res.Message == "Success") {
          this.CallAlertModal("OTP sent to your mobile no...")
        } else if (res.Message == "Overlimit") {
          this.CallAlertModal("Overlimit")
        } else {
          this.CallAlertModal("OTP operation Faild...")
        }
      }, (err: any) => {
        console.log(err);
      })
  }

  onClickReSendEmailOTP() {
    let Email = this.EmailLoginForm.controls["Email"].value;
    if (Email == "") {
      this.CallAlertModal("Please enter email address.")
    } else {
      var pattern = new RegExp(isEmailValidate);
      var result = pattern.test(Email);
      if (result == true) {
        this.Loader.show();
        const EmailRequest = {
          "FullName": "",
          "VERIFICATION_ID": 'ClientOTPLoginEmail',
          "USER_TYPE": "3",
          "EmailId": this.EmailLoginForm.controls["Email"].value,
          "Trans_Type": 'EmailID',
          "Flag": "G"
        }
        var json = JSON.stringify(EmailRequest);
        this.SendEmailOTP(json);
      } else {
        this.CallAlertModal("Invalid Email Address")
      }
    }
  }

  SendOtpToEmail() {
    let Email = this.EmailLoginForm.controls["Email"].value;
    if (Email == "") {
      this.CallAlertModal("Please enter email address.")
    } else {
      var pattern = new RegExp(isEmailValidate);
      var result = pattern.test(Email);
      if (result == true) {
        this.Loader.show();
        const EmailRequest = {
          "FullName": this.EmailLoginForm.controls["Email"].value,
          "VERIFICATION_ID": 'ClientOTPLoginEmail',
          "USER_TYPE": "3",
          "EmailId": this.EmailLoginForm.controls["Email"].value,
          "Trans_Type": 'EmailID',
          "Flag": "G"
        }
        var json = JSON.stringify(EmailRequest);
        this.SendEmailOTP(json);
      } else {
        this.CallAlertModal("Please enter valid email address..")
      }
    }
  }

  SendEmailOTP(EmailRequest: any) {
    this.LoginService.JMOTPEmail(EmailRequest).subscribe(
      (response: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(response))

        this.Loader.hide();
        if (res.Message == "Success") {
          this.CallAlertModal("OTP sent to your EmailID...")
        } else if (res.Message == "Overlimit") {
          this.CallAlertModal("Overlimit")
        } else {
          this.CallAlertModal("OTP operation Faild...")
        }
      }, (err: any) => {
        console.log(err);
      })
  }

  OnMobileLogin() {
    let RandomKey = ''
    RandomKey = this.generateRandomString(6)
    this.isMobileLoginFormSubbmitted = true;
    if (this.MobileLoginForm.invalid) {
      return
    } else {
      this.Loader.show();
      const MobileVerifyRequest = {
        "FullName": "",
        "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
        "Trans_Type": "Mobile",
        "Flag": "V",
        "OTP": this.MobileLoginForm.controls["MobileOTP"].value,
        "RandomKey" : RandomKey
      };
      var json = JSON.stringify(MobileVerifyRequest);
      this.LoginService.JMOTPMobile(json).subscribe(
        (response: any) => {
          let res = JSON.parse(this.EncrdecrService.getDecr(response))
          let key = ''
          let message = ''
          key = res.Message.split('|')[0]
          message = res.Message.split('|')[1]
          this.Loader.hide();
          if (message == "Verified" && key == RandomKey) {
            this.CallAlertModal("OTP Verified Successfully");
            this.Loader.show();
            const json = {
              "MobileNo": this.MobileLoginForm.controls["Mobile"].value,
              "Email": "",
              "flag": "BasedOnMobile",
              "UserType" : this.UserType
            }
            let data = JSON.stringify(json)
            this.LoginService.fetchClientDetailsBasedOnMobileOrEmail(data).subscribe(
              (response: any) => {
                let res = JSON.parse(this.EncrdecrService.getDecr(response))
                debugger;
                this.Loader.hide();
                if (res.ArrayOfResponse != null && res.ArrayOfResponse != undefined) {
                  //if (res.ArrayOfResponse.length === 1 || res.ArrayOfResponse.length >= 1) {
                  if (res.ArrayOfResponse.length === 1) {
                    let password = this.EncrdecrService.getDecr(res.ArrayOfResponse[0].Password)
                    const json = {
                      "Username": res.ArrayOfResponse[0].PANNo,
                      "Password": password
                    }
                    let databody = JSON.stringify(json);
                    this.callAuthenticationController(databody)
                  } else {
                    if (res.ArrayOfResponse.length === 0) {
                      this.CallAlertModal("unauthorized user Sorry...please contact Branch.");
                      this.MobileLoginForm.reset();
                    } else {
                      this.ClientData = res.ArrayOfResponse
                      this.isMobileListDisplay = true;
                      $('#AlertModal').modal('hide');
                      $('#AlertModalLogin').modal({
                        backdrop: 'static',
                        keyboard: false
                      });
                    }
                  }
                }
              }, (err: any) => {
                console.log(err);
                this.Loader.hide();
              })
          } else if (res.Message == "OTP Code is Expired") {
            this.Loader.hide();
            this.CallAlertModal("OTP Code is Expired")
            this.isMobileListDisplay = false;
          } else if (res.Message == "User Not Exist") {
            this.Loader.hide();
            this.CallAlertModal(res.Message);
            this.MobileLoginForm.reset();
            this.isMobileListDisplay = false;
          } else if (res.Message == "Overlimit") {
            this.Loader.hide();
            this.CallAlertModal("Overlimit!! please try after 5 minutes.");
            this.MobileLoginForm.reset();
            this.isMobileListDisplay = false;
          } else if (res.Message == "Invalid OTP Code") {
            this.Loader.hide();
            this.CallAlertModal("Invalid OTP Code");
            this.MobileLoginForm.reset();
            this.isMobileListDisplay = false;
          } else {
            this.Loader.hide();
            this.CallAlertModal("Verified operation Faild...")
            this.MobileLoginForm.reset();
            this.isMobileListDisplay = false;
          }
        }, (err: any) => {
          console.log(err);
        })
    }
  }

  onEmailLoginClick() {
    debugger;
    let RandomKey = ''
    RandomKey = this.generateRandomString(6)
    this.isEmailFormSubbmitted = true;
    if (this.EmailLoginForm.invalid) {
      return
    } else {
      this.Loader.show();
      let Email = this.EmailLoginForm.controls["Email"].value;
      const EmailVerifyRequest = {
        "FullName": "",
        "EmailId": this.EmailLoginForm.controls["Email"].value,
        "Trans_Type": "EmailID",
        "Flag": "V",
        "OTP": this.EmailLoginForm.controls["EmailOTP"].value,
        "RandomKey" : RandomKey
      };
      var json = JSON.stringify(EmailVerifyRequest);
      this.LoginService.JMOTPEmail(json).subscribe(
        (response: any) => {
          let res = JSON.parse(this.EncrdecrService.getDecr(response))
          let key = ''
          let message = ''
          key = res.Message.split('|')[0]
          message = res.Message.split('|')[1]
          this.Loader.hide();
          debugger;
          if (message == "Verified" && key == RandomKey) {
            this.CallAlertModal("OTP Verified Successfully");
            const json = {
              "MobileNo": "",
              "Email": this.EmailLoginForm.controls["Email"].value,
              "flag": "BasedOnEmail",
              "UserType" : this.UserType
            }
            let data = JSON.stringify(json)
            this.Loader.show();
            this.LoginService.fetchClientDetailsBasedOnMobileOrEmail(data).subscribe(
              (response: any) => {
                let res = JSON.parse(this.EncrdecrService.getDecr(response))
                debugger;
                this.Loader.hide();
                if (res.ArrayOfResponse != null && res.ArrayOfResponse != undefined) {
                  //if (res.ArrayOfResponse.length === 1 || res.ArrayOfResponse.length >= 1) {
                  if (res.ArrayOfResponse.length === 1) {
                    let password = this.EncrdecrService.getDecr(res.ArrayOfResponse[0].Password)
                    const json = {
                      "Username": res.ArrayOfResponse[0].PANNo,
                      "Password": password
                    }
                    let databody = JSON.stringify(json);
                    this.callAuthenticationController(databody)
                  } else {
                    if (res.ArrayOfResponse.length === 0) {
                      this.CallAlertModal("unauthorized user Sorry contact Branch.");
                      this.EmailLoginForm.reset();
                    } else {
                      this.ClientDataBasedOnEmail = res.ArrayOfResponse
                      this.isEmailListDisplay = true;
                      $('#AlertModal').modal('hide');
                      $('#AlertModalLoginEmail').modal({
                        backdrop: 'static',
                        keyboard: false
                      });
                    }
                  }
                }
              }, (err: any) => {
                console.log(err);
                this.Loader.hide();
              })
          } else if (res.Message == "OTP Code is Expired") {
            this.Loader.hide();
            this.CallAlertModal("OTP Code is Expired");
            this.isEmailListDisplay = false;
          } else if (res.Message == "User Not Exist") {
            this.Loader.hide();
            this.CallAlertModal(res.Message);
            this.EmailLoginForm.reset();
            this.isEmailListDisplay = false;
          } else if (res.Message == "Overlimit") {
            this.Loader.hide();
            this.CallAlertModal("Overlimit!! please try after 5 minutes.");
            this.EmailLoginForm.reset();
            this.isEmailListDisplay = false;
          } else if (res.Message == "Invalid OTP Code") {
            this.Loader.hide();
            this.CallAlertModal("Invalid OTP Code");
            this.EmailLoginForm.reset();
            this.isEmailListDisplay = false;
          } else {
            this.Loader.hide();
            this.CallAlertModal("Verified operation Faild...")
            this.EmailLoginForm.reset();
            this.isEmailListDisplay = false;
          }
        }, (err: any) => {
          console.log(err);
        })
    }
  }

  OnClickClientSelection(clientDetails: any) {
    if (clientDetails != undefined && clientDetails != null) {
      this.Loader.show();
      let password = this.EncrdecrService.getDecr(clientDetails.Password)
      const json = {
        "Username": clientDetails.PANNo,
        "Password": password
      }
      let databody = JSON.stringify(json);
      this.callAuthenticationController(databody)
    } else {
      this.Loader.hide();
      this.CallAlertModal("Something goes Wrong");
    }
  }

  BasedonEmailSelection(clientDetails: any) {
    if (clientDetails != undefined && clientDetails != null) {
      this.Loader.show();
      let password = this.EncrdecrService.getDecr(clientDetails.Password)
      const json = {
        "Username": clientDetails.PANNo,
        "Password": password
      }
      let databody = JSON.stringify(json);
      this.callAuthenticationController(databody)
    } else {
      this.CallAlertModal("Something goes Wrong");
    }
  }

  SetViewPort(w) {
    if (w <= 767) {
      this.IsMobile = true;
    }
    else {
      this.IsMobile = false;
    }
  }
  // ngAfterViewInit() {
  //   $('.passwordtxt').on('input', (e: any) => this.ontypePassword(e));
  // };
  // ontypePassword(e) {
  //   //console.log(e.target.value);
  //   var getPassVal = e.target.value;
  //   if (this.txtLength > getPassVal.length) {
  //     if (getPassVal.length == 0) {
  //       this.UserPassword = "";
  //       this.txtLength = 0;
  //       this.LoginForm.controls['Password'].setValue(this.UserPassword);
  //     }
  //     else {
  //       this.txtLength = getPassVal.length;
  //       this.UserPassword = this.UserPassword.slice(0, -1);
  //       this.LoginForm.controls['Password'].setValue(this.UserPassword);
  //     }
  //   }
  //   else {
  //     this.txtLength = getPassVal.length;
  //     var OneWord = getPassVal[getPassVal.length - 1]
  //     this.UserPassword = this.UserPassword + OneWord;
  //     this.LoginForm.controls['Password'].setValue(this.UserPassword);
  //     if (this.LoginForm.controls.Password1.value != '$') {
  //       var strReplaceString = this.LoginForm.controls.Password1.value;
  //       strReplaceString = strReplaceString.replace(/[^$]/g, "");
  //       // $('#<%=txtPassword.ClientID%>').val(strReplaceString + '$');
  //       this.LoginForm.controls['Password1'].setValue(strReplaceString + '$');
  //     }
  //     else {
  //       //$('#<%=txtPassword.ClientID%>').val($('#<%=txtPassword.ClientID%>').val() + '$');
  //       this.LoginForm.controls['Password1'].setValue(this.LoginForm.controls.Password1.value + '$')
  //     }
  //   }
  // }
  onSubmit() {
    this.LoginSubmitted = true;
    if (this.LoginForm.invalid) {
      return;
    }
    else {

      this.Loader.show();
      const DataBody = JSON.stringify(this.LoginForm.value);
      sessionStorage.setItem('Tgsh@#3734fjfskshh==', '173.212.234.56');
      let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
      this.LoginService.PostLogin_NSE_Info(DataBody, this.UserType, IpAddress).subscribe(
        (res: any) => {
          debugger;
          if (res != "Invalid Username and Password" && res.TOKEN_ID != null) {
            sessionStorage.setItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF', this.EncrdecrService.setEncr("1"));
            sessionStorage.setItem('YKHkxDnoSoOe6PdTjKrqfm4z6', this.EncrdecrService.setEncr("19"));
            sessionStorage.setItem('`huR,8@RK9^??b4 -.hfs!!uR,XX', res.TOKEN_ID);
            sessionStorage.setItem('F44sGAGh2xwkpUL', res.LOGIN_ID); // Session_SubBrokerId
            sessionStorage.setItem('V4WGROuLLBE6t98', res.USER_NAME); // Session_UserName
            sessionStorage.setItem('V8hpPfITcxhEI9x', res.EMAIL_ID); // Session_UserEmail
            sessionStorage.setItem('3erT@Qr65TdXwq1', res.LOGIN_ID);//Added by Bhushan
            sessionStorage.setItem('Xe003SRRyYEoOC2', res.MOBILE); // Session_UserMobile
            // let login_id = this.EncrdecrService.getDecr(res.LOGIN_ID)
            // let SubBrokerCode = this.EncrdecrService.getDecr(res.SubBrokerCode);
            // let EmpCode = this.EncrdecrService.getDecr(res.EmployeeCode);
            // let USER_ID = this.EncrdecrService.getDecr(res.USER_ID)
            //this.ShowHideHeaderMenu.show();
            sessionStorage.setItem('pOWgAF62qFXnZMn', ''); // RazorCustId
            sessionStorage.setItem('Hldq31TLYwRbLJ8', res.SubBrokerCode); // Session_SubBrokerCode
            sessionStorage.setItem('L2D3506kIHSk3E0', res.USER_TYPE); // Session_UserType
            sessionStorage.setItem('qAkZrQYLWNBSlki', res.LOGIN_ID); // Session_CBId
            sessionStorage.setItem('pi4ZU2P3M8JE4q7', ''); // Session_B64CompanyLogo
            sessionStorage.setItem('SBLoginBase64PP', res.SBLogoBase64); //IFD Logo : Added by Saddam

            if (res.Family_ID !== null && res.Family_ID !== undefined && res.Family_ID !== 'null') {
              sessionStorage.setItem('hn0bHyGhfpGtrPe', res.Family_ID);  // Session_Family_ID
            }
            let ut = res.USER_TYPE;
            if (ut != undefined && ut != null && ut != '') {
              if (this.EncrdecrService.getDecr(ut) == '3') {
                sessionStorage.setItem('m5JkoXISmYRAIuY', res.USER_ID); // Session_UserId
                sessionStorage.setItem('UZT6qHaDZSz66kx', res.LOGIN_ID); // Session_ClientBasicInfoId
                sessionStorage.setItem('N1QJHdOkO7Hz8QC', res.LOGIN_ID);
                sessionStorage.setItem('ySxterqMDG7RY2qTpuzrfA', this.EncrdecrService.setEncr("1"));
                sessionStorage.setItem('AqVk7klfa+b0nlVjO8Y90I=', res.RiskProfile); //Aggressive/Moderate etc
                sessionStorage.setItem('aaIHl0pIiYe71gyyxC/5xA==', res.RiskProfileStatus);//Active or Expired
                sessionStorage.setItem('LWHTWnSDkv4+qaQXsO6tHYU=', res.RiskProfileLevel); //risk Level of client
                sessionStorage.setItem('6l0NqN5kkIZCaDvdzUfYVK8g=', res.RiskProfileInsertedDate)

                if (this.utm_source == res.LOGIN_ID) {
                  sessionStorage.setItem("3erT@Qr65TdXwq1", res.LOGIN_ID);
                  this.router.navigate(["/Client-Profile-Preview/Preview"]);
                }
                else {
                  window.location.href = '/dashboard';
                }
              }
              else {
                this.CallAlertModal('Please enter valid username and password');
              }
            }
            else {
              this.CallAlertModal('Please enter valid username and password');
            }
          }
          else {
            this.CallAlertModal('Please enter valid username and password');
          }
          this.Loader.hide();
        },
        (err: any) => {
          const rr = err.error;
          const er = rr.substring(1, rr.length - 1);
          const rk = er.split(', ');
          this.Loader.hide();
          this.CallAlertModal('Please enter valid username and password');
        }
      );
    }
  }
  SendUserType() {
    sessionStorage.setItem('L2D3506kIHSk3E0', this.EncrdecrService.setEncr(this.UserType.toString()));
    sessionStorage.setItem('sdfkjo2352lkj', "ForgotPass");
  }
  CallAlertModal(msg: any) {
    $('#AlertModal').modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#AlertModalContent').text(msg);
  }
  getCompanyDetails() {
    this.Loader.show();
    this.ClientDataService.getCompanyDetails(environment.CompanyId).subscribe((data: any) => {
      this.Loader.hide();
      if (data != null && data.ArrayOfResponse.length != 0) {
        let rk = data.ArrayOfResponse[0];
        sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("0"));
        sessionStorage.setItem('Mnuoisns6wqgswj2$#', "0");
        sessionStorage.setItem('E#$%#XCha7%*=', this.EncrdecrService.getDecr(rk.Dummy2));
        sessionStorage.setItem('Vbhs@#%LMKIo=', this.EncrdecrService.getDecr(rk.Dummy1));
        sessionStorage.setItem('DufjGDH3843JE', this.EncrdecrService.getDecr(rk.Dummy4));
        sessionStorage.setItem('PfdkSJDNWks2323Ms', this.EncrdecrService.getDecr(rk.Dummy3));
        sessionStorage.setItem('Arh34SDBJSSiF', this.EncrdecrService.getDecr(rk.Dummy5));
        sessionStorage.setItem('Fhd63asja$%Wsdjs9304#@dfvdjfjd', this.EncrdecrService.getDecr(rk.Dummy6));
        sessionStorage.setItem('%^&s8g9b!@fla%g', this.EncrdecrService.getDecr(rk.Dummy7));
        if (!isNullOrUndefined(this.EncrdecrService.getDecr(rk.Dummy10))) {
          sessionStorage.setItem('dsa&hdhbc%ube=', this.EncrdecrService.getDecr(rk.Dummy10));
        } else {
          sessionStorage.setItem('dsa&hdhbc%ube=', 'false');
        }
        sessionStorage.setItem('cIe+MhBUozWO3+mKZd2to=', this.EncrdecrService.getDecr(rk.EnableRiskProfile));
      }
    });
  }
  callAuthenticationController(DataBody: any) {
    sessionStorage.setItem('Tgsh@#3734fjfskshh==', '173.212.234.56');
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    this.LoginService.PostLogin_NSE_Info(DataBody, this.UserType, IpAddress).subscribe(
      (res: any) => {
        if (res != "Invalid Username and Password" && res.TOKEN_ID != null) {
          sessionStorage.setItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF', this.EncrdecrService.setEncr("1"));
          sessionStorage.setItem('YKHkxDnoSoOe6PdTjKrqfm4z6', this.EncrdecrService.setEncr("19"));
          sessionStorage.setItem('`huR,8@RK9^??b4 -.hfs!!uR,XX', res.TOKEN_ID);
          sessionStorage.setItem('F44sGAGh2xwkpUL', res.LOGIN_ID); // Session_SubBrokerId
          sessionStorage.setItem('V4WGROuLLBE6t98', res.USER_NAME); // Session_UserName
          sessionStorage.setItem('V8hpPfITcxhEI9x', res.EMAIL_ID); // Session_UserEmail

          sessionStorage.setItem('Xe003SRRyYEoOC2', res.MOBILE); // Session_UserMobile
          sessionStorage.setItem('pOWgAF62qFXnZMn', ''); // RazorCustId
          sessionStorage.setItem('Hldq31TLYwRbLJ8', res.SubBrokerCode); // Session_SubBrokerCode
          sessionStorage.setItem('L2D3506kIHSk3E0', res.USER_TYPE); // Session_UserType
          sessionStorage.setItem('qAkZrQYLWNBSlki', res.LOGIN_ID); // Session_CBId
          sessionStorage.setItem('pi4ZU2P3M8JE4q7', ''); // Session_B64CompanyLogo
          if (res.Family_ID !== null && res.Family_ID !== undefined && res.Family_ID !== 'null') {
            sessionStorage.setItem('hn0bHyGhfpGtrPe', res.Family_ID);  // Session_Family_ID
          }
          let ut = res.USER_TYPE;
          if (ut != undefined && ut != null && ut != '') {
            if (this.EncrdecrService.getDecr(ut) == '3') {
              sessionStorage.setItem('m5JkoXISmYRAIuY', res.USER_ID);
              sessionStorage.setItem('UZT6qHaDZSz66kx', res.LOGIN_ID); // Session_ClientBasicInfoId
              sessionStorage.setItem('N1QJHdOkO7Hz8QC', res.LOGIN_ID);
              sessionStorage.setItem('ySxterqMDG7RY2qTpuzrfA', this.EncrdecrService.setEncr("1"));

              sessionStorage.setItem('AqVk7klfa+b0nlVjO8Y90I=', res.RiskProfile); //Aggressive/Moderate etc
              sessionStorage.setItem('aaIHl0pIiYe71gyyxC/5xA==', res.RiskProfileStatus);//Active or Expired
              sessionStorage.setItem('LWHTWnSDkv4+qaQXsO6tHYU=', res.RiskProfileLevel); //risk Level of client
              sessionStorage.setItem('6l0NqN5kkIZCaDvdzUfYVK8g=', res.RiskProfileInsertedDate)
              this.Loader.hide();
              window.location.href = '/dashboard';
            }
            else {
              this.CallAlertModal('Please enter valid username and password');
            }
          }
          else {
            this.CallAlertModal('Please enter valid username and password');
          }
        }
        else {
          this.CallAlertModal('Please enter valid username and password');
        }
        this.Loader.hide();
      },
      (err: any) => {
        const rr = err.error;
        const er = rr.substring(1, rr.length - 1);
        const rk = er.split(', ');
        this.Loader.hide();
        this.CallAlertModal('Please enter valid username and password');
      }
    );
  }

  generateRandomString(length) {
    let result = '';
    const charactersLength = this.characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += this.characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
}
