
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderPreLoginComponent } from '../header-pre-login/header-pre-login.component';
import { FooterComponent } from '../footer/footer.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RouterModule } from '@angular/router';
import { CuttoffTimingModalComponent } from './cuttoff-timing-modal/cuttoff-timing-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CuttoffTimingModalBseComponent } from './cuttoff-timing-modal-bse/cuttoff-timing-modal-bse.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

import { NgOtpInputModule } from 'ng-otp-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OtpComponent } from './otp/otp.component';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ClientProfileModelComponent } from './client-profile-model/client-profile-model.component';
import { AddNomineeComponent } from './add-nominee/add-nominee.component';
import { RiskprofileModelComponent } from './riskprofile-model/riskprofile-model.component';
import { DigitalKYCModelComponent } from './digital-kyc-model/digital-kyc-model.component';
import { ProfilePreviewModelComponent } from './profile-preview-model/profile-preview-model.component';
import { ClientsFilterComponent } from './clients-filter/clients-filter.component';
import { ExploreFundsFilterPipe } from '../filters/explore-funds-filter.pipe';
import { ExploreFundsNsePipe } from '../filters/explore-funds-nse.pipe';
import { HeaderJmComponent } from './header-jm/header-jm.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { ReportFiltersComponent } from './report-filters/report-filters.component';
@NgModule({
  declarations: [
    HeaderPreLoginComponent,
    FooterComponent,
    ContactUsComponent,
    ConfirmationDialogComponent,
    CuttoffTimingModalComponent,
    CuttoffTimingModalBseComponent,
    TermsAndConditionsComponent,
    OtpComponent,
    ClientProfileModelComponent,
    AddNomineeComponent,
    RiskprofileModelComponent,
    DigitalKYCModelComponent,
    ProfilePreviewModelComponent,
    ClientsFilterComponent,
    ExploreFundsFilterPipe,
    ExploreFundsNsePipe,
    HeaderJmComponent,
    ReportFiltersComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule.forRoot(), // ModalModule comes from ngx-bootstrap
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports:[
    HeaderPreLoginComponent,
    FooterComponent,
    ContactUsComponent,
    ConfirmationDialogComponent,
    CuttoffTimingModalComponent,
    CuttoffTimingModalBseComponent,
    TermsAndConditionsComponent,
    OtpComponent,
    ClientProfileModelComponent,
    AddNomineeComponent,
    RiskprofileModelComponent,
    DigitalKYCModelComponent,
    ProfilePreviewModelComponent,
    ClientsFilterComponent,
    ExploreFundsFilterPipe,
    ExploreFundsNsePipe,
    HeaderJmComponent,
    ReportFiltersComponent
  ],
  entryComponents: [ConfirmationDialogComponent, OtpComponent,AddNomineeComponent,RiskprofileModelComponent,DigitalKYCModelComponent,ProfilePreviewModelComponent],
  providers: [OtpComponent,ConfirmationDialogComponent,AddNomineeComponent,RiskprofileModelComponent,DigitalKYCModelComponent,ProfilePreviewModelComponent]
})
export class SharedModule { }
