import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exploreFundsNse',
  pure: false //true makes it pure and false makes it impure
})


export class ExploreFundsNsePipe implements PipeTransform{
  transform(
    value: any,
    SchemeOption :string,//SchemePlanType: string,
    SchemeType :string, //FundNatureCategory: string,
    FundCategoryName: any,
    RiskId: any,
    InvestmentHorizon: any,
    InputReturn: number,
    SearchFund: string,
    SortingBy: number,
    //IsFavorite: any,
    IsNFO: boolean,
    IsPreferred :boolean,
    Is100RsSip :boolean,
    CurrentShowRecordCountDesk:number,
    SubCategoyResult:any
  ): any {

    //if (IsFavorite === undefined) return value;
    //if (IsNFO === undefined) return value;
    //if (CurrentShowRecordCountDesk === undefined) return value;
    ////////
    let ArrSchemePlanType = [];
    let ArrFundCategory = [];
    let FinalArray = [];

    if (SchemeOption === "") {
      return value;
    } else {
      if(value.length > 0){
      value.forEach(SingleSchemePlanFund => {
        if (SingleSchemePlanFund.SchemeOption === SchemeOption) {
          ArrSchemePlanType.push(SingleSchemePlanFund);
        }
      });

      let Arr_FundCategoryName = FundCategoryName.split(",");
      let TempFinalArr: any;
      if (Arr_FundCategoryName[0] == 'All' && Arr_FundCategoryName.length == 1) {
        TempFinalArr = this.MakeRiskArray(
          ArrSchemePlanType,
          RiskId,
          InvestmentHorizon,
          InputReturn,
          SearchFund,
          SortingBy,
          //IsFavorite,
          SchemeOption,
          SchemeType,
          IsNFO,
          IsPreferred, Is100RsSip, SubCategoyResult
        );
      } else {
        ArrFundCategory = ArrSchemePlanType.filter(a => Arr_FundCategoryName.indexOf(a.AssetType) != -1)
        TempFinalArr = this.MakeRiskArray(ArrFundCategory, RiskId, InvestmentHorizon, InputReturn, SearchFund, SortingBy,
          //IsFavorite,
           SchemeOption, SchemeType,
           IsNFO,IsPreferred, Is100RsSip, SubCategoyResult
           );
      }
      FinalArray = TempFinalArr;

      if(FinalArray.length > 0){
      if(CurrentShowRecordCountDesk != undefined){
        if(TempFinalArr.length <= CurrentShowRecordCountDesk)
        {
          FinalArray[0].IsShowLoadMore = false;
          FinalArray = TempFinalArr.slice(0,FinalArray.length);

        }
        else
        {
          FinalArray[0].IsShowLoadMore = true;
          FinalArray = TempFinalArr.slice(0,CurrentShowRecordCountDesk);

        }
      }
      }
      return FinalArray;
    }
    else{
      return value;
    }
    }
  }

  MakeRiskArray(
    ArguSchemePlanType,
    ArguRiskId,
    InvestmentHorizon,
    InputReturn,
    SearchFund,
    SortingBy,
    //IsFavorite,
    SchemeOption,
    SchemeType,
    IsNFO,
    IsPreferred, Is100RsSip, SubCategoyResult
  ) {
    let Arr_RiskId = ArguRiskId.split(",");
    let TempFinalArr: any;
    if (Arr_RiskId[0] == "") {
      TempFinalArr = this.MakeInvestmentArr(
        ArguSchemePlanType,
        InvestmentHorizon,
        InputReturn,
        SearchFund,
        SortingBy,
        //IsFavorite,
        SchemeOption,
        SchemeType,
        IsNFO,
        IsPreferred, Is100RsSip, SubCategoyResult
      );
    } else {
      let InnerArrRiskLabel = [];
      InnerArrRiskLabel = ArguSchemePlanType.filter(a => Arr_RiskId.indexOf(a.RiskLable) != -1)
      TempFinalArr = this.MakeInvestmentArr(
        InnerArrRiskLabel,
        InvestmentHorizon,
        InputReturn,
        SearchFund,
        SortingBy,
        //IsFavorite,
        SchemeOption,
        SchemeType,
        IsNFO,
        IsPreferred, Is100RsSip, SubCategoyResult
      );
    }
    return TempFinalArr;
  }

  MakeInvestmentArr(
    arr,
    ParaInvestmentHorizon,
    InputReturn,
    SearchFund,
    SortingBy,
    //IsFavorite,
    SchemeOption,
    SchemeType,
    IsNFO,
    IsPreferred, Is100RsSip, SubCategoyResult
  ) {
    ////////
    let Arr_InvestmentHorizon = ParaInvestmentHorizon.split(",");
    let TempFinalArr: any;
    if (Arr_InvestmentHorizon[0] == "") {
      TempFinalArr = this.MakeReturnArr(
        arr,
        InputReturn,
        SearchFund,
        SortingBy,
        //IsFavorite,
        SchemeOption,
        SchemeType,
        IsNFO,
        IsPreferred, Is100RsSip, SubCategoyResult
      );
    } else {
      let InnerInvestHorizon = [];
      InnerInvestHorizon = arr.filter(a => Arr_InvestmentHorizon.indexOf(a.AMCName) != -1)
      TempFinalArr = this.MakeReturnArr(
        InnerInvestHorizon,
        InputReturn,
        SearchFund,
        SortingBy,
        //IsFavorite,
        SchemeOption,
        SchemeType,
        IsNFO,
        IsPreferred, Is100RsSip, SubCategoyResult
      );
    }
    return TempFinalArr;
  }

  MakeReturnArr(arr, Ir, SearchFund, SortingBy,
    //IsFavorite,
     SchemeOption, SchemeType,
     IsNFO,IsPreferred, Is100RsSip, SubCategoyResult
     ) {
    let TempFinalArr: any;
    let InnerReturnArr = [];
    if (Ir <= 1) {
      TempFinalArr = this.MakeSearchFundArr(
        arr,
        SearchFund,
        SortingBy,
        //IsFavorite,
        SchemeOption,
        SchemeType,
        IsNFO,
        IsPreferred, Is100RsSip, SubCategoyResult
      );
    } else {
      arr.forEach(SingleArr => {
        // if (parseFloat(SingleArr.ReturnValues[0].Value) >= Ir && SingleArr.IsLessThanOneYear != true) {
        //   InnerReturnArr.push(SingleArr);
        // }
        if (parseFloat(SingleArr.InceptionReturn) >= Ir && SingleArr.IsLessThanOneYear != true) {
          InnerReturnArr.push(SingleArr);
        }
        //
      });
      TempFinalArr = this.MakeSearchFundArr(
        InnerReturnArr,
        SearchFund,
        SortingBy,
        //IsFavorite,
        SchemeOption,
        SchemeType,
        IsNFO,
        IsPreferred, Is100RsSip, SubCategoyResult
      );
    }
    return TempFinalArr;
  }

  MakeSearchFundArr(arr, SearchFund, SortingBy,
    //IsFavorite,
     SchemeOption, SchemeType, IsNFO,IsPreferred, Is100RsSip, SubCategoyResult) {
    let TempFinalArr: any;
    let InnerSearchArr = [];
    if (SearchFund === "" || SearchFund.length < 3) {
      TempFinalArr = this.MakeFundNatureCategoryArr(arr, SortingBy, SchemeOption, SchemeType, IsNFO,IsPreferred, Is100RsSip, SubCategoyResult);
      //TempFinalArr = arr;

      //TempFinalArr = this.MakeFundNatureCategoryArr(arr, SchemeType)
    } else {
      arr.forEach(SingleArr => {
        if (SingleArr.SchemeName.trim().toLowerCase().includes(SearchFund.toLowerCase())){
          InnerSearchArr.push(SingleArr);
        }
      });
      //TempFinalArr = this.MakeFavoriteArr(InnerSearchArr, SortingBy, IsFavorite, SchemeOption, SchemeType, IsNFO);
      //TempFinalArr = InnerSearchArr;
      TempFinalArr = this.MakeFundNatureCategoryArr(InnerSearchArr, SortingBy, SchemeOption, SchemeType, IsNFO,IsPreferred, Is100RsSip, SubCategoyResult)
    }
    return TempFinalArr;
  }

  // MakeFavoriteArr(arr, SortingBy,
  //   //IsFavorite,
  //    SchemeOption, SchemeType, IsNFO) {
  //   let Arr_IsFavorite = IsFavorite.split(",");
  //   let TempFinalArr: any;
  //   let InnerFavoriteArr = [];
  //   if (Arr_IsFavorite[0] === "") {
  //     TempFinalArr = this.MakeFundNatureCategoryArr(arr, SortingBy, SchemeOption, SchemeType, IsNFO);
  //   } else {
  //     arr.forEach(SingleArr => {
  //       let index = Arr_IsFavorite.indexOf(SingleArr.FundId);
  //       if (index != -1) {
  //         InnerFavoriteArr.push(SingleArr);
  //       }
  //     });
  //     TempFinalArr = this.MakeFundNatureCategoryArr(
  //       InnerFavoriteArr,
  //       SortingBy,
  //       SchemeOption,
  //       SchemeType, IsNFO
  //     );
  //   }
  //   return TempFinalArr;
  // }

  MakeFundNatureCategoryArr(arr, SortingBy, SchemeOption, SchemeType, IsNFO,IsPreferred, Is100RsSip, SubCategoyResult){
    let TempFinalArr: any;
    let TempFundCatArr = [];
    arr.forEach(SingleFund => {
      if (SingleFund.SchemeType === SchemeType) {
        TempFundCatArr.push(SingleFund);
      }
    });
    //TempFinalArr = TempFundCatArr;
    TempFinalArr = this.MakeNFOArr(TempFundCatArr, SortingBy, SchemeOption, IsNFO,IsPreferred, Is100RsSip, SubCategoyResult);
    return TempFinalArr;
  }

  MakeNFOArr(arr, SortingBy, SchemeOption, IsNFO,IsPreferred, Is100RsSip, SubCategoyResult) {
    //
    let TempFinalArr: any;
    let TempNFOArr = [];
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    let TodaysDate = yyyy+'-'+mm+'-'+dd;
    var Dates = new Date(TodaysDate);
    if (IsNFO) {  
      arr.forEach(SingleFund => {
        var StartDate = new Date(SingleFund.NFOStart);
        var EndDate = new Date(SingleFund.NFOEnd);
        if ((Dates >= StartDate) && (Dates <= EndDate)) {
          TempNFOArr.push(SingleFund);
        }
      });
      //TempFinalArr = this.MakeSortByArr(TempNFOArr, SortingBy, SchemeOption);
      TempFinalArr = this.MakePreferredSchemeArr(TempNFOArr, SortingBy, SchemeOption, IsPreferred, Is100RsSip, SubCategoyResult);
    }
    else {
      //TempFinalArr = this.MakeSortByArr(arr, SortingBy, SchemeOption);
      TempFinalArr = this.MakePreferredSchemeArr(arr, SortingBy, SchemeOption, IsPreferred, Is100RsSip, SubCategoyResult);
    }
    return TempFinalArr;
  }

  MakePreferredSchemeArr(arr, SortingBy, SchemeOption, IsPreferred, Is100RsSip, SubCategoyResult) {
    let TempFinalArr: any;
    let TempPreferSchemeArr = [];
    if (IsPreferred) {
      arr.forEach(SingleFund => {
        //////
        if (SingleFund.RecFlag === "True") {
          TempPreferSchemeArr.push(SingleFund);
        }
      });
      TempFinalArr = this.Make100RsSipSchemeArr(TempPreferSchemeArr, SortingBy, SchemeOption, Is100RsSip, SubCategoyResult);
    }
    else {
      TempFinalArr = this.Make100RsSipSchemeArr(arr, SortingBy, SchemeOption, Is100RsSip, SubCategoyResult);
    }
    return TempFinalArr;
  }
  Make100RsSipSchemeArr(arr, SortingBy, SchemeOption, Is100RsSip, SubCategoyResult) {
    let TempFinalArr: any;
    let Temp100RsSipSchemeArr = [];
    if (Is100RsSip) {
      arr.forEach(SingleFund => {
        //////
        if (SingleFund.minsipamount == "100") {
          Temp100RsSipSchemeArr.push(SingleFund);
        }
      });
      TempFinalArr = this.MakeSubCategoryArr(Temp100RsSipSchemeArr, SortingBy, SchemeOption, SubCategoyResult);
    }
    else {
      TempFinalArr = this.MakeSubCategoryArr(arr, SortingBy, SchemeOption, SubCategoyResult);
    }
    return TempFinalArr;
  }
  MakeSubCategoryArr(arr, SortingBy, SchemeOption,SubCategoyResult){
    let r = SubCategoyResult.split(",");
      let TempFinalArr: any;
      if (r[0] == 'All' && r.length == 1) {
        TempFinalArr = this.MakeSortByArr(arr, SortingBy, SchemeOption);
      } else {
        let ArrFundCategory:any[] = [];
        ArrFundCategory = arr.filter(a => r.indexOf(a.CategoryName) != -1)
        TempFinalArr = this.MakeSortByArr(ArrFundCategory,SortingBy, SchemeOption);
      }
      return TempFinalArr;
  }

  MakeSortByArr(arr, SortingBy, SchemeOption) {
    ////////
    let TempFinalArr: any;
    switch (SortingBy) {
      case 1:
        TempFinalArr = arr.sort((a, b) => a.AUM - b.AUM);
        break;
      case 2:
        TempFinalArr = arr.sort((a, b) => b.AUM - a.AUM);
        break;
      case 3:
        let Plan = SchemeOption;
        //if (Plan === "Regular") {
          if (Plan === "Growth") {
          TempFinalArr = arr.sort(
            //(a, b) => a.RegularLaunchDate - b.RegularLaunchDate
            (a, b) => a.InceptionDate - b.InceptionDate
          );
        }
        else {
          TempFinalArr = arr.sort(
            //(a, b) => a.DirectLaunchDate - b.DirectLaunchDate
            (a, b) => a.InceptionDate - b.InceptionDate
          );
        }
        break;
      case 4:
        let Plan2 = SchemeOption;
        //if (Plan2 === "Regular") {
        if (Plan2 === "Growth") {
          TempFinalArr = arr.sort(
            //(a, b) => b.RegularLaunchDate - a.RegularLaunchDate
            (a, b) => b.InceptionDate - a.InceptionDate
          );
        } else {
          TempFinalArr = arr.sort(
            //(a, b) => b.DirectLaunchDate - a.DirectLaunchDate
            (a, b) => b.InceptionDate - a.InceptionDate
          );
        }
        break;
      case 5:
        TempFinalArr = arr.sort(
          //(a, b) => a.ReturnValues[0].Value - b.ReturnValues[0].Value
          (a, b) => a.InceptionReturn - b.InceptionReturn
        );
        break;
      case 6:
        TempFinalArr = arr.sort(
          //(a, b) => b.ReturnValues[0].Value - a.ReturnValues[0].Value
          (a, b) => b.InceptionReturn- a.InceptionReturn
        );
        break;
    }
    return TempFinalArr;
  }
}
