<div class="modal" id="AlertModalSSO" appDisabledRightClick>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content AlertModalContentCls">
        <div class="modal-header AlertModalHeader">Important Message</div>
        <div id="AlertModalSSOContent" class="modal-body AlertModalBody"></div>
        <div class="modal-footer AlertModalFooter">
          <button type="button" data-dismiss="modal" (click)="GoToLoginScreen()" class="CursorPointer">Okay</button>
        </div>
      </div>
    </div>
  </div>