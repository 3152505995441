import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() { }
  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    if(token != null){
      return true;
    }
    else{
      return false;
    }
  }
}
