import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RandomStringService {
Charcaters:string= '!@#$%^&~0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  constructor() { }

 getString(length):string {
    var result = '';
    let chars = this.Charcaters;
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}
// var rString = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
}
