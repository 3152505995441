import { animate, trigger, state, style, transition } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        // height: 'auto',
        opacity: 1,
        display: 'block'
      })),
      state('closed', style({
        // height: '0px',
        opacity: 0,
        display: 'none'
      })),
      transition('open => closed', [
        animate('0s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ],
})
export class ContactUsComponent implements OnInit {
  IsMobile: boolean = false;
  IsContactUsOpen:boolean = false;

  constructor() { }

  ngOnInit() {
    this.SetViewPort(window.innerWidth);
  }
  SetViewPort(w) {
    if (w <= 767) {
      this.IsMobile = true;
    }
    else {
      this.IsMobile = false;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.SetViewPort(event.target.innerWidth)
  }

  OpenContactUs() {
    // $(".modal").modal('hide');
    $("#ContactUsModal").modal({
      backdrop : 'static',
      keyboard : false
    });
  }

}
