
import { isNullOrUndefined } from './validation';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Component, HostListener } from '@angular/core'
import { Title } from '@angular/platform-browser';
import { BnNgIdleService } from 'bn-ng-idle';
import { ClientDataService } from './services/client-data.service';
import { EncrdecrService } from './Auth/encrdecr.service';
import { LoginService } from './services/login.service';
import { CustomLoaderService } from './services/custom-loader.service';
import { TokenExpireService } from './services/token-expire.service';
import { MainHeaderMenuService } from './services/main-header-menu.service';
import { Observable } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  // Companyname: string = 'AU';
  CompanyLogo: any;
  href: any;
  IsLoaded = false;
  Companyname: string = environment.CompayName;
  CommonBaseHref: string = environment.CommonBaseHref;
  PrimaryColor: string = '';
  SecondaryColor: string = '';
  ThirdColor: string = '';
  BodyColor: string = '';
  BodyBgColor: string = '';
  BodyFooterBgColor: string = '';
  Table_Header_Bg = '';
  Table_Head_Text = '';
  fontFamily = '';
  favIcon: HTMLLinkElement = document.querySelector('#Fevicon');
  //MainLogo: HTMLImageElement = document.querySelector('#MainLogo');
  logo: string;
  loader: string;
  loaderWidth: string;
  loaderHeight: string;
  SessionTime: number = 3000;
  IsMobile: boolean = false;
  IsLoggedIn: boolean = false;
  isLoggedIn$: Observable<boolean>;
  constructor(private Idle: BnNgIdleService,
    private titleService: Title,
    private ClientDataService: ClientDataService,
    private EncrdecrService: EncrdecrService,
    private LoginService: LoginService,
    private router: Router,
    public Loader: CustomLoaderService,
    private Logout: TokenExpireService,
    private MainHeaderMenuService: MainHeaderMenuService
  ) { // initiate it in your component constructor
    //sessionStorage.removeItem('6+J3RebINEPBJVbAJw');


    this.Idle.startWatching(this.SessionTime).subscribe((res) => {
      if (res) {
        this.MainHeaderMenuService.hide();
        if (sessionStorage.getItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF') != null && sessionStorage.getItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF') != undefined && sessionStorage.getItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF') != "") {
          const isLoggtedIn = this.EncrdecrService.getDecr(sessionStorage.getItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF'));
          if (isLoggtedIn == "1") {
            this.IsLoggedIn = true;
          }
          else {
            this.IsLoggedIn = false;
          }
        } else {
          this.IsLoggedIn = false;
        }
        if (res == true) {

          switch (this.Companyname) {           
            case 'HDFC':
              this.ClientDataService.SetWelcomeText('HDFC');
              this.titleService.setTitle('HDFC');
              sessionStorage.setItem('logo', '../../assets/images/HDFC_Logo_WhiteBg.png');
              sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("18"));
              sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "18");
              sessionStorage.setItem('Mnuoisns6wqgswj2$#', "0");
              break;
          }
          let path = window.location.pathname.toLowerCase();
          if (path != '/login-corp' && !path.includes("login") && path != '/index' && path != '/ipo' && path != '/ipo/' && path != '/ipo/index' && path != '/') {
            $('.modal').modal('hide');

            this.SessionExpired();
            const LoginSource = sessionStorage.getItem('6+J3RebINEPBJVbAJw');
            if (LoginSource == null || LoginSource == undefined || LoginSource == '' && this.Companyname != "AU") {
              $('#SessionModal').modal({
                backdrop: 'static',
                keyboard: false
              });
            }
          }
          history.pushState(null, null, window.location.href);
          history.back();
          window.onpopstate = () => history.forward();
          $('select').select2('close');
        }
      }
    })
    //this.getCompanyDetails();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.SetViewPort(event.target.innerWidth)
  }
  ngOnInit() {
    this.isLoggedIn$ = this.MainHeaderMenuService.isShowHeader;
    this.SetViewPort(window.innerWidth);
      $('body').css('min-height', window.innerHeight - 66)
      //$('.MainOuter').css('min-height', window.innerHeight - 72);
    //
    $('.modal').modal('hide');

    this.getCompanyDetails();
    switch (this.Companyname) {
      case 'HDFC':
        this.PrimaryColor = '#f80003'; //#032B5Bf80003
        this.SecondaryColor = '#004b8d';
        this.ThirdColor = '#004b8d';
        this.BodyColor = '#444444';
        this.BodyBgColor = '#e7e9eb' // FEFCCD
        this.BodyFooterBgColor = '#0064ac';
        this.Table_Header_Bg = '#efefef';
        this.Table_Head_Text = '#004b8d';
        this.fontFamily = 'Inter';
        this.ClientDataService.SetWelcomeText('HDFC');
        this.titleService.setTitle('HDFC');
        this.favIcon.href = './assets/images/HDFC_favicon.ico';
        this.loader = '../../../assets/images/HDFC_Loader.gif';
        this.loaderWidth = '120px';
        this.loaderHeight = '120px';
        sessionStorage.setItem('logo', '../../assets/images/HDFC_Logo.png');
        sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("18"));
        sessionStorage.setItem('Mnuoisns6wqgswj2$#', "0");
        break;
    }
    this.ClientDataService.SetCompanyName(this.Companyname);
    document.documentElement.style.setProperty('--primary-color', this.PrimaryColor);
    document.documentElement.style.setProperty('--secondary-color', this.SecondaryColor);
    document.documentElement.style.setProperty('--third-color', this.ThirdColor);
    document.documentElement.style.setProperty('--body-color', this.BodyColor);
    document.documentElement.style.setProperty('--body-bg-color', this.BodyBgColor);
    document.documentElement.style.setProperty('--body-footer-bg-color', this.BodyFooterBgColor);
    document.documentElement.style.setProperty('--table-header-bg-color', this.Table_Header_Bg);
    document.documentElement.style.setProperty('--table-header-text-color', this.Table_Head_Text);
    document.documentElement.style.setProperty('--font-family-regular', `${this.fontFamily}Regular`);
    document.documentElement.style.setProperty('--font-family-semibold', `${this.fontFamily}SemiBold`);
    document.documentElement.style.setProperty('--font-family-bold', `${this.fontFamily}Bold`);

  }
  SetViewPort(w) {
    if (w <= 767) {
      this.IsMobile = true;
    }
    else {
      this.IsMobile = false;
    }
  }
  getCompanyDetails() {
    //////
    // if(session)
    //  sessionStorage.removeItem("E#$%#XCha7%*=");
    //  sessionStorage.removeItem("Vbhs@#%LMKIo=");
    //  sessionStorage.removeItem("DufjGDH3843JE");
    //  sessionStorage.removeItem("PfdkSJDNWks2323Ms");
    //  sessionStorage.removeItem("Arh34SDBJSSiF");

    this.ClientDataService.getCompanyDetails(environment.CompanyId).subscribe((data: any) => {
      //
      //
      if (data != null && data.ArrayOfResponse.length != 0) {

        //let dtsa = this.EncrdecrService.getDecr(data.ArrayOfResponse[0].Dummy2);
        //this.Loader.hide();
        let rk = data.ArrayOfResponse[0];
        //console.log('Corp_IFA = ' + this.EncrdecrService.getDecr(rk.Dummy1));
        //this.Companyname = rk.CompanyName;
        // if(this.Companyname == 'AU')
        // {
        // sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$',this.EncrdecrService.setEncr("4"));
        // sessionStorage.setItem('Mnuoisns6wqgswj2$#',"1");
        // }
        // else{
        // sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$',this.EncrdecrService.setEncr("0"));
        // sessionStorage.setItem('Mnuoisns6wqgswj2$#',"0");
        // }

        var str = this.EncrdecrService.getDecr(rk.Dummy1);
        sessionStorage.setItem('E#$%#XCha7%*=', this.EncrdecrService.getDecr(rk.Dummy2));
        sessionStorage.setItem('Vbhs@#%LMKIo=', this.EncrdecrService.getDecr(rk.Dummy1));
        sessionStorage.setItem('DufjGDH3843JE', this.EncrdecrService.getDecr(rk.Dummy4));
        sessionStorage.setItem('PfdkSJDNWks2323Ms', this.EncrdecrService.getDecr(rk.Dummy3));
        sessionStorage.setItem('Arh34SDBJSSiF', this.EncrdecrService.getDecr(rk.Dummy5));
        sessionStorage.setItem('Fhd63asja$%Wsdjs9304#@dfvdjfjd', this.EncrdecrService.getDecr(rk.Dummy6));
        sessionStorage.setItem('%^&s8g9b!@fla%g', this.EncrdecrService.getDecr(rk.Dummy7));
        sessionStorage.setItem('JikLG6K4LOdfv', this.EncrdecrService.getDecr(rk.Dummy11));
        if (!isNullOrUndefined(rk.Dummy12)) {
          sessionStorage.setItem('Hjgir56hutHLB', this.EncrdecrService.getDecr(rk.Dummy12));
        }

        try {
          if (!isNullOrUndefined(this.EncrdecrService.getDecr(rk.Dummy8))) {
            sessionStorage.setItem('trtygy$gtdr&dft=', this.EncrdecrService.getDecr(rk.Dummy8));
          } else {
            sessionStorage.setItem('trtygy$gtdr&dft=', 'false');
          }
        }
        catch (e) {
          sessionStorage.setItem('trtygy$gtdr&dft=', 'false');
        }
        try {
          if (!isNullOrUndefined(this.EncrdecrService.getDecr(rk.Dummy9))) {
            sessionStorage.setItem('hgjhfjg&jbfj%dfgs=', this.EncrdecrService.getDecr(rk.Dummy9));
          } else {
            sessionStorage.setItem('hgjhfjg&jbfj%dfgs=', 'false');
          }
        }
        catch (e) {
          sessionStorage.setItem('hgjhfjg&jbfj%dfgs=', 'false');
        }
        try {
          if (!isNullOrUndefined(this.EncrdecrService.getDecr(rk.Dummy10))) {
            sessionStorage.setItem('dsa&hdhbc%ube=', this.EncrdecrService.getDecr(rk.Dummy10));
          } else {
            sessionStorage.setItem('dsa&hdhbc%ube=', 'false');
          }
          sessionStorage.setItem('cIe+MhBUozWO3+mKZd2to=', this.EncrdecrService.getDecr(rk.EnableRiskProfile));
        }
        catch (e) {
          sessionStorage.setItem('dsa&hdhbc%ube=', 'false');
        }

        // if (this.Companyname == 'Mahindra') {


        // }

        // if(rk.Dummy1 !="" && rk.Dummy1!=null && rk.Dummy1!=undefined){
        //   sessionStorage.setItem('Vbhs@#%LMKIo=',"N");
        //   console.log('Corp_IFA_i = ' + sessionStorage.getItem('Vbhs@#%LMKIo='));
        // }
        //this.EncrdecrService.getDecr(rk.CompanyName);
      }
    });
  }
  SessionExpired() {

    let LoginToken = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    this.LoginService.Logout(LoginToken).subscribe(
      (res: any) => {

        const sessionvalue = sessionStorage.getItem('nvjsd212djskd$#21ksdj');

        if (sessionvalue != undefined && sessionvalue != null && sessionvalue != '') {
          if (sessionvalue == 'C') {
            // const LoginSource = sessionStorage.getItem('6+J3RebINEPBJVbAJw');
            //sessionStorage.removeItem('6+J3RebINEPBJVbAJw');
            // if (this.Companyname == "AU") {
            //   //this.router.navigateByUrl('/mb-logout');
            // if (LoginSource == "MB") {
            //   this.router.navigateByUrl('/mb-logout');
            // }
            // else {
            //     if (LoginSource == 'NB') {
            //     //this.router.navigateByUrl('https://ppdweb.aubank.in/netbanking/logout');
            //     window.location.href = 'https://www.aubank.in/netbanking/logout';
            //   } else {
            //     this.router.navigateByUrl('/login-corp');
            //   }
            // }
            // }
            // else {

            //   this.router.navigateByUrl('/login-corp');
            // }
            this.Logout.LogoutUser();
          }
          else if (sessionvalue == 'E') {

            this.router.navigateByUrl('/employee-login');
          }
        }
        sessionStorage.clear();
        localStorage.clear();
        switch (this.Companyname) {          
          case 'HDFC':
            sessionStorage.setItem('logo', '../../assets/images/HDFC_Logo_WhiteBg.png');
            sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("18"));
            sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "18");
            sessionStorage.setItem('Mnuoisns6wqgswj2$#', "0");
            break;
        }
      }
    );
  }
  RedirectTODashBoard() {
    var G_UserType = parseInt(this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'))); //Session_UserType
    if (G_UserType == 3) {
      this.router.navigateByUrl('/dashboard');
    }
    else if (G_UserType == 2) {
      this.router.navigateByUrl('/dashboard');
    }
    else {
      this.router.navigateByUrl('/dashboard');
    }
  }

  FnIsCollapseSideMenu(e) {
    if (e) {
      $('.MainBg').addClass('collapsed_Menu');
    }
    else {
      $('.MainBg').removeClass('collapsed_Menu');
    }
  }
  GoToClientProfile() {
    this.router.navigateByUrl('/Client-Profile-Preview/Preview');
  }
}
// ===== Scroll to Top ====
$(window).scroll(function () {
  if ($(this).scrollTop() >= 200) {        // If page is scrolled more than 50px
    $('#return-to-top').fadeIn(200);    // Fade in the arrow
  } else {
    $('#return-to-top').fadeOut(200);   // Else fade out the arrow
  }
});
$(document).ready(function () {
  $('#return-to-top').click(function () {      // When arrow is clicked
    $('body,html').animate({
      scrollTop: 0                       // Scroll to top of body
    }, 500);
  });
  $(".numonly").on("input", function () {
    var regexp = /[^\d\.\-]/g;
    if ($(this).val().match(regexp)) {
      $(this).val($(this).val().replace(regexp, ''));
    }
  });

  $(".alphaonly").on("input", function () {
    var regexp = /[^a-zA-Z ]/g;
    if ($(this).val().match(regexp)) {
      $(this).val($(this).val().replace(regexp, ''));
    }
  });

});


