export const environment = {
  production: true,
  CommonBaseHref:'',
  CompayName: 'HDFC',
  CompanyId:1,
  environmentDigio:"sandbox",  
  
  
  //hdfc URL
  // ServerUrl: 'http://localhost:8086/MF/', //uat local wrapper url
  //ServerUrl: 'http://172.21.21.51:8081/MF/' , 
  ServerUrl:  'https://uatpartnersapi.hdfcsec.com/MF/', //uat domain wrapper url  
  HDFCFranchiseURL : "https://uatpartners.hdfcsec.com/"

  // ForLive
  // ServerUrl:  'https://partnersapi.hdfcsec.com/MF/', //Live wrapper url  
  // HDFCFranchiseURL : "https://partners.hdfcsec.com/"
};



