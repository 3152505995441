import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-cuttoff-timing-modal',
  templateUrl: './cuttoff-timing-modal.component.html',
  styleUrls: ['./cuttoff-timing-modal.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class CuttoffTimingModalComponent implements OnInit {
  constructor() {
   }

  ngOnInit() {
    
  }

}
