

  <div class="MainBg bg-white">
    <div class="MainInnerFormWithoutFlex pb-4" style="background-color: transparent !important;">
      <div class="SectionBox bg-white BoxShadow">
        <div class="SectionTopHeading SectionHeading mb-3">
          <span>
            <!-- <img src="../../assets/images/Header_Icon.svg" class="" />&nbsp; -->
            Client Assistance Mapping
          </span>
        </div>
        <div class="table-responsive px-3 mt-4">
            <table class="CommonTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <!-- <th>Email</th>
                  <th>Mobile</th>
                  <th>PinCode</th> -->
                  <th>Map As Assistance</th>
                </tr>
              </thead>
              <tbody *ngIf="AllIFD.length > 0">
                <tr *ngFor="let ifd of AllIFD; let i= index">
                  <td>{{ifd.SubBrokerName}}</td>
                  <td>{{ifd.SubBrokerCode}}</td>
                  <!-- <td>{{ifd.EmailID}}</td>
                  <td>{{ifd.MobileNo}}</td>
                  <td>{{ifd.PinCode}}</td> -->
                  <td class="ColorBlue CursorPointer"
                (click)="onClickSelect(ifd.SubBrokerId)">Map</td>
                </tr>
              </tbody>
              <tbody *ngIf="AllIFD.length == 0">
                <tr class="NoRecordFound NoRecordFoundAlignTable">
                  <td colspan="8">
                    No Record Found
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
