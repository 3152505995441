import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrdecrService } from 'src/app/Auth/encrdecr.service';
import { ClientsFilterService } from 'src/app/services/clients-filter.service';
import { CustomLoaderService } from 'src/app/services/custom-loader.service';
import { ProfileService } from 'src/app/services/profile.service';
import { TokenExpireService } from 'src/app/services/token-expire.service';
import { IsValidArray } from 'src/app/validation';
declare var $: any;
@Component({
  selector: 'app-clients-filter',
  templateUrl: './clients-filter.component.html',
  styleUrls: ['./clients-filter.component.scss']
})
export class ClientsFilterComponent implements OnInit {
  @Output() FilterData: EventEmitter<any> = new EventEmitter();
  @Input() isMultiSelect: boolean = false;
  @Input() DDLFilterType: string = 'T';    // Default 'T' is passed -- Added by Saddam
  @Input() HierarchyClient = true;
  @Input() FilterType: string = "byClient"; // i have changed simple FilterType to @Input type
  clientFilterForm: FormGroup;
  LoginId: string;
  UserType: string = "";
  Param1: string = "";
  ClientData: any;
  TempClientData: any;
  FamilyData: any;
  EmployeeData: any;
  EmployeeDataH: any;
  EmployeeSetting = {};
  SelectedEmployee: any;
  SubbrokerData: any;
  HierarchyData: any;
  PlaceData: any;
  PlaceDataALL: any;
  ResponseData: any;
  selectedClient: any;
  @Input() isShowClientFilter: boolean = true;
  isdisplayFamilyName: boolean = true;
  isDisplayClientName: boolean = true;

  @Input() isShowEmpSubBrokerFilter: boolean = true;

  isDisplayEmployeeName: boolean = true;
  isDisplaySubBrokerName: boolean = true;
  isDisplayEmpSubBrokerFamilyName: boolean = true;
  isDisplayEmpSubClientName: boolean = true;

  isShowHiearchyFilter: boolean = true;
  IsShowSubbroker: boolean = true;
  ClientDataSetting = {};
  resultDisplayName = [];
  AllEmpData = [];
  result = [];
  selectedItems: any[] = [];
  ChildSubbrokerData: any[] = [];
  IsMobile: boolean = false;
  @Input() CheckUCCForTranx: boolean = false;
  G_ROLEID: number;
  ClientData_Temp: any;  //Added by Saddam : Store ClientData on page load
  FamilData_Temp: any;    //Added by Saddam
  IsHeirarchyEmp: boolean = false;    //Added by Saddam
  CorpIFDFlag: string = '';
  constructor(
    private fb: FormBuilder,
    private encryptDecrypt: EncrdecrService,
    private ClientFilterService: ClientsFilterService,
    private Loader: CustomLoaderService,
    private Logout: TokenExpireService,
    private EncrdecrService: EncrdecrService,
    private ProfileService: ProfileService,) {
    this.clientFilterForm = this.fb.group({
      ClientBasicInfoId: [''],
      FamilyId: [''],
      EmpId: [''],
      SubbrokerId: [''],
      HierarchyId: [''],
      PlaceId: ['']
    });
  }
  SetViewPort(w) {
    ;
    if (w <= 767) {
      this.IsMobile = true;
    }
    else {
      this.IsMobile = false;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.SetViewPort(event.target.innerWidth)
  }
  ngOnInit() {

    this.SetViewPort(window.innerWidth);
    let data = window.location.pathname;


    this.UserType = this.encryptDecrypt.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    if (this.UserType == '2') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('F44sGAGh2xwkpUL'));
    }
    if (this.UserType == '3') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('F44sGAGh2xwkpUL'));
    }
    if (this.UserType == '1') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('F44sGAGh2xwkpUL'));
      if (sessionStorage.getItem('5gE4QWzFXWKewRC') != '' && sessionStorage.getItem('5gE4QWzFXWKewRC') != null && sessionStorage.getItem('5gE4QWzFXWKewRC') != undefined) {
        this.G_ROLEID = parseInt(this.EncrdecrService.getDecr(sessionStorage.getItem('5gE4QWzFXWKewRC')));
      }
    }

    if (
      sessionStorage.getItem("kioh298hKh2kj") != null &&
      sessionStorage.getItem("kioh298hKh2kj") != undefined
    ) {
      this.CorpIFDFlag = this.EncrdecrService.getDecr(sessionStorage.getItem('kioh298hKh2kj'))
    }

    console.log('----------------------' + this.UserType)
    //console.log(this.router.url);

    switch (data) {
      case '/reports/Monthly-SIP-collection-report':
        this.isShowClientFilter = false;
        this.isShowClientFilter = false;
        this.isShowHiearchyFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isDisplayEmployeeName = true;
        this.isDisplaySubBrokerName = true;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/Redemption-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/Client-FD-PortFolio-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/FD-Mobilization-And-Maturity-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/Fresh-SIP-Addition':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/MF-Mobilization-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/reversal/Rejection-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/Final-Mobilization-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/FD-Maturity-Report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports/fd-bond-form-entry-report':
        this.isShowClientFilter = false;
        this.isShowEmpSubBrokerFilter = false;
        this.isShowHiearchyFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports-new/client-wise-mobilization':
        break;
      //Added by Saddam
      case '/reports-new/fresh-sip':
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isDisplayEmpSubClientName = false;
        this.HierarchyClient = false;
        this.isShowClientFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports-new/business-mobilization-snapshot':
        debugger
        if (this.UserType == '1') {
          this.isDisplayEmployeeName = true;
          this.isDisplaySubBrokerName = true;
          this.isDisplayEmpSubClientName = false;
          this.isDisplayEmpSubBrokerFamilyName = false;
          this.isDisplayClientName = false;
          this.isdisplayFamilyName = false;
          this.HierarchyClient = false;
        }
        break;
      case '/reports-new/client-wise-aum':
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isDisplayEmpSubClientName = false;
        this.HierarchyClient = false;
        this.isShowClientFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        break;
      case '/reports-new/net-sell-report':
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isDisplayEmpSubClientName = false;
        this.HierarchyClient = false;
        this.isShowClientFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        if (this.UserType == "2") {
          this.isDisplayEmployeeName = false;
          this.IsShowSubbroker = false;
        }
        else {
          this.isDisplayEmployeeName = true;
          this.IsShowSubbroker = true;
        }
        this.isDisplaySubBrokerName = false;
        break;
      case '/reports-new/business-metric':
        this.isShowClientFilter = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayClientName = false;
        this.isDisplayEmployeeName = true;
        this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        break;
      // commented code by Ravi Vaghela
      default:
        //this.isShowClientFilter = true;
        this.isDisplayClientName = true;
        this.isdisplayFamilyName = true;
        // this.isShowEmpSubBrokerFilter = true;
        this.isShowHiearchyFilter = true;


        this.isDisplayEmployeeName = false;
        this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        break;
    }


    this.BindDropdown();
    //when user redirected from explore fund to transaction pages
    try {
      this.selectedClient = JSON.parse(this.encryptDecrypt.getDecr(sessionStorage.getItem('QQX4$E6Qnd')))
    } catch (err) {

    }

    if (this.selectedClient != undefined) {
      this.clientFilterForm.controls['ClientBasicInfoId'].patchValue(`${this.selectedClient.ClientBasicInfoId}`);
      $(".ClientSelect").prop("disabled", true);
      sessionStorage.removeItem('QQX4$E6Qnd')
      this.FilterData.emit(this.selectedClient)
    }

    this.ClientDataSetting = {
      singleSelection: false,
      idField: 'ClientBasicInfoId',
      textField: 'clientname',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    //Added By Ravi Vaghela
    if (this.FilterType == 'byHierachy') {
      this.onCheck('byHierachy')
    }
  }
  ngAfterViewInit() {
    $('select').select2();
    $(".ClientSelect").on("change", (e: any) => this.onChangeClient(e));
    $(".FamilySelect").on("change", (e: any) => this.onChangeFamily(e));
    $(".EmployeeSelect").on("change", (e: any) => this.onChangeEmployee(e));
    $(".SubbrokerSelect").on("change", (e: any) => this.onChangeSubbroker(e, 'Subbroker'));
    $(".ChildSubbrokerSelect").on("change", (e: any) => this.onChangeSubbroker(e, 'ChildSubbroker'));
    $(".HierarchySelect").on("change", (e: any) => this.onChangeHierarchy(e));
    $(".PlaceSelect").on("change", (e: any) => this.onChangePlace(e));

  }
  onChangeClient(event: any) {

    let ClientBasicInfoId = event.target.value;
    if (ClientBasicInfoId != '') {
      this.clientFilterForm.controls['ClientBasicInfoId'].setValue(ClientBasicInfoId);
      let filterData = this.TempClientData.filter(item => item.ClientBasicInfoId == ClientBasicInfoId);
      if (filterData.length > 0) {
        let SubBrokerName: any = '';
        if (this.SubbrokerData != undefined && this.SubbrokerData != null && this.SubbrokerData.length > 0) {
          SubBrokerName = this.SubbrokerData.find(item => item.SubbrokerCode == filterData[0].SubBrokerCode);
        }
        filterData[0].ClientID = ClientBasicInfoId;
        filterData[0].flag = "Client";
        filterData[0].resultDisplayName = "";

        if (SubBrokerName != null && SubBrokerName != undefined && SubBrokerName != '') {
          filterData[0].SubBrokerName = SubBrokerName.SubbrokerName;
        }
        if (this.CheckUCCForTranx) {

          let pan = this.EncrdecrService.setEncr((filterData[0].PANNo).substring(0, 10));
          let Request = {
            PANNo: pan
          }
          this.ProfileService.CheckCVLKRA(Request).subscribe((result: any) => {
            if (result != null) {
              console.log(result)
              if (result.Message != null && result.Message != "" && result.Message.indexOf("Not Authorized") > -1) {
                this.Logout.LogoutUser();
                return;
              }
              else {
                let r = result.ArrayOfResponse;
                if (r.length > 0) {
                  if (r[0].KYCStatus == 1) {
                    this.FilterData.emit(filterData[0]);
                  }
                  else {
                    $(".ClientSelect").val('').trigger('change');
                    this.CallAlertModal("KYC is not completed. Please complete KYC first.");
                  }
                }
                else {
                  $(".ClientSelect").val('').trigger('change');
                  this.CallAlertModal("KYC is not completed. Please complete KYC first.");
                }
              }
            }
          }, (err: any) => {
            console.log(err)
            this.Loader.hide();
          });
        }
        else {
          this.FilterData.emit(filterData[0]);
        }
      }
    }
  }
  onChangeFamily(event: any) {
    let Family = event.target.value;
    if (this.TempClientData != undefined && this.TempClientData != null) {
      this.ClientData = this.TempClientData.filter(item => item.ClientGroupName == Family);
      if (this.ClientData.length == 1) {
        this.ClientFilterService.setClientDropdownData(this.ClientData[0]);
        $('.ClientSelect').val(this.ClientData[0].ClientBasicInfoId).trigger('change');
      }
      else {
        $('.ClientSelect').val('').trigger('change');
      }
    }
  }
  onChangeEmployee(event: any) {
    let ID_Code = event.target.value;
    if (ID_Code != '') {
      this.clientFilterForm.controls['EmpId'].setValue(ID_Code);
      this.LoginId = ID_Code;
      this.Param1 = 'Direct'
      this.SubbrokerData = [];
      this.ClientData = [''];
      this.TempClientData = [];
      this.FamilyData = [];
      this.BindDropdown();
      if (this.isDisplayEmployeeName == true || this.IsHeirarchyEmp == true) {
        let json = {
          "EmployeId": ID_Code,
          "flag": "employee"
        }
        this.FilterData.emit(json);
      } else {
        this.FilterData.emit(ID_Code);
      }
    }
    $(".SubbrokerSelect,.ChildSubbrokerSelect").val('').trigger('change');
  }
  onChangeSubbroker(event: any, flag = '') {
    debugger
    let SubbrokerId = event.target.value;
    if (SubbrokerId != '') {
      let SubBrokerName = event.target.options[event.target.options.selectedIndex].text;
      let empClient;
      let empClientFam;
      this.clientFilterForm.controls['SubbrokerId'].setValue(SubbrokerId);
      //Added by Avdhoot For ChildSubBroker
      let ChildFlag = ''
      let IsChildSB = false;   //Master Franchise
      let selected_ifd_data: any[] = [];

      //Master Franchise : Added by Saddam
      if (this.ChildSubbrokerData !== undefined && this.UserType == '2' && this.ChildSubbrokerData.length > 0) {
        ChildFlag = 'ChildSubBroker'

        selected_ifd_data = this.ChildSubbrokerData.filter((e) => {
          return e.SubbrokerId == SubbrokerId;
        });
        if (selected_ifd_data[0].IsChildSB == true) {
          IsChildSB = true;
        }

        if (selected_ifd_data[0].CorporateIFDFlag != 1 && this.DDLFilterType == 'D') {
          if (IsChildSB == true) {
            this.isdisplayFamilyName = false;
            this.isDisplayClientName = false;
          }
          else {
            this.isdisplayFamilyName = true;
            this.isDisplayClientName = true;
          }
        }
        else {
          this.isdisplayFamilyName = true;
          this.isDisplayClientName = true;
        }
      }

      let json = {
        "SubBrokerId": SubbrokerId,
        "SubBrokerName": SubBrokerName,
        "flag": "SubBroker",
        "childFlag": ChildFlag,
        "IsChildSB": IsChildSB
      }

      this.FilterData.emit(json)
      if (this.G_ROLEID >= 1 && this.G_ROLEID <= 3 && this.DDLFilterType == 'IP') {
        empClient = this.ClientData;
      }
      else {
        this.ClientData = [];
        this.TempClientData = [];
        this.FamilyData = [];
      }

      this.Param1 = "";
      $(".ClientSelect").val('').trigger('change')
      this.Loader.show();
      let request: any;
      if (this.UserType == '1' && this.DDLFilterType == 'R') {
        request = {
          "LoginId": SubbrokerId,
          "LoginType": '1-2',
          "FilterType": this.DDLFilterType,
          "Param1": this.Param1,
          "Param2": ""
        }
      }
      else {
        request = {
          "LoginId": SubbrokerId,
          "LoginType": '2',
          "FilterType": this.DDLFilterType,
          "Param1": this.Param1,
          "Param2": ""
        }
      }


      if (this.UserType == '1') {
        this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('F44sGAGh2xwkpUL'));
      }
      let body = { data: this.encryptDecrypt.setEncr(JSON.stringify(request)) }
      this.ClientFilterService.WrapperApiCall(body, "/api/ClientFilters/getClientFilters").subscribe(
        (response: any) => {
          let res = JSON.parse(this.EncrdecrService.getDecr(response))
          this.ResponseData = res;
          console.log('Client listttttttttt===>>>>> ',res)
          this.Loader.hide();
          if (res.code == 200) {
            console.log(res);
            if (res.ArrayOfResponse[0].ClientDetails.length > 0) {
              if (this.G_ROLEID >= 1 && this.G_ROLEID <= 3 && this.DDLFilterType == 'IP') {
                if (this.isMultiSelect) {
                  this.ClearMultiSelectDDL();
                }

                let sbClient = res.ArrayOfResponse[0].ClientDetails;
                let tempClients = empClient.concat(sbClient);
                this.ClientData = tempClients;
                this.TempClientData = tempClients;
                let filterData = tempClients.filter(item => item.ClientGroupName !== null);
                this.FamilyData = [...new Set(filterData.map(item => item.ClientGroupName))];
              }
              else {
                if (this.isMultiSelect) {
                  this.ClearMultiSelectDDL();
                }

                this.ClientData = res.ArrayOfResponse[0].ClientDetails;
                this.TempClientData = res.ArrayOfResponse[0].ClientDetails;
                let filterData = res.ArrayOfResponse[0].ClientDetails.filter(item => item.ClientGroupName !== null);
                this.FamilyData = [...new Set(filterData.map(item => item.ClientGroupName))];

                if (flag == 'Subbroker') {               //Added by Saddam
                  this.ChildSubbrokerData = [];
                  if (IsValidArray(res.ArrayOfResponse[0].ChildSubBrokers)) {
                    this.ChildSubbrokerData = res.ArrayOfResponse[0].ChildSubBrokers;
                  }
                }
              }
            }
            else {
              if (flag == 'Subbroker') {                 //Added by Saddam
                this.ChildSubbrokerData = [];
                if (this.isMultiSelect) {
                  this.ClearMultiSelectDDL();
                }
                if (IsValidArray(res.ArrayOfResponse[0].ChildSubBrokers)) {
                  this.ChildSubbrokerData = res.ArrayOfResponse[0].ChildSubBrokers;
                }
              }
            }
          }
        }, (err: any) => {
          this.Loader.hide();
          console.log(err);
        })
    }
    $(".FamilySelect").val('').trigger('change');

    //this.setDropDownData();
  }
  onChangeHierarchy(event: any) {
    let HierarchyId = event.target.value;
    this.clientFilterForm.controls['HierarchyId'].setValue(HierarchyId);
    let PlaceDataFilter = this.AllEmpData.filter(item => item.HierarchyName == HierarchyId);
    this.PlaceDataALL = [...new Map(PlaceDataFilter.map(item => [item['PlaceName'], item])).values()]
    $(".PlaceSelect").val('').trigger('change');
  }
  onChangePlace(event: any) {
    let PlaceId = event.target.value;
    if (PlaceId != '') {
      this.clientFilterForm.controls['PlaceId'].setValue(PlaceId);
      let EmployeeDataall = this.AllEmpData.filter(item => item.PlaceName == PlaceId);
      this.EmployeeDataH = [...new Map(EmployeeDataall.map(item => [item['ID_Code'], item])).values()]
    }
    $(".EmployeeSelect").val('').trigger('change');
    //this.setDropDownData();
  }
  onCheck(value: any) {

    let data = window.location.pathname;
    let filterData = {
      ShowTransaction: false
    }
    this.FilterData.emit(filterData)

    //this.setDropDownData();
    this.ClearDropDown();
    this.FilterType = value;

    if (this.isMultiSelect) {
      this.ClearMultiSelectDDL();
    }

    if (data != '/reports/Monthly-SIP-collection-report' && data != '/reports/fd-bond-form-entry-report'
      && data != '/reports/Final-Mobilization-Report' && data != '/reports/reversal/Rejection-Report'
      && data != '/reports/MF-Mobilization-Report' && data != '/reports/Fresh-SIP-Addition'
      && data != '/reports/FD-Mobilization-And-Maturity-Report' && data != '/reports/Client-FD-PortFolio-Report'
      && data != '/reports/Redemption-Report' && data != '/reports/FD-Maturity-Report') {
      if (this.FilterType == "byEmpSubroker") {
        this.isDisplayEmployeeName = true;
        this.isDisplaySubBrokerName = true;
        this.isDisplayEmpSubClientName = true;
        this.isDisplayEmpSubBrokerFamilyName = true;
        this.isDisplayClientName = false;
        this.isdisplayFamilyName = false;
        //Added by Saddam
        this.ClientData = this.ClientData_Temp;
        this.TempClientData = this.ClientData_Temp;
        this.FamilyData = this.FamilData_Temp;
        // this.BindDropdown();
      } else if (this.FilterType == 'byClient') {
        this.isDisplayClientName = true;
        this.isdisplayFamilyName = true;
        this.isDisplayEmployeeName = false;
        this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        //Added by Saddam
        this.ClientData = this.ClientData_Temp;
        this.TempClientData = this.ClientData_Temp;
        this.FamilyData = this.FamilData_Temp
        // this.BindDropdown();
      } else if (this.FilterType == 'byHierachy') {
        this.isDisplayClientName = false;
        this.isdisplayFamilyName = false;
        this.isDisplayEmployeeName = false;
        this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.IsHeirarchyEmp = true;
        //Added by Saddam
        this.ClientData = this.ClientData_Temp;
        this.TempClientData = this.ClientData_Temp;
        this.FamilyData = this.FamilData_Temp
      }
    }

    if (data == '/reports-new/client-wise-mobilization' || data == '/reports-new/client-wise-aum' || data == '/reports-new/net-sell-report' || data == '/reports-new/fresh-sip') {   //Added by Saddam  //Added by Saddam
      debugger
      this.isDisplayClientName = false;
      this.isdisplayFamilyName = false;
      // this.isDisplayEmployeeName = false;
      // this.isDisplaySubBrokerName = false;
      this.isDisplayEmpSubClientName = false;
      this.isDisplayEmpSubBrokerFamilyName = false;
    }
    if (data == '/reports-new/business-metric') {
      this.isDisplayClientName = false;
      this.isdisplayFamilyName = false;
      this.isDisplayEmpSubClientName = false;
      this.isDisplayEmpSubBrokerFamilyName = false;
      this.isDisplaySubBrokerName = false;
    }
    if (data == '/reports-new/business-mobilization-snapshot') {
      if (this.UserType == '1') {
        if (this.FilterType == 'byHierachy') {
          this.isDisplayEmployeeName = false;
          this.isDisplaySubBrokerName = false;
          this.IsHeirarchyEmp = true;
        }
        else {
          this.isDisplayEmployeeName = true;
          this.isDisplaySubBrokerName = true;
        }
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
        this.isDisplayClientName = false;
        this.isdisplayFamilyName = false;
        this.HierarchyClient = false;
      }
      else {
        this.isDisplayClientName = false;
        this.isdisplayFamilyName = false;
        // this.isDisplayEmployeeName = false;
        // this.isDisplaySubBrokerName = false;
        this.isDisplayEmpSubClientName = false;
        this.isDisplayEmpSubBrokerFamilyName = false;
      }
    }
    // if (data == '/transaction-new/lumpsum' || data == '/transaction-new/sip'
    // || data == '/transaction-new/swp' || data == '/transaction-new/stp'
    // || data == '/explore-funds-new' || data == '/clients-new'
    // || data == '/SGB/SGBInvest' || data == '/SGB/SGBOrderBook'
    // || data == '/fd/fixed_deposite' || data == '/fd/fd-profile'
    // || data == '/fd/fd-orderbook' || data == '/fd/bajajfd-orderbook'
    // || data == '/goal-based-investing/park-money' || data == '/goal-based-investing/create-wealth') {
    //   if (this.FilterType == "byEmpSubroker") {
    //     this.isDisplayEmployeeName = true;
    //     this.isDisplaySubBrokerName = true;
    //     this.isDisplayEmpSubClientName = true;
    //     this.isDisplayEmpSubBrokerFamilyName = true;
    //     this.isDisplayClientName = false;
    //     this.isdisplayFamilyName = false;
    //   } else if (this.FilterType == 'byClient') {
    //     this.isDisplayClientName = true;
    //     this.isdisplayFamilyName = true;
    //     this.isDisplayEmployeeName = false;
    //     this.isDisplaySubBrokerName = false;
    //     this.isDisplayEmpSubClientName = false;
    //     this.isDisplayEmpSubBrokerFamilyName = false;
    //   } else if (this.FilterType == 'byHierachy') {
    //     this.isDisplayClientName = false;
    //     this.isdisplayFamilyName = false;
    //     this.isDisplayEmployeeName = false;
    //     this.isDisplaySubBrokerName = false;
    //     this.isDisplayEmpSubClientName = false;
    //     this.isDisplayEmpSubBrokerFamilyName = false;
    //   }
    // }

  }
  ClearDropDown() {
    $(".ClientSelect").prop("disabled", false);
    this.ClientData = [];
    this.ClientData = this.TempClientData;
    $('select').val('').trigger('change.select2');
    this.clientFilterForm.patchValue({
      ClientBasicInfoId: '',
      FamilyId: '',
      EmpId: '',
      SubbrokerId: '',
      HierarchyId: '',
      PlaceId: ''
    }
    );
    this.ChildSubbrokerData = [];
  }
  BindDropdown() {

    this.Loader.show();

    let request = {
      "LoginId": this.LoginId,
      "LoginType": this.UserType,
      "FilterType": this.DDLFilterType,
      "Param1": this.Param1,
      "Param2": ""
    }

    if (this.UserType == '1') {
      this.LoginId = this.encryptDecrypt.getDecr(sessionStorage.getItem('F44sGAGh2xwkpUL'));
    }
    let body = { data: this.encryptDecrypt.setEncr(JSON.stringify(request)) }
    this.ClientFilterService.WrapperApiCall(body, "/api/ClientFilters/getClientFilters").subscribe(
      (response: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(response))
        this.ResponseData = res;
        //console.log(res)
        this.Loader.hide();
        if (res.code == 200) {
          console.log(res);

          if (this.isMultiSelect) {
            this.ClearMultiSelectDDL();
          }

          if (IsValidArray(res.ArrayOfResponse[0].ClientDetails)) {
            this.ClientData = res.ArrayOfResponse[0].ClientDetails;
            this.TempClientData = res.ArrayOfResponse[0].ClientDetails;
            this.ClientData_Temp = res.ArrayOfResponse[0].ClientDetails;
            let filterData = res.ArrayOfResponse[0].ClientDetails.filter(item => item.ClientGroupName !== null);
            this.FamilyData = [...new Set(filterData.map(item => item.ClientGroupName))];

            let FromDashboard = sessionStorage.getItem("hgWUIy487$mJwue");
            if (FromDashboard != null && FromDashboard != undefined) {
              let rr = this.EncrdecrService.getDecr(FromDashboard).split('#*#');
              let k = this.ClientData.find(a => a.ClientBasicInfoId == parseInt(rr[0]));
              setTimeout(() => {
                if (rr[3] == 's') {
                  $('.ClientSelect').val(k.ClientBasicInfoId).trigger('change');
                }
                else {
                  $('#ddlClient ul.item1 li:nth-child(1)').trigger('click');
                }
              }, 1000);
            }

            this.FamilData_Temp = this.FamilyData;
          }
          if (IsValidArray(res.ArrayOfResponse[0].SubbrokerDetails)) {
            this.SubbrokerData = res.ArrayOfResponse[0].SubbrokerDetails;
          }
          if (IsValidArray(res.ArrayOfResponse[0].EmployeeDetails)) {

            let filterData = res.ArrayOfResponse[0].EmployeeDetails.filter(item => item.ID_Code !== null);
            this.AllEmpData = filterData;
            this.EmployeeData = [...new Map(filterData.map(item => [item['ID_Code'], item])).values()]

            this.PlaceData = [...new Map(filterData.map(item => [item['PlaceName'], item])).values()]
            this.PlaceDataALL = this.PlaceData;

            this.HierarchyData = [...new Map(filterData.map(item => [item['HierarchyName'], item])).values()]
          }

          if (IsValidArray(res.ArrayOfResponse[0].ChildSubBrokers)) {
            this.ChildSubbrokerData = res.ArrayOfResponse[0].ChildSubBrokers;
          }
        }
      }, (err: any) => {
        this.Loader.hide();
        console.log(err);
      })

  }

  setDropDownData() {
    this.ClientFilterService.setSelectedDropdownData(this.clientFilterForm.value);
  }


  onItemSelect(e: any) {
    this.ClearClientDetails();
    const ClientId = e.ClientBasicInfoId;
    const clientName = e.clientname;
    if (ClientId) {
      const i = this.result.indexOf(ClientId);
      if (i === -1) {
        if (this.result.length > 0) {
          this.resultDisplayName = [];
          this.resultDisplayName.push('Multiple');
        } else {
          this.resultDisplayName.push(clientName);
        }
        this.result.push(ClientId);
      } else {
        this.result.splice(i, 1);
        this.resultDisplayName.splice(i, 1);
        if (this.result.length > 1) {
          this.resultDisplayName = [];
          this.resultDisplayName.push('Multiple');
        } else {
          this.resultDisplayName = [];
          this.resultDisplayName.push(clientName);
        }
      }
      this.clientFilterForm.controls.ClientBasicInfoId.setValue(this.result.toString());

      let filterData = [];
      if (this.result.length > 1) {
        filterData = this.TempClientData.filter((item) => this.result.includes(item.ClientBasicInfoId));
      } else {
        filterData = this.TempClientData.filter(item => item.ClientBasicInfoId == this.result[0]);
      }

      if (this.result.length > 0) {
        filterData[0].ClientID = this.result;
        filterData[0].flag = "Client";
        filterData[0].resultDisplayName = this.resultDisplayName;
      } else {
        this.resultDisplayName = [];
        filterData = [{ "ClientID": "", "resultDisplayName": this.resultDisplayName }];
      }
      this.FilterData.emit(filterData[0]);
      console.log(filterData);

    }
  }

  onItemSelectDeselectAll(items: any, para: number) {
    this.ClearClientDetails();
    switch (para) {
      case 0:
        this.result = [];
        this.resultDisplayName = [];
        // this.selectedItems=[];
        this.resultDisplayName.push('All');
        items.map(item => {
          this.result.push(item.ClientBasicInfoId);
        });
        break;
      case 1:
        this.result = [];
        this.resultDisplayName = [];
        this.selectedItems = [];
        break;
    }

    let filterData = this.TempClientData;
    filterData[0].ClientID = this.result;
    filterData[0].flag = "Client";
    filterData[0].resultDisplayName = this.resultDisplayName;
    console.log(filterData);
    this.clientFilterForm.controls.ClientBasicInfoId.setValue(this.result.toString());
    this.FilterData.emit(filterData[0]);

  }

  ClearClientDetails() {
    this.clientFilterForm.controls.ClientBasicInfoId.setValue("");
  }
  CallAlertModal(msg: any) {
    $("#AlertModal").modal({
      backdrop: "static",
      keyboard: false,
    });
    $("#AlertModalContent").text(msg);
  }

  // Clear Multi-Select previously selected values : Added by Saddam
  ClearMultiSelectDDL() {
    let filterData = [];
    this.resultDisplayName = [];
    this.ClientData = [];
    this.selectedItems = [];
    this.result = [];
    filterData = [{ "ClientID": "", "resultDisplayName": this.resultDisplayName }];
    this.FilterData.emit(filterData[0]);
  }

}
