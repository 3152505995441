<div class="modal" id="fdAlertMode" appDisabledRightClick>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content AlertModalContentCls">
        <div class="modal-header AlertModalHeader">Important Message</div>
        <div class="modal-body AlertModalBody" id="fdAlterMsg">
          <!-- <p>Your Not Eligible For Online FD.</p>
          <p>Please Go to your Profile and Enbale FD Profile.</p> -->
        </div>
        <div class="modal-footer AlertModalFooter">
          <!-- <button type="button" data-dismiss="modal" class="CursorPointer" (click)="onclickclose()">Close</button> -->
        </div>
      </div>
    </div>
  </div>
