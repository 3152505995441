
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EncrdecrService } from '../../Auth/encrdecr.service';
import { isNullOrUndefined } from "../../validation";
import { ProfileService } from 'src/app/services/profile.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { CustomLoaderService } from 'src/app/services/custom-loader.service';
import { LoginService } from "../../services/login.service";
import { TokenExpireService } from 'src/app/services/token-expire.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { OtpComponent } from '../otp/otp.component';
import { NseOnboardingService } from "./../../services/nse-onboarding.service";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { CpOnBoardingService } from '../../services/cp-on-boarding.service';
declare var $: any;

@Component({
  selector: 'app-profile-preview-model',
  templateUrl: './profile-preview-model.component.html',
  styleUrls: ['./profile-preview-model.component.scss']
})
export class ProfilePreviewModelComponent implements OnInit {

  dataString: any;
  PANNo: any;
  Password: string;
  UserName: any;
  ClientBasicInfoId: any;
  USER_TYPE: any;
  TOKEN_ID: any;
  ClientPanNo: string;
  firstname: any;
  MiddleName: any;
  LastName: any;
  ClientGender: string;
  ClientMobileNo: string;
  ClientEmailId: string;
  ClientDOB: any;
  Address1: string;
  Address2: string;
  Pincode: any;
  City: any;
  Country: string;
  State: any;
  NomineeName: any;
  NomineeDateofBirth: any;
  relationship: any;
  IFSCCode: any;
  ClientBankAccountNo: string;
  birthCountry: any;
  Occupation: any;
  IndianTaxResident: string;
  PoliticalExposure: any;
  GrossAnnualIncome: any;
  imageSignature: any;
  imageCheque: any;
  imagePhoto: any;
  StateList: any;
  GenderList: any;
  TitleList: any;
  MaritalStatusList: any;
  AccountTypeList: any;
  OccupationTypeList: any;
  PoliticallyExposedTypeList: any;
  AnnualIncomeList: any;
  RelationList: any;
  BankList: any;
  maratalstatus: any;
  MaritalStatus: any;
  GeneratedUCCCode: any;
  G_UCCCode: any;
  GuardianName: string;
  GuardianRelation: string;
  Accountype: any;
  BranchName: any;
  MICRCode: any;
  BankName: any;
  Relationwithemailholder: any;
  Relationwithemobholder: any;
  RelationListwithHolder: any;
  public bsModelRef: BsModalRef;
  showprofilepreview: boolean = false;
  QuestionList: any;
  QustionArr: any = [];
  NomineeMinor: string;
  RiskProfileName: any;
  RiskProfileForm: FormGroup;
  QAnsArray: any;
  QusAnsList: any = [];
  Answerlst: any = [];
  CountryList: any = [];
  ShowLogout: boolean = false;
  constructor(private EncrdecrService: EncrdecrService, private route: ActivatedRoute,
    private clientProfileService: ProfileService, private transaction: TransactionService,
    private Loader: CustomLoaderService, private LoginService: LoginService, private bsModelService: BsModalService,
    private formBuilder: FormBuilder, private CpOnBoardingSr: CpOnBoardingService,
    private Logout: TokenExpireService, private DomSanitizer: DomSanitizer, private NseOnboardingService: NseOnboardingService) {
    this.RiskProfileForm = formBuilder.group({});
  }

  ngOnInit() {
    $('.MainBg').css('min-height', window.innerHeight - 112);
    this.Loader.show();
    this.route.queryParams.subscribe(params => {
      try {
        this.dataString = this.EncrdecrService.getDecr(
          params.Data.toString().replace(/\s/g, '+'));
        var datasource = this.dataString.split('|');
        this.AuthenticateClient(datasource);
      } catch (error) {
        this.Logout.LogoutUser();
      }


    });

  }
  AuthenticateClient(data) {
    this.route.queryParams.subscribe((params) => {
      if (!isNullOrUndefined(data)) {
        this.PANNo = data[0];
        this.Password = this.EncrdecrService.getDecr(data[3]);
        this.UserName = data[1];
        this.ClientBasicInfoId = data[5];
        this.USER_TYPE = data[6];
        sessionStorage.setItem(
          "N1QJHdOkO7Hz8QC",
          this.EncrdecrService.setEncr(this.ClientBasicInfoId)
        );
        this.SendOTP(data[5]);
      }
    });
  }
  SendOTP(clientid) {
    const request = {
      ClientBasicInfoId: this.EncrdecrService.setEncr(clientid),
      //Flag : 'G',
      TransactionType: this.EncrdecrService.setEncr("profilepreviewOTP_AU"),
    };
    var json = JSON.stringify(request);
    this.transaction.sendTransactionOTP(json).subscribe((data) => {
      //
      console.log(data.Message);
      if (data.Message.indexOf("Success") > -1) {
        this.Loader.hide();
        this.bsModelRef = this.bsModelService.show(OtpComponent, {
          class: "modal-dialog-centered modal-lg modal_otp",
          ignoreBackdropClick: true,
        });
        this.bsModelRef.content.onClose = new Subject<boolean>();
        this.bsModelRef.content.action = new Subject<boolean>();
        this.bsModelRef.content.onClose.subscribe((res) => {
          if (res == true) {
            this.Loader.show();
            const loginrequest = {
              Username: this.PANNo,
              Password: this.Password,
            };
            sessionStorage.setItem("Tgsh@#3734fjfskshh==", "173.212.234.56");
            let IpAddress = sessionStorage.getItem("Tgsh@#3734fjfskshh==");
            const loginjson = JSON.stringify(loginrequest);
            this.LoginTOAccount(loginjson, IpAddress);
          }
        });
        this.bsModelRef.content.action.subscribe((res) => {
          if (res == true) {
            this.ResendOTP();
          }
        });
      }
      else {
        this.Loader.hide();
        this.CallAlertModal('There seems to be some issue...!');
      }
    },
      err => {
        this.Loader.hide();
      });
  }
  LoginTOAccount(loginjson, IpAddress) {
    this.LoginService.PostLogin_NSE_Info(
      loginjson,
      parseInt(this.USER_TYPE),
      IpAddress
    ).subscribe(
      (res: any) => {
        console.log(res);
        if (res != "Invalid Username and Password" && res.TOKEN_ID != null) {
          sessionStorage.setItem("`huR,8@RK9^??b4 -.hfs!!uR,XX", res.TOKEN_ID);
          this.TOKEN_ID = res.TOKEN_ID;
          sessionStorage.setItem("F44sGAGh2xwkpUL", res.LOGIN_ID); // Session_SubBrokerId
          sessionStorage.setItem("V4WGROuLLBE6t98", res.USER_NAME); // Session_UserName
          sessionStorage.setItem("V8hpPfITcxhEI9x", res.EMAIL_ID); // Session_UserEmail
          sessionStorage.setItem("Xe003SRRyYEoOC2", res.MOBILE); // Session_UserMobile
          if (
            res.RazorCustId !== null &&
            res.RazorCustId !== undefined &&
            res.RazorCustId !== "null"
          ) {
            sessionStorage.setItem("pOWgAF62qFXnZMn", res.RazorCustId); // RazorCustId
          } else {
            sessionStorage.setItem("pOWgAF62qFXnZMn", ""); // RazorCustId
          }
          sessionStorage.setItem("Hldq31TLYwRbLJ8", res.SubBrokerCode); // Session_SubBrokerCode
          sessionStorage.setItem("L2D3506kIHSk3E0", res.USER_TYPE); // Session_UserType
          sessionStorage.setItem("qAkZrQYLWNBSlki", res.LOGIN_ID); // Session_CBId
          //#region string base64
          sessionStorage.setItem("pi4ZU2P3M8JE4q7", ""); // Session_B64CompanyLogo
          //#endregion

          if (
            res.Family_ID !== null &&
            res.Family_ID !== undefined &&
            res.Family_ID !== "null"
          ) {
            sessionStorage.setItem("hn0bHyGhfpGtrPe", res.Family_ID); // Session_Family_ID
          }
          switch (this.EncrdecrService.getDecr(res.USER_TYPE)) {
            case "3":
              sessionStorage.setItem("m5JkoXISmYRAIuY", res.USER_ID); // Session_UserId
              sessionStorage.setItem("UZT6qHaDZSz66kx", res.LOGIN_ID); // Session_ClientBasicInfoId
              sessionStorage.setItem("N1QJHdOkO7Hz8QC", res.LOGIN_ID); // Session_ClientBasicInfoId

              // this.BindQuestionAnswerList();
              //sessionStorage.setItem('2EjsRIl52CU8VUD8fwMc=',res.AgeWithAnsIDforRP); //Age and AnswerID of Client for Risk  eg. 25|16 i.e Age|AnsId

              //this.router.navigate(['/client-profile'], { relativeTo: this.route });

              break;

          }
          this.showprofilepreview = true;
          this.BindState("");
          this.BindCountry();
          //this.BindGender("");
          //this.BindTitle("");
          //this.BindMaritalStatus("");
          this.BindAccountType("");
          this.BindOccupationTypeList("");
          this.BindOccupationTypeList("");
          this.BindPoliticallyExpsedList("");
          this.BindAnnualIncomeList("");
          this.BindRelation("");
          this.BindBankList("");
          this.BindRelationwithHolder();

          // this.NseOnboardingService.GetRiskProfileQuestionList().subscribe((data) => {
          //   //
          //   if (data != null) {
          //     this.QuestionList = data.ArrayOfResponse;

          //     //this.QuestionList.pop();
          //     ////
          //     //console.log("Risk Profile Question :: " + this.QuestionList);
          //     this.QuestionList.forEach((qus) => {
          //       this.QustionArr.push(qus.QuestionId);
          //       // this.RiskProfileForm.addControl(
          //       //   `Risk_${qus.QuestionId}`,
          //       //   new FormControl("", Validators.required)
          //     //  );
          //     });
          //   } else {
          //     this.Loader.hide();
          //   }
          // });
          this.NseOnboardingService.GetRPDataofClient(
            this.ClientBasicInfoId,
            "QueAnsByClient"   //GetRPdataofClient
          ).subscribe((res: any) => {
            //
            if (res != null) {
              let QusAnsList = res.ArrayOfResponse;
              this.QusAnsList = res.ArrayOfResponse;
              // QusAnsList.pop();
              if (QusAnsList.length > 0) {
                QusAnsList.map((arr: any, index: number) => {
                  // if (arr.QuestionId != 10 && arr.QuestionId != 11) {
                  // this.RiskProfileForm.controls[
                  //   `Risk_${arr.QuestionId}`
                  // ].setValue(arr.AnswerId);
                  this.Answerlst.push(arr.AnswerId);
                  setTimeout(() => {
                    $(`#Risk_${arr.QuestionId}`).val(arr.AnswerId).trigger('change');
                  }, 1000);

                  //  }

                });
              }
            } else {
              this.Loader.hide();
            }
          });
          this.BindClientData();
        } else {
          this.Loader.hide();
          this.CallAlertModal("There seems to be some issues");
        }
      },
      (err: any) => {
        //
        // console.log(err)
        const rr = err.error;
        const er = rr.substring(1, rr.length - 1);
        const rk = er.split(", ");
        this.Loader.hide();
        if (rk[1].includes("Number of invalid login attempts")) {
          this.CallAlertModal(
            "Number of invalid login attempts. Your account is locked for 15 minutes"
          );
        } else {
          this.Loader.hide();
          this.CallAlertModal("There seems to be some issue please try again later");
        }
        //// console.log('err =>' + er);
      }
    );
  }
  CallAlertModal(msg: any) {
    $("#RiskProfileAlertModel").modal({ backdrop: "static", keyboard: false });
    $("#RiskProfileAlertModelContent").text(msg);
  }
  LogOut() {
    this.Logout.LogoutUser();
  }
  BindClientData() {
    this.Loader.hide();
    this.clientProfileService
      .GetClientInfo(this.ClientBasicInfoId)
      .subscribe(
        (response: any) => {
          let res = JSON.parse(this.EncrdecrService.getDecr(response))
          //console.log(JSON.stringify(res));
          if (res != null) {
            if (
              res.Message != null &&
              res.Message != "" &&
              res.Message.indexOf("Not Authorized") > -1
            ) {
              this.Loader.hide();
              this.Logout.LogoutUser();
              return;
            } else {

              let BasicDetails =
                res.ArrayOfResponse[0].personalDetails[0].active[0]
                  .firstApplicant[0];

              this.firstname = BasicDetails.Investorname;
              // this.firstname = fullname[0];
              // if(!isNullOrUndefined(fullname[1]) && fullname[1] !=""){
              //   this.MiddleName = fullname[1];
              // }
              // if(!isNullOrUndefined(fullname[2]) && fullname[2] !=""){
              // this.LastName = fullname[2]
              // }
              if (BasicDetails.gender == "M") { this.ClientGender = "Male" }
              if (BasicDetails.gender == "F") { this.ClientGender = "Female" }
              if (BasicDetails.gender == "T") { this.ClientGender = "Transgender" }
              this.ClientPanNo = this.EncrdecrService.getDecr(BasicDetails.panno);
              this.ClientMobileNo = this.EncrdecrService.getDecr(BasicDetails.Mobno);
              //this.BasicDetailsForm.controls['Email'].setValue(BasicDetails.emailid);
              if (BasicDetails.emailid != null) {
                this.ClientEmailId = this.EncrdecrService.getDecr(BasicDetails.emailid);
              }
              let r = (BasicDetails.dob).split('/');
              let rr = new Date(`${r[1]}/${r[0]}/${r[2]}`);
              this.ClientDOB = BasicDetails.dob;
              //this.MaritalStatus = this.MaritalStatusList.find((e:any)=>e.VALUEFIELD == BasicDetails.MaritalStatusId).TEXTFIELD;

              if (
                res.ArrayOfResponse[0].communicationDetails[0].active
                  .length > 0
              ) {
                //

                let AddressDetail =
                  res.ArrayOfResponse[0].communicationDetails[0]
                    .active[0];
                // if(AddressDetail.add1.includes(",")){
                //   var plitadd = AddressDetail.add1.split(',');
                // }
                // else{
                //   var plitadd = AddressDetail.add1.split(' ');
                // }
                // var prt1  = plitadd.length/2;
                // if(prt1 % 1 != 0) {
                //   prt1 = Math.trunc( prt1 );
                // }
                // var length  = plitadd.length;
                // var add1string = "";
                // var add2string = "";
                // for(var i = 0; i < prt1; i++){
                //   add1string = add1string+' '+plitadd[i];
                // }
                // var address = add1string;
                // for(var i = prt1; i < length; i++){
                //   add2string = add2string+' '+plitadd[i];
                // }
                // var address2 = add2string;
                //
                //this.Address1 = address ;
                this.Address1 = AddressDetail.add1;
                // this.Address2 = address2;
                this.Pincode = AddressDetail.pincode;
                this.City = AddressDetail.city;
                try {
                  if (!isNullOrUndefined(AddressDetail.statecode) && AddressDetail.statecode != '') {
                    this.State = this.StateList.find((e: any) => e.ValueField == AddressDetail.statecode).TextField;
                  }
                }
                catch (error) {

                }
                if (AddressDetail.countrycode == 'IND') {
                  AddressDetail.countrycode = 'IN'
                }
                try {
                  if (AddressDetail.countrycode.length > 2) {
                    this.Country = AddressDetail.countrycode
                  }
                  else {
                    if (!isNullOrUndefined(AddressDetail.countrycode) && AddressDetail.countrycode != '') {
                      this.Country = this.CountryList.find((e: any) => e.ValueField == AddressDetail.countrycode).TextField;
                    }
                  }
                }
                catch (error) {

                }
              }

              // Nominee
              if (
                res.ArrayOfResponse[0].nomineeDetails[0].active[0].nominee
                  .length > 0
              ) {
                let nomineeDetails =
                  res.ArrayOfResponse[0].nomineeDetails[0].active[0]
                    .nominee[0];
                this.NomineeName = nomineeDetails.invnomname;
                this.NomineeDateofBirth = nomineeDetails.dob;
                //this.NomineePAN = this.EncrdecrService.getDecr(nomineeDetails.NomineePan);
                try {
                  if (!isNullOrUndefined(nomineeDetails.RelationshipId) && nomineeDetails.RelationshipId != '' && nomineeDetails.RelationshipId != 0 && nomineeDetails.RelationshipId != '0') { this.relationship = this.RelationList.find((e: any) => e.ValueField == nomineeDetails.RelationshipId).TextField; }
                }
                catch (error) {

                }
                this.GuardianName = nomineeDetails.GaurdianName;
                try {
                  if (!isNullOrUndefined(nomineeDetails.GuardianRelation) && nomineeDetails.GuardianRelation != '' && nomineeDetails.GuardianRelation != 0 && nomineeDetails.GuardianRelation != '0') { this.GuardianRelation = this.RelationList.find((e: any) => e.ValueField == nomineeDetails.GuardianRelation).TextField; }
                }
                catch (error) {

                }
                if (nomineeDetails.isminor == false || nomineeDetails.isminor == 'false') {
                  this.NomineeMinor = "NO"
                }
                else {
                  this.NomineeMinor = "YES"
                }
              }

              //Bank

              if (
                res.ArrayOfResponse[0].bankDetails[0].active.length > 0
              ) {
                let bankDetails =
                  res.ArrayOfResponse[0].bankDetails[0].active[0];

                this.IFSCCode = bankDetails.ifsccode;
                this.ClientBankAccountNo = this.EncrdecrService.getDecr(bankDetails.bankaccno);
                this.Accountype = bankDetails.acctype;
                this.BranchName = bankDetails.branchname;
                this.MICRCode = bankDetails.micrcode;
                this.BankName = bankDetails.BankName;

                // if(!isNullOrUndefined(bankDetails.invbankid) && bankDetails.invbankid!=''){
                //   this.BankName = this.BankList.find((e:any)=>e.ValueField == bankDetails.invbankid).TextField;
                // }
                //FATCA

                if (
                  res.ArrayOfResponse[0].fatcaDetails[0].firstApplicant !=
                  null
                ) {
                  let FatcaDetails =
                    res.ArrayOfResponse[0].fatcaDetails[0]
                      .firstApplicant[0];
                  this.IndianTaxResident = "Indian";
                  if (FatcaDetails.PoliticalExposure == '1') { this.PoliticalExposure = "YES" }
                  if (FatcaDetails.PoliticalExposure == '0') { this.PoliticalExposure = "NO" }
                  // if(!isNullOrUndefined(FatcaDetails.OccupationCode) && FatcaDetails.OccupationCode!= ''){
                  //   this.Occupation = this.OccupationTypeList.find((e:any)=>e.ValueField == FatcaDetails.OccupationCode).TextField;
                  // }

                  this.Occupation = FatcaDetails.occupation;
                  this.GrossAnnualIncome = FatcaDetails.GrossAnnualIncome;
                  try {
                    if (!isNullOrUndefined(FatcaDetails.CountryOfBirth) && FatcaDetails.CountryOfBirth != '') {
                      this.birthCountry = this.CountryList.find((e: any) => e.ValueField == FatcaDetails.CountryOfBirth).TextField;
                    }
                  }
                  catch (error) {

                  }
                  try {
                    if (!isNullOrUndefined(FatcaDetails.RelationWithEmailHolder) && this.Relationwithemailholder != '') {
                      this.Relationwithemailholder = this.RelationListwithHolder.find((e: any) => e.ValueField == FatcaDetails.RelationWithEmailHolder).TextField;
                    }
                  }
                  catch (error) {

                  }
                  try {
                    if (!isNullOrUndefined(FatcaDetails.RelationWithMobileHolder) && this.Relationwithemobholder != '') {
                      this.Relationwithemobholder = this.RelationListwithHolder.find((e: any) => e.ValueField == FatcaDetails.RelationWithMobileHolder).TextField;
                    }
                  }
                  catch (error) {

                  }
                }

                let request = {
                  QuestionIdList: this.QustionArr.toString(),
                  AnswerIdList: this.Answerlst.toString(),
                  ClientbasicinfoId: this.ClientBasicInfoId.toString(),
                };
                let DataBody = JSON.stringify(request);
                try {
                  this.NseOnboardingService.GetRiskProfileScoreAndName(DataBody).subscribe(
                    (result: any) => {
                      this.Loader.hide();
                      if (result.ArrayOfResponse.length > 0) {
                        if (result.ArrayOfResponse[0].ProfileName != "") {
                          this.RiskProfileName = result.ArrayOfResponse[0].ProfileName;
                        } else {
                          this.RiskProfileName = "NA";
                        }
                      }
                      else {
                        this.RiskProfileName = "NA";
                      }
                    }
                  );
                }
                catch (error) {

                }
              }
            }
            this.Loader.hide();
          }
        },
        err => {
          this.Loader.hide();
        });
  }
  BindState(statecode: any) {
    this.clientProfileService.GetStateList().subscribe(
      (res: any) => {
        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            this.Logout.LogoutUser();
            return;
          }
          else {

            let a = res.ArrayOfResponse;
            let b = a.slice(1);
            this.StateList = b;
            var StateJSON = JSON.stringify(this.StateList);
            sessionStorage.setItem('Qgv9DROh+jOceFdqwhI1xT', this.EncrdecrService.setEncr(StateJSON));
            console.log("State List Data ==> " + this.StateList);
          }
        }
      });
  }
  BindCountry() {
    this.clientProfileService.GetCountryList().subscribe((data_res: any) => {
      let res = JSON.parse(this.EncrdecrService.getDecr(data_res));

      if (res != null) {
        if (
          res.Message != null &&
          res.Message != "" &&
          res.Message.indexOf("Not Authorized") > -1
        ) {
          this.Loader.hide();
          this.Logout.LogoutUser();
          return;
        } else {
          let a = res.ArrayOfResponse;
          let b = a.slice(1);
          this.CountryList = b;
        }
      }
    });
  }
  BindAccountType(accountType: any) {
    this.clientProfileService.GetAccountTypeList().subscribe(
      (data_res: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(data_res));

        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            this.Logout.LogoutUser();
            return;
          }
          else {

            this.AccountTypeList = res.ArrayOfResponse;
            var json = JSON.stringify(this.AccountTypeList);
            sessionStorage.setItem('dQYayPY3T0wat9', this.EncrdecrService.setEncr(json));
            console.log("Account Type List Data ==> " + this.AccountTypeList);
          }
        }
        //console.log(this.AccountTypeList)
      });
  }
  BindOccupationTypeList(occupationCode: any) {
    this.clientProfileService.GetOccupationTypeList().subscribe(
      (data_res: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(data_res));

        if (res != null) {

          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            this.Logout.LogoutUser();
            return;
          }
          else {

            let a = res.ArrayOfResponse;
            let b = a.slice(1);
            this.OccupationTypeList = b;
            var json = JSON.stringify(this.OccupationTypeList);
            sessionStorage.setItem('3UP4CMkAdNn1sBb62', this.EncrdecrService.setEncr(json));
            console.log("Occupation Type List Data ==> " + this.OccupationTypeList);
          }
        }

      });
  }
  BindPoliticallyExpsedList(politicalCode: any) {
    this.clientProfileService.GetNPSDropDownData(politicalCode, "PoliticallyExposedFlag").subscribe(
      (res: any) => {
        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            this.Logout.LogoutUser();
            return;
          }
          else {

            let getPoliticallyExposedList = res.ArrayOfResponse;
            this.PoliticallyExposedTypeList = getPoliticallyExposedList;
            var json = JSON.stringify(this.PoliticallyExposedTypeList);
            sessionStorage.setItem('mwRC44HI9oLw2zn', this.EncrdecrService.setEncr(json));
            console.log("Political Exposed List Data ==> " + this.PoliticallyExposedTypeList);
          }
        }
      });
  }
  BindAnnualIncomeList(annualIncomeCode: any) {
    this.clientProfileService.GetAnnualIncomeList().subscribe(
      (data_res: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(data_res));

        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            this.Logout.LogoutUser();
            return;
          }
          else {

            let a = res.ArrayOfResponse;
            let b = a.slice(1);
            this.AnnualIncomeList = b;
            var json = JSON.stringify(this.AnnualIncomeList);
            sessionStorage.setItem('NXh2XKswerfOlKs5JcUq', this.EncrdecrService.setEncr(json));
            console.log("Annual Income List Data ==> " + this.AnnualIncomeList);
          }
        }
      });
  }
  BindRelation(relationCode: any) {
    this.clientProfileService.GetRelationList().subscribe(
      (data_res: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(data_res));

        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            this.Logout.LogoutUser();
            return;
          }
          else {

            let a = res.ArrayOfResponse;
            let b = a.slice(1);
            this.RelationList = b;
            var json = JSON.stringify(this.RelationList);
            sessionStorage.setItem('JeR5r6N0aTFlrDfX26UPte', this.EncrdecrService.setEncr(json));
            console.log("Relationship List Data ==> " + JSON.stringify(this.RelationList));
          }
        }
      });
  }
  BindBankList(bankListCode: any) {
    this.clientProfileService.GetBankListBSE().subscribe(
      (data_res: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(data_res));
        if (res != null) {

          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            this.Logout.LogoutUser();
            return;
          }
          else {

            this.BankList = res.ArrayOfResponse;
            var json = JSON.stringify(this.BankList);
            sessionStorage.setItem('9qQBQnBqlukamtQn6', this.EncrdecrService.setEncr(json));
          }
        }
      });
  }
  OnsubmitUccCreate() {
    this.Loader.show();
    this.clientProfileService
      .GetGenerateUCC(this.ClientBasicInfoId)
      .subscribe(
        (response: any) => {

          let res = JSON.parse(this.EncrdecrService.getDecr(response))
          if (res != null) {
            if (
              res.Message != null &&
              res.Message != "" &&
              res.Message.indexOf("Not Authorized") > -1
            ) {
              this.Loader.hide();
              this.Logout.LogoutUser();
              return;
            } else if (
              res.Message.includes("FILE CONTAIN SPECIAL CHARACTERS")
            ) {
              this.Loader.hide();
              // this.OpenDash(
              //   "Sorry! Your data contains special character. Please contact your Branch / RM."
              // );
            } else {
              //$("#GenerateUCCModal").modal("hide");
              if (res.Code !== "") {
                this.GeneratedUCCCode = res.Code;
                if (
                  this.G_UCCCode == null ||
                  this.G_UCCCode == undefined ||
                  this.G_UCCCode == "" ||
                  this.G_UCCCode == "0"
                ) {
                  this.G_UCCCode = res.Code;
                }
                this.UpdateRPConfirmLog()
                this.Loader.hide();
                this.ShowLogout = true;
                this.CallAlertModal("You are now Investment Ready..!");
                //}
              } else {
                this.Loader.hide();
                this.CallAlertModal(res.Message);
              }
            }
          }
        },
        (err) => {
          this.Loader.hide();
          //console.log(err)
        }
      );
  }
  BindRelationwithHolder() {
    this.clientProfileService
      .GetRelationwithholderlist()
      .subscribe((data_res: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(data_res));

        if (res != null) {
          if (
            res.Message != null &&
            res.Message != "" &&
            res.Message.indexOf("Not Authorized") > -1
          ) {
            this.Loader.hide();
            this.Logout.LogoutUser();
            return;
          } else {
            this.RelationListwithHolder = res.ArrayOfResponse;
          }
        }
      });
  }
  ResendOTP() {
    const request = {
      ClientBasicInfoId: this.EncrdecrService.setEncr(this.ClientBasicInfoId),
      //Flag : 'G',
      TransactionType: this.EncrdecrService.setEncr("TransactionClient"),
    };
    var json = JSON.stringify(request);
    this.transaction.sendTransactionOTP(json).subscribe((data) => {
    });
  }
  // BindQuestionAnswerList() {
  //   this.NseOnboardingService.GetRiskProfileQuestionList().subscribe((data) => {
  //     this.Loader.hide();
  //     if (data != null) {
  //       this.QuestionList = data.ArrayOfResponse;
  //       //this.QuestionList.pop();

  //       //console.log("Risk Profile Question :: " + this.QuestionList);
  //       // this.QuestionList.forEach((qus) => {
  //       //   this.QustionArr.push(qus.QuestionId);
  //       //   this.RiskProfileForm.addControl(
  //       //     `Risk_${qus.QuestionId}`,
  //       //     new FormControl("", Validators.required)
  //       //   );
  //       // });

  //       this.NseOnboardingService.GetRPDataofClient(
  //         this.ClientBasicInfoId,
  //         "QueAnsByClient" //"GetRPdataofClient"
  //       ).subscribe((res: any) => {
  //         //
  //         if (res != null) {
  //           let QusAnsList = res.ArrayOfResponse;
  //             //var finalarray =  this.QuestionList.find((e:any)=>e.QuestionId == QusAnsList.QuestionId );

  //           this.QAnsArray = this.QuestionList.map((item)=>{
  //             item.QuestionId == QusAnsList.QuestionId
  //             item.AnswerId == QusAnsList.AnswerId
  //           })
  //          // QusAnsList.pop();
  //           if (QusAnsList.length > 0) {
  //             QusAnsList.map((arr: any, index: number) => {
  //               if (arr.QuestionId != 10 && arr.QuestionId != 11) {
  //                 // this.RiskProfileForm.controls[
  //                 //   `Risk_${arr.QuestionId}`
  //                 // ].setValue(arr.AnswerId);
  //               }
  //             });
  //           }
  //         } else {
  //           this.Loader.hide();
  //         }
  //       });
  //     } else {
  //       this.Loader.hide();
  //     }
  //   });
  // }
  UpdateRPConfirmLog() {

    const request = {
      Clientbasicinfoid: this.ClientBasicInfoId,
      BSESchemeCode: "",
      RPConfirmationFlag: 'Y',
      Flag: "ClientOnBoardConfirmation"
    }

    this.clientProfileService.UpdatehighRPConfirmation(JSON.stringify(request)).subscribe((res) => {
      if (res.ID == 0) {

      }
    }, (error) => {
      this.Loader.hide();
    });
  }
  LogoutUser() {
    this.Logout.LogoutUser();
  }
}
