
    <div class="modal-header AlertModalHeader">Cut off Timings</div>
    <div class="modal-body AlertModalBody" style="height:450px; overflow-y: scroll;">
        <p>
            <br />
            The NAV that will be used to determine the number of units that will be allotted to the investor for
            purchase transactions will depend on the date and time of receipt of application as well as the date
            and time of receipt of funds: Details of these cut off times are as under:
            <br />

            <b>NAV applicability for all types of purchases of mutual funds:</b>
            <br />



            <b>For liquid and overnight funds:</b>
            <br />


            The cut off for receipt of purchase applications at the AMC/RTA offices is 1.30 pm on every working
            day. If funds are also received prior to this cut off time, then the NAV of the previous day will be
            applied for allotment of units. NAV of the previous day will be used based on the timing of the
            receipt of application or receipt of funds, whichever is later.


            <br />
            <b>For other debt and equity schemes:</b>
            <br />



            The cut off for receipt of purchase applications at the AMC/RTA offices is 3 pm on every working
            day. If funds are also received prior to this cut off time, then the NAV of that same day will be
            applied for allotment of units. NAV of the same day will be used based on the timing of the receipt
            of application or receipt of funds, before the cut-off time, whichever is later. In case either the
            funds or the application is received after the cut off time, the NAV of the next working day will be
            used for allotment of units

            <br />

            NAV applicability for SIP instalments will also follow the above rule i.e. the NAV of the day, on
            which the SIP instalment is received by the AMC in its bank account, will be utilised for processing
            of transactions.


            <br />
            <b>NAV applicability for redemptions from all types of mutual fund schemes:</b>
            <br />



            <b>For liquid and overnight funds:</b>

            <br />

            The cut off time for receipt of redemption applications at the AMC/RTA offices is 1.30 pm. Pay out
            from these schemes generally happens on the next working day.

            <br />


            <b>For other debt and equity schemes:</b>
            <br />



            The cut off time for receipt of redemption applications for debt and equity schemes at the AMC/RTA
            offices is 3 pm. Pay-out from debt schemes generally happens on the next working day and for equity
            schemes on the third working day after the receipt of application by the AMC before the cut- off
            time on that day.


            <br />
            <b>Switch in/Switch out applications:</b>
            <br />

            Applications for switch out from any scheme will be treated as a redemption application and the NAV
            that would have been used as if the application was a redemption application. The switch in
            application will be treated as a purchase application and the NAV rule applicable to purchase
            applications will be used to determine the NAV that will be used to process the transaction.
            Accordingly, NAVs for purchase of units will be applied on the day on which the funds are received
            from the scheme in which the redemption application is processed.
            <br />


            <b>General rules:</b>
            <br />
            1.Unit allotment will be based on Funds available in Scheme accounts or timestamped date and time
            whichever is later - irrespective of schemes or threshold amount except for NFOs.
            <br />
            2.The cut off times for receipt of applications are applicable when the applications are received by
            the AMC/RTA. For applications received by Mahindra Finance, generally, applications received on the
            online transaction platform an hour before these cut off times will be handed over to the AMCs
            before the cut off time. Applications received less than sixty minutes before the cut off times will
            be processed by Mahindra Finance and its vendors on a best effort basis. Clients are requested to
            ensure that applications are punched in at least sixty minutes before the cut off times to enable
            transactions to be sent to the AMCs/RTAs on the same day
            <br />
            3.In case of NFO subscriptions, allotment date (as decided by the respective MF/AMC) will be
            considered irrespective of credit date / NFO application submitted on or before NFO closure date but
            credit should have been received on or before the prescribed NFO allotment date.
            <br />
            4.In case of switches into NFO schemes from the existing schemes, Switch-Out will be processed in
            the existing schemes based on time-stamped date and time, Switch-In units will be allotted only on
            NFO Allotment date (as decided by the respective MF/AMC)
            <br />
            5.Where funds are transferred first and application is submitted thereafter, time-stamped date and
            time of the application will be considered for NAV applicability.
            <br />
            6.Actual debit in customer account may differ from date/time of Funds available for AMC utilisation.
            NAV of that day will be applied when the funds are received in the AMC’s bank account.
            <br />
            7.In case of systematic triggers, even if an investor opts for specific day of the week / month /
            quarterly / half-yearly / annual, actual trade date for each instalment will vary as funds available
            for AMC utilization may be delayed due to various associated process and other stakeholders
            involved.
            <br />
            8.In case of cheques accompanied with the application, realisation of funds will happen after a
            couple of days due to time required for cheque deposit, associated settlement system in banks and so
            on.
            <br />
            9.In case of online transactions, depending upon your bankers, there will be time-lag in the
            transfer of funds to MF scheme account and hence trade date and applicable NAV may differ on
            case-to-case basis.
        </p>
    </div>
