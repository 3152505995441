import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cuttoff-timing-modal-bse',
  templateUrl: './cuttoff-timing-modal-bse.component.html',
  styleUrls: ['./cuttoff-timing-modal-bse.component.scss']
})
export class CuttoffTimingModalBseComponent implements OnInit {
@Input() BSECutoffContent:string;
  constructor() { }

  ngOnInit() {
  }

}
