<div *ngIf="isLoggedIn$ | async as isShowHeader">
  <nav class="navbar ShadowBottom fixed-top">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center">
        <div *ngIf="!IsMobile" class="hamrburg CursorPointer" (click)="OnClickHamerburg()">
          <i class="fa fa-bars" aria-hidden="true" style="font-size: 17px;"></i>
        </div>
        <a class="navbar-brand p-0">
          <img src="../assets/images/HDFC_Logo.png" alt="Logo" class="logo">
        </a>
      </div>
      <div class="RightSideMenu">
        <!-- <img src="../../../assets/images/Notification.svg" class="HeaderIcons" /> -->
        <div class="dropdown" [ngClass]="{ 'open': isDropdownOpen }">
          <img src="../../../assets/images/Notification.svg" class="HeaderIcons dropdown-toggle"
            (click)="toggleDropdown()" />
          <!-- Add ngIf to conditionally display the red dot -->
          <!-- <div class="notification-dot" *ngIf="hasNotifications && onClickNotificationIcon"></div> -->
          <i class="fa fa-circle  text-danger-glow blink" *ngIf="hasNotifications && onClickNotificationIcon"></i>
          <div class="dropdown-content" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Notifications</h5>
                </div>
                <div class="modal-body">
                  <div *ngIf="NotificationData.length !== 0">
                    <p *ngFor="let notification of NotificationData">{{ notification.Mobile_Web }}</p>
                  </div>
                  <div *ngIf="NotificationData.length === 0">
                    <p>No Notification Found</p>
                  </div>
                </div>
                <div class="modal-footer">
                  <div></div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <img src="../../../assets/images/Cart.svg" class="HeaderIcons" routerLink="/transaction-new/cart"
          routerLinkActive='active' />
        <!-- <a *ngIf="!IsMobile && UserType != '1'" class="navbar-brand p-0 mr-0 ml-2">
          <ng-container *ngIf="SBLogoBase64 == ''">
            <img src="../../../assets/images/JM/IFD_Logo.png" alt="Logo" class="Companylogo_Web">
          </ng-container>
          <ng-container *ngIf="SBLogoBase64 != ''">
            <img [src]="SBLogoBase64" alt="Logo" class="Companylogo_Web">
          </ng-container>
        </a> -->
      </div>

    </div>
  </nav>
  <div class="MarginTopAsHeader">
    <nav [ngClass]='IsMobile ? "d-flex":"d-none"' class="navbar fixed-bottom bg-white">
      <ul class="navbar-nav m-0 w-100 text-center">
        <li class="nav-item active" (click)="onClickFooterMenu('Home')" routerLinkActive='active'>
          <img alt="N/A" *ngIf="!IsHomeActive" src="../../../assets/images/JM/Home_Inactive.svg" class="" />
          <img alt="N/A" *ngIf="IsHomeActive" src="../../../assets/images/JM/Home_Active.svg" class="" />
          <a [ngClass]="IsHomeActive ? 'ClrRed_n':''" class="nav-link">Home</a>
        </li>
        <li class="nav-item" (click)="onClickFooterMenu('Act')" routerLink="/transaction-new/lumpsum"
          routerLinkActive='active'>
          <img alt="N/A" *ngIf="!IsActNowActive" src="../../../assets/images/JM/Transaction_Inactive.svg" class="" />
          <img alt="N/A" *ngIf="IsActNowActive" src="../../../assets/images/JM/Transaction_Active.svg" class="" />
          <a [ngClass]="IsActNowActive ? 'ClrRed_n':''" class="nav-link">Transact</a>
        </li>
        <li class="nav-item" (click)="onClickFooterMenu('Add')" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Menu-Plus.svg" class=" AddImg" />
          <!-- <img alt="N/A" *ngIf="IsAddActive" src="../../../assets/images/JM/Menu-Close.svg" class=" AddImg" /> -->
          <!-- <a class="nav-link"></a> -->
        </li>
        <li class="nav-item" (click)="onClickFooterMenu('Loyalty')" routerLink="/explore-funds-new"
          routerLinkActive='active'>
          <img alt="N/A" *ngIf="!IsLoyaltyActive" src="../../../assets/images/JM/Loyalty_Inactive.svg" class="" />
          <img alt="N/A" *ngIf="IsLoyaltyActive" src="../../../assets/images/JM/Loyalty_Active.svg" class="" />
          <a [ngClass]="IsLoyaltyActive ? 'ClrRed_n':''" class="nav-link">Explore Funds</a>
        </li>
        <li class="nav-item" id="IdOthersMenu" (click)="onClickFooterMenu('Menu')" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Menu_Inactive.svg" class="" />
          <!-- <img alt="N/A" *ngIf="IsMenuActive" src="../../../assets/images/JM/Menu_Active.svg" class="" /> -->
          <a class="nav-link">Menu</a>
        </li>
      </ul>
    </nav>
    <div id="overlay" (click)="onclickOverlay()"></div>
    <ul *ngIf="IsMobile && UserType == '2'" id="OthersMenuUl" class="BoxShadow OthersMenuUl" style="display:none">
      <div class="MenuSection">
        <li class="nav-item" routerLink="/transaction-new/lumpsum" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Transact_new.svg" class="svg" width="24px" />
          <a class="nav-link">MF</a>
        </li>
        <li class="nav-item" routerLink="/fd/fd-landing" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/Sidebar/FD.svg" class="svg" width="24px" />
          <a class="nav-link">FD</a>
        </li>
        <li class="nav-item" routerLink="/SGB/SGBInvest" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/Sidebar/SGB.svg" class="svg" width="24px" />
          <a class="nav-link">SGB</a>
        </li>
        <li class="nav-item" routerLink="ipo/dashboardmain" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/Sidebar/NCD.svg" class="svg" width="24px" />
          <a class="nav-link">IPO/NCD</a>
        </li>
        <li class="nav-item" routerLink="/goal-based-investing/dashboard" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/Sidebar/calculator.svg" class="svg" width="24px" />
          <a class="nav-link">Calculators</a>
        </li>
        <li class="nav-item" routerLink="/value-add-service" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Goal Based Investing_new.svg" class="svg" width="24px" />
          <a class="nav-link">Co-Branding</a>
        </li>
        <li class="nav-item" routerLink="/sb-emp/Sb-EmpCreation" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/Sidebar/user_add.svg" class="svg" width="24px" />
          <a class="nav-link">Employee Creation</a>
        </li>
        <li class="nav-item" routerLink="/sb-emp/Sb-EmpMapping" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/Sidebar/user_tag.svg" class="svg" width="24px" />
          <a class="nav-link">Employee Mapping</a>
        </li>
      </div>
      <div class="MenuDiv d-flex justify-content-center align-items-center">
        <!-- <span class="MenuTitle">Menu</span> -->
        <span class="CloseMenu" (click)="CloseMenu()"><img src="../../../assets/images/Close-white.svg" alt=""
            width="10px"></span>
      </div>
    </ul>

    <ul *ngIf="IsMobile && UserType != '2'" id="OthersMenuUl" class="BoxShadow OthersMenuUl" style="display:none">
      <div class="MenuSection">
        <li class="nav-item" routerLink="/transaction-new/lumpsum" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Transact_new.svg" class="svg" width="24px" />
          <a class="nav-link">MF</a>
        </li>
        <li class="nav-item" routerLink="/fd/fd-landing" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/Sidebar/FD.svg" class="svg" width="24px" />
          <a class="nav-link">FD</a>
        </li>
        <li class="nav-item" routerLink="/SGB/SGBInvest" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/Sidebar/SGB.svg" class="svg" width="24px" />
          <a class="nav-link">SGB</a>
        </li>
        <li class="nav-item" routerLink="/ipo/index" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/Sidebar/NCD.svg" class="svg" width="24px" />
          <a class="nav-link">IPO/NCD</a>
        </li>
        <li class="nav-item" routerLink="/goal-based-investing/dashboard" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Goal Based Investing_new.svg" class="svg" width="24px" />
          <a class="nav-link">Calculators</a>
        </li>
      </div>
      <div class="MenuDiv d-flex justify-content-center align-items-center">
        <span class="CloseMenu" (click)="CloseMenu()"><img src="../../../assets/images/Close-white.svg" alt=""
            width="10px"></span>
      </div>
    </ul>

    <ul id="OthersMenuUl2" class="BoxShadow OthersMenuUl" style="display:none">
      <div class="MenuSection">
        <li class="nav-item" (click)="GoToProfile()">
          <img alt="N/A" src="../../../assets/images/Sidebar/Profile.svg" class="svg" width="24px" />
          <a class="nav-link">My Profile</a>
        </li>
        <li class="nav-item" routerLink="/reports-new/dashboard" routerLinkActive='active'>
          <img alt="N/A" src="../../../assets/images/JM/Reports.svg" class="svg" width="24px" />
          <a class="nav-link">Reports</a>
        </li>
        <li class="nav-item" routerLink="/dashboard" routerLinkActive='active' *ngIf="UserType == '1'">
          <img src="../../../assets/images/JM/Home_new.svg" class="svg" width="24px" />
          <a class="nav-link">Dashboard IFD </a>
        </li>
        <li class="nav-item" routerLink="/dashboard" routerLinkActive='active' *ngIf="UserType != '3'">
          <img src="../../../assets/images/JM/Home_new.svg" class="svg" width="24px" />
          <a class="nav-link">Dashboard Client</a>
        </li>
        <li class="nav-item" routerLink="/clients" routerLinkActive='active' *ngIf="UserType != '3'">
          <img src="../../../assets/images/Sidebar/Clients.svg" class="svg" width="24px" />
          <a class="nav-link">Clients</a>
        </li>
        <li class="nav-item" routerLink="/referral-report" routerLinkActive='active' *ngIf="UserType == '1'">
          <!--*ngIf="UserType == '1'"-->
          <img src="../../../assets/images/Sidebar/Subbroker.svg" class="svg" width="24px" />
          <a class="nav-link">Subbroker</a>
        </li>
        <li class="nav-item" routerLink="/campaign-management" routerLinkActive='active' *ngIf="UserType == '1'">
          <img src="../../../assets/images/Sidebar/Campaign_Management.svg" class="svg" width="24px" />
          <a class="nav-link">Campaign Management</a>
        </li>
        <li class="nav-item" routerLink="/document-directory/upload-documents" routerLinkActive='active'
          *ngIf="UserType === '3' || UserType === '2' ">
          <img alt="N/A" src="../../../assets/images/JM/Reports.svg" class="svg" width="24px" />
          <a class="nav-link">Documents</a>
        </li>
        <li class="nav-item" routerLink="/act-now" routerLinkActive='active' *ngIf="UserType === '2' ">
          <img alt="N/A" src="../../../assets/images/Sidebar/Reconciliation.svg" class="svg" width="24px" />
          <a class="nav-link">Act Now</a>
        </li>
        <li class="nav-item" routerLink="/franchise-onboarding" routerLinkActive='active' *ngIf="UserType === '2' ">
          <img alt="N/A" src="../../../assets/images/JM/Menu-Plus.svg" class="svg" width="24px" />
          <a class="nav-link">Franchise Onboarding</a>
        </li>
        <li class="nav-item" routerLink="/client-family" routerLinkActive='active' *ngIf="UserType === '3'">
          <img alt="N/A" src="../../../assets/images/JM/Reports.svg" class="svg" width="24px" />
          <a class="nav-link" *ngIf="UserType == '3'">My Family</a>
          <a class="nav-link" *ngIf="UserType != '3'">Client Family</a>
        </li>
        <li class="nav-item" (click)="LogOut()">
          <img alt="N/A" src="../../../assets/images/Icon_Logout.svg" class="svg" width="24px" />
          <a class="nav-link ClrRed_n">Logout</a>
        </li>

      </div>
      <div class="MenuDiv Posi d-flex justify-content-center align-items-center">
        <!-- <span class="MenuTitle">Menu</span> -->
        <span class="CloseMenu" (click)="CloseMenu()"><img src="../../../assets/images/Close-white.svg" alt=""
            width="10px"></span>
      </div>
    </ul>
    <div *ngIf="!IsMobile && UserType == '3'">
      <ul [class.collapsed]="IsCollapseSideMenu" class="Sidemenu">
        <li class="UserDetails">
          <a class="Custom_a" [ngClass]="IsCollapseSideMenu ? 'Li_a_Padding' : ''" (click)="GoToProfile()">
            <div *ngIf="IsCollapseSideMenu" class="CollapseUser"><img
                src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImgDefault" /></div>
            <div *ngIf="!IsCollapseSideMenu" class="d-flex justify-content-start align-items-center BorderBott">
              <img src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImg">
              <span class="UserName">{{UserName}}</span>
            </div>
          </a>
        </li>
        <li routerLink="/dashboard" routerLinkActive='active' class="">
          <a class="" [ngClass]="!IsCollapseSideMenu ? 'd-flex align-items-center':''">
            <img src="../../../assets/images/Sidebar/Home_new.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Home</span>
          </a>
        </li>
        <li class="" routerLink="/transaction-new/lumpsum" routerLinkActive='active'>
          <a class="" [ngClass]="!IsCollapseSideMenu ? 'd-flex align-items-center':''">
            <img src="../../../assets/images/Sidebar/Online_Transaction.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Online Transaction</span>
          </a>
        </li>
        <li class="" routerLink="/goal-based-investing/dashboard" routerLinkActive='active'>
          <a class="" [ngClass]="!IsCollapseSideMenu ? 'd-flex align-items-center':''">
            <img src="../../../assets/images/Sidebar/Goal_Based_Investing.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Goal Based Investing</span>
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#fd" data-toggle="collapse"
            data-target="#fd">
            <img src="../../../assets/images/Sidebar/FD.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">FD</span>
          </a>
          <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="fd" aria-expanded="false">
            <ul class="flex-column pl-5 nav">
              <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/fd/fd-landing"
                  routerLinkActive="active"><span>Online FD</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/fd-orderbook"
                  routerLinkActive="active"><span>Order Book</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/bajajfd-orderbook"
                  routerLinkActive="active"><span>Bajaj FD Order Book</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/shriram-fd-orderbook"
                  routerLinkActive="active"><span>Shriram FD Order Book</span></a></li>
            </ul>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#sgb" data-toggle="collapse"
            data-target="#sgb">
            <img src="../../../assets/images/Sidebar/SGB.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">SGB</span>
          </a>
          <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="sgb" aria-expanded="false">
            <ul class="flex-column pl-5 nav">
              <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/SGB/SGBInvest"
                  routerLinkActive="active"><span>Apply Now</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/SGB/SGBOrderBook"
                  routerLinkActive="active"><span>Order Book</span></a></li>
            </ul>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#ipo" data-toggle="collapse"
            data-target="#ipo">
            <img src="../../../assets/images/Sidebar/NCD.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">IPO/NCD</span>
          </a>
          <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="ipo" aria-expanded="false">
            <ul class="flex-column pl-5 nav">
              <li class="nav-item" *ngIf="UserType == '3'"><a class="nav-link DropMenu" routerLink="/ipo/AddClient"
                  routerLinkActive="active"><span>View Profile</span></a></li>
              <li class="nav-item" *ngIf="UserType == '3'"><a class="nav-link DropMenu" routerLink="/ipo/index"
                  routerLinkActive="active"><span>Apply/Track IPO</span></a></li>
              <li class="nav-item" *ngIf="UserType == '3'"><a class="nav-link DropMenu" routerLink="/ipo/index-ncd"
                  routerLinkActive="active"><span>Apply NCD </span></a></li>

            </ul>
          </div>
        </li>
        <li class="" routerLink="/explore-funds-new" routerLinkActive='active'>
          <a class="" [ngClass]="!IsCollapseSideMenu ? 'd-flex align-items-center':''">
            <img src="../../../assets/images/Sidebar/Explore_Funds.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Explore Funds</span>
          </a>
        </li>

        <li class="" routerLink="/reports-new/dashboard" routerLinkActive='active'>
          <a class="" [ngClass]="!IsCollapseSideMenu ? 'd-flex align-items-center':''">
            <img src="../../../assets/images/Sidebar/Reports.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Reports</span>
          </a>
        </li>
        <!-- <li class="" routerLink="/value-add-service" routerLinkActive='active'>
          <a class="d-flex align-items-center">
            <img src="../../../assets/images/Sidebar/Reports.svg" class="svg"/>
            <span *ngIf="!IsCollapseSideMenu" class="">Co-Branding</span>
          </a>
        </li> -->
        <li class="" routerLink="/document-directory/upload-documents" routerLinkActive='active'>
          <a class="" [ngClass]="!IsCollapseSideMenu ? 'd-flex align-items-center':''">
            <img src="../../../assets/images/Sidebar/Reports.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Documents</span>
          </a>
        </li>
        <li class="" routerLink="/client-family" routerLinkActive='active'>
          <a class="" [ngClass]="!IsCollapseSideMenu ? 'd-flex align-items-center':''">
            <img src="../../../assets/images/Sidebar/Reports.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu && UserType == '3'" class="">My Family</span>
            <!-- <span *ngIf="!IsCollapseSideMenu"  class="">Client-Family</span> -->
          </a>
        </li>
        <li class=" " (click)="LogOut()"><a class="d-flex align-items-center"><img
              src="../../../assets/images/Sidebar/Icon_Logout.svg" /><span *ngIf="!IsCollapseSideMenu"
              class="Left_Menu ClrRed_n">Logout</span></a></li>
        <!--PlacedBtm-->
      </ul>
    </div>


    <!-- For Subbroker and Employee : Dynamic menu binding : Added by Saddam -->
    <div *ngIf="!IsMobile && UserType != '3'">
      <div id="header" [ngClass]="IsShow">
        <ul [class.collapsed]="IsCollapseSideMenu" class="Sidemenu">
          <li class="UserDetails">
            <a class="Custom_a" [ngClass]="IsCollapseSideMenu ? 'Li_a_Padding' : ''" (click)="GoToProfile()">
              <div *ngIf="IsCollapseSideMenu" class="CollapseUser"><img
                  src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImgDefault" /></div>
              <div *ngIf="!IsCollapseSideMenu" class="d-flex justify-content-start align-items-center BorderBott">
                <img src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImg">
                <span class="UserName">{{UserName}}</span>
              </div>
            </a>
          </li>
          <li *ngFor="let item of MenuList; let i = index" class="nav-item" routerLinkActive='active'>
            <div *ngIf="item.MainMenuURL != ''">
              <a class="" [routerLink]="item.MainMenuURL"
                [ngClass]="!IsCollapseSideMenu ? 'd-flex align-items-center':''">
                <img src="{{GetImgSource(item.MainMenuName)}}" class="svg" />
                <span *ngIf="!IsCollapseSideMenu" class="d-flex align-items-center">{{item.MainMenuName}}</span>
              </a>
            </div>
            <div *ngIf="item.MainMenuURL == ''">
              <a class="nav-link nav-link_Arrow collapsed text-truncate" href='#{{ReplaceChar(item.MainMenuName)}}'
                data-toggle="collapse" data-target="#{{ReplaceChar(item.MainMenuName)}}">
                <img src="{{GetImgSource(item.MainMenuName)}}" />
                <span class="MainMenu" *ngIf="!IsCollapseSideMenu">{{item.MainMenuName}}</span>
              </a>
              <div class="collapse Trans" id="{{ReplaceChar(item.MainMenuName)}}" aria-expanded="false"
                *ngIf="!IsCollapseSideMenu">
                <ul class="flex-column pl-5 nav">
                  <li class="nav-item" *ngFor="let submenu of item.data">
                    <a class="nav-link DropMenu MainSubMenu" [routerLink]="submenu.SubMenuURL"
                      routerLinkActive='active'>{{submenu.SubMenuName}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class=" " (click)="LogOut()"><a class="d-flex align-items-center"><img
                src="../../../assets/images/Sidebar/Icon_Logout.svg" /><span *ngIf="!IsCollapseSideMenu"
                class="Left_Menu ClrRed_n">Logout</span></a></li>
        </ul>
      </div>
    </div>


    <div *ngIf="IsMobile" class="UserDetails_Mob">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <img src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImg">
          <div style="line-height: 18px;">
            <div class="Hello">Hello</div>
            <div class="UserName_Mob">{{UserName}}</div>
          </div>
        </div>
        <div>
          <!-- <img src="../../../assets/images/JM/IFD_Logo.png" alt="Logo" height="38px;" class="logo"> -->
          <a *ngIf="IsMobile && UserType != '1'" class="navbar-brand p-0 mr-0 ml-2">
            <ng-container *ngIf="SBLogoBase64 == ''">
              <img src="../../../assets/images/JM/IFD_Logo.png" alt="Logo" height="38px;" class="logo">
            </ng-container>
            <ng-container *ngIf="SBLogoBase64 != ''">
              <img [src]="SBLogoBase64" alt="Logo" height="38px;" class="logo">
            </ng-container>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="QRCode" appDisabledRightClick>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">QR Code</div>
      <div class="modal-body AlertModalBody">

        <div><img class="SuccessPage" class="SuccessPage" [src]="QRCodeImage"></div>
        <div class="">{{QRCodeURL}} </div>

      </div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" class="CursorPointer" (click)="CopyURL()">
          <img src="../../../assets/images/copy.svg" style="width: 25px;height: 25px;" />
          <span>{{CopyTest}}</span>

        </button>
        <button type="button" (click)="DownloadQRCode()" class="CursorPointer">Download QR Code</button>
        <button type="button" class="CursorPointer" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>