import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VariableService {

  RmName:string = '';
  RmContact:string ='';
  ClientName:string ='';
  PanNo:string ='';
  MobileNo;
  EmailId;
  constructor() { }
}
