import { ConvertToJSONService } from './convert-to-json.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EncrdecrService } from './../Auth/encrdecr.service';
const headers = { headers: new HttpHeaders({ 'content-type': 'application/json' }) }

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  ServerUrl = environment.ServerUrl;
  CompanyName = environment.CompayName;
  // Don't remove this lines from here
  RMContact: string = "";
  RMName: string = '';
  //
  constructor(private http: HttpClient,
    private EncrdecrService: EncrdecrService,
    private ConvertToJSON: ConvertToJSONService) {

  }

  GetAccountInfo(id: string, UserType: string): Observable<any> {
    debugger
    let DataUrl = ""
    let QueryString = '';
   // id = this.EncrdecrService.setEncr(id);
    if (UserType == "2") {
      DataUrl = `${this.ServerUrl}/api/SignUp/GetAccountInfo`;
      QueryString = `SubBrokerId=${id}&ClientBasicInfoId=&UserType=2`;
    }
    else if (UserType == "1") {
      DataUrl = `${this.ServerUrl}/api/SignUp/GetAccountInfo`;
      QueryString = `SubBrokerId=${id}&ClientBasicInfoId=&UserType=1`;
    }
    else {
      DataUrl = `${this.ServerUrl}/api/SignUp/GetAccountInfo`;
      QueryString = `SubBrokerId=&ClientBasicInfoId=${id}&UserType=3`;
    }

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("DataURl :" + DataUrl);
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log("Body ->  " + JSON.stringify(body));
    console.log("Token -> " + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    return this.http.post<any>(DataUrl, body, tokenOption).pipe();
  }

  GetEUINInfo(id: string): Observable<any> {
    let DataUrl = ""
    let QueryString = '';
    id = this.EncrdecrService.setEncr(id);
    DataUrl = `${this.ServerUrl}/api/SignUp/GetEUINInfo`;
    QueryString = `SubBrokerId=${id}`;

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    // console.log("DataURl :"+DataUrl) ;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(DataUrl, body, tokenOption).pipe();
  }

  GetClientInfo(ClientBasicInfoId): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/ClientOnBoardingBSE/ClientProfileDetails`;
    const QueryString = `ClientBasicInfoId=${this.EncrdecrService.setEncr(ClientBasicInfoId)}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);

    return this.http.post<any>(DataUrl, body, this.GetToken()).pipe();
  }
  GetClientDocumentDownload(ClientBasicInfoId): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/ClientOnBoardingBSE/ClientDocumentDownload`;
    const QueryString = `ClientBasicInfoId=${this.EncrdecrService.setEncr(ClientBasicInfoId)}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(DataUrl, body, this.GetToken()).pipe();
  }



  CheckPANExistOrNot(data: any) {
    const header = this.GetToken();
    let url = `${this.ServerUrl}/api/ClientOnBoardingJM/CheckPanExistOrNotOnBoarding`;
    return this.http.post(url, data, header).pipe();
  }

  CheckMobileOrEmailExistOrNot(data: any) {
    const header = this.GetToken();
    let url = `${this.ServerUrl}/api/ClientOnBoardingJM/CheckMobileOrEmailExistOrNot`;
    return this.http.post(url, data, header).pipe();
  }

  PostAccountInfo(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/SignUp/SignUp`;
    return this.http.post<any>(DataUrl, formData, headers);
  }

  PostRTAMailBack(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/SignUp/RTAInfo`;
    return this.http.post<any>(DataUrl, formData, headers);
  }

  PostBSEstarInfo(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/SignUp/BSECredentials`;
    return this.http.post<any>(DataUrl, formData, headers);
  }

  PostLogo(selectedFile: File, ARNNO, Domain_Name): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('Image', selectedFile, selectedFile.name);
    let DataUrl = `${this.ServerUrl}/api/SignUp/CompanyLogo?SubBrokerId=${ARNNO}&Domain_Name=${Domain_Name}`;
    return this.http.post<any>(DataUrl, formData);
  }

  PostEmailSettings(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/SignUp/EmailSettings`;
    return this.http.post<any>(DataUrl, formData, headers);
  }

  PostBasicDetails(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/ClientOnBoardingBSE/Details`;

    //console.log(DataUrl)
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log('Fatca Save url ' + DataUrl);
    console.log('Fatca Save body ' + formData);
    console.log('Fatca Save token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const request = { data: this.EncrdecrService.setEncr(formData) }
    return this.http.post<any>(DataUrl, request, tokenOption);
  }
  PostPreBasicDetails(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}api/CheckDetails/Details`;
    let request = { data: this.EncrdecrService.setEncr(formData) }
    return this.http.post<any>(DataUrl, request, headers);
  }
  PostDocuments(formData): Observable<any> {

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {//'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    let DataUrl = `${this.ServerUrl}/api/ClientOnBoardingBSE/UploadDocument`;
    return this.http.post<any>(DataUrl, formData, tokenOption);
  }
  GetStateList(): Observable<any> {

    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingDetails?Flag=State`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');

    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log('GetStateList URL ' + DataUrl);
    console.log('GetStateList URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    return this.http.get<any>(DataUrl, tokenOption).pipe();
  }

  GetRelationList(): Observable<any> {
    debugger
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // const tokenOption = {
    //   headers:
    //     new HttpHeaders(
    //       {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Basic ${TOKEN}`,
    //       })
    // }
    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingDetails?Flag=RELATION_LIST`;
    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log('GetRelationList URL ' + DataUrl);
    console.log('GetRelationList URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // return this.http.post<any>(body, tokenOption).pipe();
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetBankList(): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingListDetails?TRANS_TYPE=Bank_list&REFF_ID2=BankCode`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log('GetBankList URL ' + DataUrl);
    console.log('GetBankList URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    return this.http.get<any>(DataUrl).pipe();
  }

  GetBankListBSE(): Observable<any> {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // const tokenOption = {
    //   headers:
    //     new HttpHeaders(
    //       {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Basic ${TOKEN}`,
    //       })
    // }
    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingDetails?Flag=BankList`;
    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log('GetBankListBSE URL ' + DataUrl);
    console.log('GetBankListBSE URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // return this.http.get<any>(DataUrl, tokenOption).pipe();
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  GetAccountTypeList(): Observable<any> {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // const tokenOption = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Basic ${TOKEN}`, })
    // }
    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingDetails?Flag=AccountType`;
    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log('GetAccountTypeList URL ' + DataUrl);
    console.log('GetAccountTypeList URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }


  GetTaxStatus() {
    let DataUrl = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=TAX_STATUS`;
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body).pipe();
  }


  IdentificationList() {
    let DataUrl = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=IDTYPE_LIST_NSE`;
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body).pipe();
  }


  GetIdentificationTypeList() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // const tokenOption = {
    //   headers:
    //     new HttpHeaders(
    //       {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Basic ${TOKEN}`,
    //       })
    // }
    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingDetails?Flag=IdentificationType`;
    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log('GetIdentificationTypeList URL ' + DataUrl);
    console.log('GetIdentificationTypeList URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetAddressTypeList() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Basic ${TOKEN}`, })
    }
    let DataUrl = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=ADDRESSTYPE_LIST_NSE`;
    console.log('GetAddressTypeList URL ' + DataUrl);
    console.log('GetAddressTypeList URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    //return this.http.get<any>(DataUrl,tokenOption).pipe();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetSourceOfWealth() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Basic ${TOKEN}`, })
    }
    let DataUrl = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=INCOME_SOURCE`;
    console.log('GetAddressTypeList URL ' + DataUrl);
    console.log('GetAddressTypeList URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    //return this.http.get<any>(DataUrl,tokenOption).pipe();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  GetOccupationTypeList() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // const tokenOption = {
    //   headers:
    //     new HttpHeaders(
    //       {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Basic ${TOKEN}`,
    //       })
    // }
    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingDetails?Flag=OccupationType`;
    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log('GetOccupationTypeList URL ' + DataUrl);
    console.log('GetOccupationTypeList URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    return this.http.post<any>(url, body, tokenOption).pipe();
    // return this.http.get<any>(DataUrl, tokenOption).pipe();
  }

  GetAnnualIncomeList() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // const tokenOption = {
    //   headers:
    //     new HttpHeaders(
    //       {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Basic ${TOKEN}`,
    //       })
    // }
    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingDetails?Flag=AnnualIncome`;
    console.log('GetAnnualIncomeList URL ' + DataUrl);
    console.log('GetAnnualIncomeList URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    // return this.http.get<any>(DataUrl, tokenOption).pipe();
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetGenerateUCC(ClientBasicInfoId) {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    let DataUrl = `${this.ServerUrl}/api/UCCCreation/Details`;
    const QueryString = `FisrtHolderId=${ClientBasicInfoId}&Flag=SI&CompanyName=${this.CompanyName}`;
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    //console.log(DataUrl)
    return this.http.post<any>(DataUrl, body, tokenOption).pipe();
  }

  PostAOFForm(formData): Observable<any> {
    ////////
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);

    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    let DataUrl = `${this.ServerUrl}/api/UCCCreation/AOFUpload`;
    return this.http.post<any>(DataUrl, formData, tokenOption);
  }

  GetDownloadAOF(UCCCode) {
    let DataUrl = `${this.ServerUrl}/api/UCCCreation/GenerateAOF?UCCCode=${UCCCode}`;
    //console.log(DataUrl)
    return this.http.get<any>(DataUrl).pipe();
  }
  GetDownloadNACH(UCCCode, NachMandateId) {
    let DataUrl = `${this.ServerUrl}/api/NachMandate/DownloadNach?UCCCode=${UCCCode}&MandateId=${NachMandateId}&TRANS_TYPE=Bank_inFormation`;
    //console.log(DataUrl)
    return this.http.get<any>(DataUrl).pipe();
  }
  postFDDetails(data) {
    const url = `${this.ServerUrl}/api/FD/UploadDocument`;
    const header = this.GetTokenNonJSON();
    return this.http.post<any>(url, data, header);
  }
  getFDDetails(ClientBasicInfoId) {
    const url = `${this.ServerUrl}/api/FD/getFDDetails?ClientBasicInfoId=${this.EncrdecrService.setEncr(ClientBasicInfoId)}`;
    console.log("FD URL " + url);
    const header = this.GetToken();
    return this.http.get<any>(url, header);
  }
  enableClientFD(ClientBasicInfoId) {
    const url = `${this.ServerUrl}/api/FD/EnableFd`;
    const Data = { "ClientbasicinfoID": ClientBasicInfoId };
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    let req_str = JSON.stringify(Data);
    const body = { data: this.EncrdecrService.setEncr(req_str) };
    return this.http.post<any>(url, body, tokenOption);
  }
  VerifyPanEmailMobile(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/CheckDetails/Details`;
    let request = { data: this.EncrdecrService.setEncr(JSON.stringify(formData)) }
    return this.http.post<any>(DataUrl, request, headers);
  }

  SendOTP(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/SMSService/SendOTP`;
    //console.log(DataUrl)

    const req = {
      data: this.EncrdecrService.setEncr(formData)
    }
    return this.http.post<any>(DataUrl, req, headers);
  }

  SendJMOTP(formData, baseURL): Observable<any> {
    let DataUrl = environment.ServerUrl + baseURL;
    //console.log(DataUrl)

    const req = {
      data: this.EncrdecrService.setEncr(formData)
    }
    return this.http.post<any>(DataUrl, req, headers);
  }

  GetMandateDetails(Data: any) {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    let DataUrl = `${this.ServerUrl}/api/ClientMandate/Details`;
    //let DataUrl = `localhost:3637/BSETransaction/api/ClientMandate/Details`;
    // const QueryString = `FisrtHolderId=${ClientBasicInfoId}&Flag=SI&CompanyName=${this.CompanyName}`;
    // const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    // //console.log(DataUrl)
    const request = { data: this.EncrdecrService.setEncr(Data) }
    return this.http.post<any>(DataUrl, request, tokenOption).pipe();
    //console.log(DataUrl)
    // return this.http.post<any>(DataUrl, Data, headers);
  }

  checkPANKYC(data: any) {

    let Value = JSON.parse(data);
    let url = environment.ServerUrl + `api/KYCCheck/GetCVLKRA?PANNo=${Value.PANNo}&DOB=${Value.DOB}&MobileNo=${Value.MobileNo}`;
    const QueryString = url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    const req = JSON.stringify(body);
    return this.http.get<any>(url).pipe();
  }

  CheckKYC(PANNO, MobileNo) {
    const header = this.GetToken();
    //let DataUrl = `${this.ServerUrl}/api/KycCheck/KycCheck?PANNo=${PANNO}&MobileNo=${MobileNo}`;
    let DataUrl = `${this.ServerUrl}/api/KYCCheck/CVLKRAKYC?PANNo=${PANNO}&MobileNo=${MobileNo}`;
    console.log(DataUrl)
    return this.http.get<any>(DataUrl, header);
  }
  GetAddressByPin(PinCode) {
    let DataUrl = `${this.ServerUrl}/api/FillBank_details/GetDetail?Flag=&Code=${PinCode}`
    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  GetBankByIFSC(IFSCCode) {
    let DataUrl = `${this.ServerUrl}/api/FillBank_details/GetDetail?Flag=IFSC&Code=${IFSCCode}`
    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();  // return this.http.post<any>(DataUrl.split('?')[0], json, headers).pipe();

  }
  GetClientInfobypanno(PANNO, UserType) {
    let DataUrl = `${this.ServerUrl}/api/ForgotPassword/ReportDetails`;
    const QueryString = `PANNo=${PANNO}&TRANS_TYPE=CLIENTDETAILS_BYPAN&USER_TYPE=${UserType}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    console.log(DataUrl);
    console.log(JSON.stringify(body));

    return this.http.post<any>(DataUrl, body, headers);
  }
  GetToken() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let UserType = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + UserType + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return tokenOption;
  }
  KycCheckCVL(PANNO, MobileNo, DOB) {
    const header = this.GetToken();
    let DataUrl = `${this.ServerUrl}/api/KYCCheck/KycCheckCVL?PANNo=${PANNO}&MobileNo=${MobileNo}&DOB=${DOB}`;
    const body = this.ConvertToJSON.QueryStringToJSON(DataUrl.split('?')[1]);
    return this.http.get<any>(DataUrl, header);
  }
  LoadKYC(formData) {
    let DataUrl = `${this.ServerUrl}/api/KYCCheck/LoadKYCKotak`;
    return this.http.post<any>(DataUrl, formData, this.GetToken());
  }
  CheckExistingPAN(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/ClientOnBoardingBSE/Details`;
    let request = { data: this.EncrdecrService.setEncr(formData) }
    return this.http.post<any>(DataUrl, request, this.GetToken());
  }
  GetTokenNonJSON() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers: new HttpHeaders({
        'Authorization': `Basic ${TOKEN}`,
      })
    }
    return tokenOption;
  }
  SendCommonOTP(body): Observable<any> {
    const url = `${this.ServerUrl}api/CommonOTP/SendOTPSMS`;
    //const token = this.GetToken();
    console.log(url)
    return this.http.post<any>(url, body, headers)
  }
  PostNSEInfo(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/SignUp/NSECredentials`;
    return this.http.post<any>(DataUrl, formData, headers);
  }
  sendMandatectivationlink(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/ClientMandate/SendMandateActivationLink`;
    const token = this.GetToken();
    return this.http.post<any>(DataUrl, formData, token);
  }
  PostEnableDirectScheme(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/EnableDirectScheme/EnableDirectSchemes`;
    const token = this.GetToken();
    return this.http.post<any>(DataUrl, formData, token);
  }
  GetDirectSchemesStatus(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/EnableDirectScheme/Getschemestatus`;
    const token = this.GetToken();
    let request = {data : this.EncrdecrService.setEncr(formData)}
    return this.http.post<any>(DataUrl, request, token);
  }
  CheckAUKYC(PANNO, MobileNo) {
    const header = this.GetToken();
    //let DataUrl = `${this.ServerUrl}/api/KycCheck/KycCheck?PANNo=${PANNO}&MobileNo=${MobileNo}`;
    let DataUrl = `${this.ServerUrl}/api/KYCCheck/AUCVLKRAKYC?PANNo=${PANNO}&MobileNo=${MobileNo}`;
    console.log(DataUrl)
    const json = this.ConvertToJSON.QueryStringToJSON(DataUrl.split('?')[1]);

    return this.http.post<any>(DataUrl.split('?')[0], json, header);
  }

  //fetch NPSClientDetails data using clientBasicInfoId
  GetNPSClientDetails(ClientBasicInfoId: number) {

    let s_url = `${this.ServerUrl}/api/Customer360Service/NPSClientDetails?ClientBasicInfoId=${ClientBasicInfoId}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetNPSDropDownData(code: any, filtername: any): Observable<any> {

    let s_url = "";
    if (filtername == 'state') {
      s_url = `${this.ServerUrl}/api/DropDown/NPSDetails?TRANS_TYPE=StateList&REFF_ID=${code}`
    }
    else if (filtername == 'gender') {
      s_url = `${this.ServerUrl}/api/DropDown/NPSDetails?TRANS_TYPE=GenderList&REFF_ID=${code}`
    }
    else if (filtername == 'title') {
      s_url = `${this.ServerUrl}/api/DropDown/NPSDetails?TRANS_TYPE=Title&REFF_ID=${code}`
    }
    else if (filtername == 'marital') {
      s_url = `${this.ServerUrl}/api/DropDown/NPSDetails?TRANS_TYPE=MaritalStatusList&REFF_ID=${code}`
    }
    else if (filtername == 'accounttype') {
      s_url = `${this.ServerUrl}/api/DropDown/NPSDetails?TRANS_TYPE=Account_TypeList&REFF_ID=${code}`
    }
    else if (filtername == 'occupationtype') {
      s_url = `${this.ServerUrl}/api/DropDown/NPSDetails?TRANS_TYPE=OccupationList&REFF_ID=${code}`
    }
    else if (filtername == 'PoliticallyExposedFlag') {
      s_url = `${this.ServerUrl}/api/DropDown/NPSDetails?TRANS_TYPE=PoliticallyExposedFlag&REFF_ID=${code}`
    }
    else if (filtername == 'IncomeRangeList') {
      s_url = `${this.ServerUrl}/api/DropDown/NPSDetails?TRANS_TYPE=IncomeRangeList&REFF_ID=${code}`
    }
    else if (filtername == 'Relationship') {
      s_url = `${this.ServerUrl}/api/DropDown/NPSDetails?TRANS_TYPE=Relationship&REFF_ID=${code}`
    }
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  GetTitleList(): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingDetails?Flag=ClientTitle`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // const tokenOption = {
    //   headers:
    //     new HttpHeaders(
    //       {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Basic ${TOKEN}`,
    //       })
    // }
    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log('GetStateList URL ' + DataUrl);
    console.log('GetStateList URL token ' + token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // return this.http.get<any>(DataUrl, tokenOption).pipe();
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  SaveNPSProfile(body: any) {
    let url = `${this.ServerUrl}/api/NPSClientOnbaoading/NPSClientOnbaoading`
    const tokenOption = this.GetToken();
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  PostNPSDocument(formData): Observable<any> {

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {//'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    let DataUrl = `${this.ServerUrl}/api/NPSClientOnbaoading/UploadDocument`;
    return this.http.post<any>(DataUrl, formData, tokenOption);
  }
  getKYCVerificationDetails(PANNO) {
    const header = this.GetToken();
    let DataUrl = `${this.ServerUrl}/api/KYCCheck/getKYCVerificationDetails?PANNo=${PANNO}`;
    console.log(DataUrl)
    const json = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(DataUrl.split('?')[1]);
    return this.http.post<any>(DataUrl.split('?')[0], json, header);
  }
  PostMailConfigInfo(formData): Observable<any> {
    let DataUrl = `${this.ServerUrl}/api/EmailConfigrationController/EmailConfigCont`;
    return this.http.post<any>(DataUrl, formData, headers);
  }
  GetRelationwithholderlist() {
    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingDetails?Flag=MoborEmailRelation`;
    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    // return this.http.get<any>(DataUrl, this.GetToken()).pipe();
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  GetRelationwithholderlist_self(flag: string, InputPara: string, cbid: string) {
    let DataUrl = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=${flag}&REFF_ID=${InputPara}&REFF_ID2=${cbid}`;
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, this.GetToken()).pipe();
  }
  GetCountryList(): Observable<any> {

    let DataUrl = `${this.ServerUrl}/api/DropDown/OnboardingDetails?Flag=TransactionCountry`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');

    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    // const tokenOption = {
    //   headers:
    //     new HttpHeaders(
    //       {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Basic ${TOKEN}`,
    //       })
    // }

    const tokenOption = this.GetToken();
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    // return this.http.get<any>(DataUrl, tokenOption).pipe();
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  UpdateNomineeDeclaration(formData): Observable<any> {

    let DataUrl = `${this.ServerUrl}api/ClientOnBoardingBSE/UpdateNomineeDeclaration`;
    //console.log(DataUrl)
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    let request = { data: this.EncrdecrService.setEncr(formData) }
    return this.http.post<any>(DataUrl, request, tokenOption);
  }
  GetNomineeStatus(formData): Observable<any> {

    let DataUrl = `${this.ServerUrl}api/ClientOnBoardingBSE/GetNomineeStatus`;
    //console.log(DataUrl)
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    let request = {data : this.EncrdecrService.setEncr(formData)}
    return this.http.post<any>(DataUrl, request, tokenOption);
  }
  GetRiskProfileDetails(trans, ClienId): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr(trans);
    let REFF_ID = this.EncrdecrService.setEncr(ClienId);
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}&REFF_ID=${REFF_ID}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    console.log("Risk Profile URL is " + url);
    console.log("Risk Profile body is " + JSON.stringify(body));
    return this.http.post<any>(url, body, token);
  }

  //Insert Data High Risk
  UpdatehighRPConfirmation(formData): Observable<any> {
    let Dataurl = `${this.ServerUrl}/api/RiskProfile/UpdateHighRpConfirmation`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          }
        )
    }
    return this.http.post<any>(Dataurl, formData, tokenOption);
  }
  SendGenUCCreqToClient(ClientBasicInfoId) {
    ////////
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    let DataUrl = `${this.ServerUrl}/api/UCCCreation/SendUCCReqToClient`;
    const QueryString = `FisrtHolderId=${ClientBasicInfoId}&Flag=SI&CompanyName=${this.CompanyName}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    //console.log(DataUrl)
    return this.http.post<any>(DataUrl, body, tokenOption).pipe();
  }
  GetEMandateStatus(ClientBasicInfoId) {

    let DataUrl = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=EMandateBankAllowStatus_JM&REFF_ID=${ClientBasicInfoId}`;
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, this.GetToken()).pipe();

  }

  GetIINAndUccStatus(ClientBasicInfoId) {

    let DataUrl = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=IINAndUccStatus_JM&REFF_ID=${ClientBasicInfoId}`;
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, this.GetToken()).pipe();

  }
  GetEMandateDetails(Data: any) {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    let DataUrl = `${this.ServerUrl}/api/BSEEMandate/GetBSEEMandateURL`;

    return this.http.post<any>(DataUrl, Data, tokenOption).pipe();

  }
  GetDetailByPan(Pan: string) {

    let DataUrl = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=GetInfoByPAN&REFF_ID=${Pan}`;
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, this.GetToken()).pipe();

  }
  GenerateSecondHolderFatcaBSE(FirstHolderId: string, SecondHolderId: string) {
    let DataUrl = `${this.ServerUrl}/api/UCCCreation/UploadFatca`;
    const QueryString = `FisrtHolderId=${FirstHolderId}&SecondHolderId=${SecondHolderId}&Flag=SI`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    console.log("Upload FATCA BSE Request :-" + QueryString)
    return this.http.post<any>(DataUrl, body, this.GetToken()).pipe();
  }
  GenerateSecondHolderFatcaNSE(SecondHolderId, HoldingType: string, PANNo: string) {
    let DataUrl = `${this.ServerUrl}/api/IINDetails/UploadFatca`;
    const QueryString = `FisrtHolderId=${SecondHolderId}&HoldingType=${HoldingType}&PANNO=${PANNo}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    console.log("Upload fatca NSE Request :-> " + QueryString)
    return this.http.post<any>(DataUrl, body, this.GetToken()).pipe();
  }
  GenerateSecondHolderUcc(FirstHolderId: string, SecondHolderId, ModeOfHolding) {
    let DataUrl = `${this.ServerUrl}/api/UCCCreation/Details`;
    const QueryString = `FisrtHolderId=${FirstHolderId}&Flag=${ModeOfHolding}&SecondHolderId=${SecondHolderId}`;
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log("Generate UCC Request : " + QueryString)
    return this.http.post<any>(DataUrl, body, this.GetToken()).pipe();
  }
  GenerateSecondHolderIIN(FirstHolderId: string, SecondHolderId, HoldingType, Flag, PANNO, TaxStatus) {
    let DataUrl = `${this.ServerUrl}/api/IINDetails/GenerateIIN`;//api/IINDetails/GenerateIIN
    const QueryString = `FisrtHolderId=${FirstHolderId}&Flag=${Flag}&SecondHolderId=${SecondHolderId}&HoldingType=${HoldingType}&PANNO=${PANNO}&TaxStatus=${TaxStatus}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    console.log("Request For generate IIN :->" + QueryString)
    return this.http.post<any>(DataUrl, body, this.GetToken()).pipe();
  }

  CheckCVLKRA(Request: any) {
    let DataUrl = `${this.ServerUrl}/api/CVLKRAData/CVLKRASTATUS`;
    return this.http.post<any>(DataUrl, Request, this.GetToken()).pipe();
  }
  GetUccStatus(FirstHolderCBId, SecondHolderCBId, ModeOfHolding) {
    let DataUrl = `${this.ServerUrl}/api/DropDown/Details?TRANS_TYPE=SecondHolderUcc&REFF_ID=${FirstHolderCBId}&REFF_ID2=${SecondHolderCBId}&REFF_ID3=${ModeOfHolding}`;
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body).pipe();
  }
  GetSecondHolderUCCDetails(Request: any) {
    let DataUrl = `${this.ServerUrl}/api/UCCCreation/GetSecondHolderUccDetails`;
    return this.http.post<any>(DataUrl, Request, this.GetToken()).pipe();
  }

  BANKMANDATEREGISTRATION(ClientBasicInfoId: any, invbankid: any) {
    let DataUrl = `${this.ServerUrl}/api/TransactionAndUtility/BANKMANDATEREGISTRATION?ClientBasicInfoId=${ClientBasicInfoId}&ClientBankDetailId=${invbankid}`;
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(DataUrl, body).pipe();
  }
  SETDEFAULTBANK(ClientBasicInfoId: any, invbankid: any) {
    let DataUrl = `${this.ServerUrl}/api/TransactionAndUtility/SETDEFAULTBANK?ClientBasicInfoId=${ClientBasicInfoId}&ClientBankDetailId=${invbankid}`;
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(DataUrl, body).pipe();
  }
  GetClientAssistanceMapping(Request: any) {
    let DataUrl = `${this.ServerUrl}/api/ClientAssistanceMapping/Details`;
    let request = {data : this.EncrdecrService.setEncr(JSON.stringify(Request))}
    return this.http.post<any>(DataUrl, request, this.GetToken()).pipe();
  }
  GiveLoginAccessToClient(ClientId,AccessFlag) {

    let DataUrl = `${this.ServerUrl}/api/ClientOnBoardingBSE/GiveLoginAccess?TRANS_TYPE=GiveLoginAccess&REFF_ID=${ClientId}&REFF_ID2=${AccessFlag}`;
    const url = DataUrl.split('?')[0];
    const QueryString = DataUrl.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, this.GetToken()).pipe();

  }
  GetClientDetailsPDF(data: any) {
    let Url = `${this.ServerUrl}/api/ClientInfo/Details`;
    return this.http.post<any>(Url, data, this.GetToken()).pipe();
  }
}
