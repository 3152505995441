import { EncrdecrService } from './encrdecr.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleAccessGuard implements  CanActivate{
  constructor(  private authService: AuthService,
    private EncrdecrService:EncrdecrService,
    private router: Router,
    ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    var UserType =this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    if(UserType=="1" || UserType=="2"){
      return true;
    }
    else{
      this.router.navigateByUrl('/login-corp');
    }

  }
}
