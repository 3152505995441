 <div class="modal-dialog modal-dialog-centered m-0">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader" id="modal-basic-title">Important Message</div>
      <div id="" class="modal-body AlertModalBody">
        <p> Your existing Risk Profile is {{RiskProfile}} <br> Last updated on {{LastRPUpdateDate}} </p>
      </div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" data-dismiss="modal" class="CursorPointer CommonSecondaryButton" (click)="HideRiskModalPopup()" *ngIf="this.RiskProfileStatus!='Expired'">Continue</button>
        <button type="button" data-dismiss="modal" class="CursorPointer" (click)="CallClientProfile()">Reassess</button>
      </div>
    </div>
  </div>

<!--
<div class="container mdlCls">
  <div class="modal-header p-1">
    <h4 class="modal-title Header" id="modal-basic-title">Important Message</h4>
  </div>
  <div class="main-div">
    <div id="">
      <div>
        <p> Your existing Risk Profile is {{RiskProfile}} <br> Last updated on {{LastRPUpdateDate}} </p>
      </div>
    <div class="modal-footer AlertModalFooter">
      <button type="button" data-dismiss="modal" class="CursorPointer CommonSecondaryButton" (click)="HideRiskModalPopup()" *ngIf="this.RiskProfileStatus!='Expired'">Continue</button>
      <button type="button" data-dismiss="modal" class="CursorPointer" (click)="CallClientProfile()">Reassess</button>
    </div>
    </div>
  </div>
</div> -->

<div class="modal mdlCls" id="AlertModalprofilemodels" appDisabledRightClick data-backdrop='static'
  data-keyboard='false'>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">Important Message</div>
      <div id="AlertModalprofilemodelsContentmodels" class="modal-body AlertModalBody"></div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" data-dismiss="modal" class="CursorPointer">Okay</button>
      </div>
    </div>
  </div>
</div>
