import { Component } from '@angular/core';

@Component({
  selector: 'app-thank-you-fd',
  templateUrl: './thank-you-fd.component.html',
  styleUrls: ['./thank-you-fd.component.scss']
})
export class ThankYouFDComponent {

}
