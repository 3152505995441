import { Component, EventEmitter, HostListener, Input, OnInit, Output, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MainHeaderMenuService } from '../../services/main-header-menu.service';
import { EncrdecrService } from '../../Auth/encrdecr.service';
import { LoginService } from '../../services/login.service';
import { TokenExpireService } from 'src/app/services/token-expire.service';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { TransactionService } from '../../services/transaction.service';
import { CustomLoaderService } from 'src/app/services/custom-loader.service';
import * as copy from 'copy-to-clipboard';
import { NotifierService } from "angular-notifier";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { IsValidArray } from 'src/app/validation';

declare var $: any;

declare var require: any;
const FileSaver = require("file-saver");
@Component({
  selector: 'app-header-jm',
  templateUrl: './header-jm.component.html',
  styleUrls: ['./header-jm.component.scss'],
  animations: [
    trigger("openClose", [
      // ...
      state(
        "open",
        style({
          // height: 'auto',
          opacity: 1,
          display: "block",
        })
      ),
      state(
        "closed",
        style({
          // height: '0px',
          opacity: 0,
          display: "none",
        })
      ),
      transition("open => closed", [animate("0s")]),
      transition("closed => open", [animate("0.3s")]),
    ]),
  ],
  host: {
    "(document:click)": "onClickOutSide($event)",
  },
})
export class HeaderJmComponent implements OnInit {
  isOverlayOpen = false;
  isDropdownOpen = false;
  LoginToken: string = '';
  IsCollapseSideMenu: boolean = false;
  isLoggedIn$: Observable<boolean>;
  @Output() isCollapseMenu: EventEmitter<any> = new EventEmitter();
  IsMobile: boolean = false;
  UserName: string = '';
  UserType: string = '3';
  IsHomeActive: boolean = true;
  IsActNowActive: boolean = false;
  IsAddActive: boolean = false;
  IsLoyaltyActive: boolean = false;
  IsMenuActive: boolean = false;
  MenuList: any[];
  data: any[];
  IsShow: boolean = false;
  isOnlySingleMenu: boolean = true;
  CBID: any = '';
  QRCodeImage: any = '';
  QRCodeURL: any = '';
  CopyTest: any = 'Copy URL';
  SBLogoBase64 = '';
  NotificationData: { "Mobile_Web": string }[] = [];
  hasNotifications = false;
  onClickNotificationIcon = true;
  private notificationClickedFlag: boolean;

  private notifier: NotifierService;
  private _eref: any;
  IsCorpRM: boolean = false; //Added by Avdhoot
  constructor(private MainHeaderMenuService: MainHeaderMenuService,
    private EncrdecrService: EncrdecrService,
    private LoginService: LoginService,
    private Logout: TokenExpireService,
    private router: Router,
    private route: ActivatedRoute,
    private TransactionService: TransactionService,
    private Loader: CustomLoaderService,
    private elRef: ElementRef) {
    // Subscribe to router events to detect navigation start
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Close the notification box when navigation starts
        this.isDropdownOpen = false;
      }
    });

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.SetViewPort(event.target.innerWidth)
  }
  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    $("#OthersMenuUl").slideUp();
    $("#OthersMenuUl2").slideUp();
  }
  ngOnInit() {
    this.SetViewPort(window.innerWidth);
    this.isLoggedIn$ = this.MainHeaderMenuService.isShowHeader;
    this.LoginToken = sessionStorage.getItem("`huR,8@RK9^??b4 -.hfs!!uR,XX");
    let ut = sessionStorage.getItem('L2D3506kIHSk3E0');

    if (sessionStorage.getItem('hioio23ionk') != null && sessionStorage.getItem('hioio23ionk') != undefined && sessionStorage.getItem('hioio23ionk') != '') {
      console.log(sessionStorage.getItem('hioio23ionk'))
      if (sessionStorage.getItem('hioio23ionk') == 'True') {
        this.onClickNotificationIcon = false;
      }
    }
    // const onClickNotificationIconValue = localStorage.getItem('onClickNotificationIcon');
    // if (onClickNotificationIconValue !== null) {
    //   this.onClickNotificationIcon = onClickNotificationIconValue === 'true';
    // } else {
    //   this.onClickNotificationIcon = false;
    // }
    if (ut != undefined && ut != null) {
      this.UserType = this.EncrdecrService.getDecr(ut);
    }
    let name = sessionStorage.getItem('V4WGROuLLBE6t98');
    if (name != undefined && name != null) {
      this.UserName = this.EncrdecrService.getDecr(name);
    }
    if (
      sessionStorage.getItem("kioh298hKh2kj") != null &&
      sessionStorage.getItem("kioh298hKh2kj") != undefined
    ) {
      let CorpIFDFlag = this.EncrdecrService.getDecr(sessionStorage.getItem('kioh298hKh2kj'))
      if (CorpIFDFlag == '1') {
        this.IsCorpRM = true;
      }

    }

    this.BindMenu();
    this.NotificationBind();
    this.FetchSBLogo();
  }

  //Added by Saddam
  BindMenu() {

    let CBId = sessionStorage.getItem('qAkZrQYLWNBSlki'); //Session_CBId
    if (CBId != null && CBId != undefined && CBId != '') {
      this.CBID = this.EncrdecrService.getDecr(CBId);
    }
    if (this.UserType != "3") {

      // let SBCode = this.EncrdecrService.getDecr(CBId);
      console.log("User Id is " + this.CBID);
      const json = {
        "userid": this.CBID
      }
      let data = JSON.stringify(json);

      this.LoginService.AccessWiseData(data).subscribe(
        (data_res: any) => {
          let res = JSON.parse(this.EncrdecrService.getDecr(data_res)); 
          console.log(res);
          if (res.ArrayOfResponse != null && res.ArrayOfResponse.length > 0) {
            let Singlemenu = res.ArrayOfResponse[0].SubMenuName;
            let t = res.ArrayOfResponse[0];
            let r = res.ArrayOfResponse[0].SubMenuURL;
            if (Singlemenu == null) {
              this.isOnlySingleMenu = true;
            } else {
              this.isOnlySingleMenu = false;
            }
            this.IsShow = true;
            this.MenuList = res.ArrayOfResponse;
            console.log('Menus==>>>>>>>>>>>>>> ',this.MenuList)
            this.data = res.ArrayOfResponse[0].data;
          }
          else {
            this.IsShow = false;
          }
        });
    }

  }
  SetViewPort(w) {
    if (w <= 767) {
      this.IsMobile = true;
    }
    else {
      this.IsMobile = false;
    }
  }
  ngAfterViewInit() {
    $(document).ready(function () {
      setTimeout(() => {
        $("img.svg").each(function () {
          var $img = $(this);
          var imgID = $img.attr("id");
          var imgClass = $img.attr("class");
          var imgURL = $img.attr("src");

          $.get(
            imgURL,
            function (data) {
              // Get the SVG tag, ignore the rest
              var $svg = $(data).find("svg");

              // Add replaced image's ID to the new SVG
              if (typeof imgID !== "undefined") {
                $svg = $svg.attr("id", imgID);
              }
              // Add replaced image's classes to the new SVG
              if (typeof imgClass !== "undefined") {
                $svg = $svg.attr("class", imgClass + " replaced-svg");
              }

              // Remove any invalid XML tags as per http://validator.w3.org
              $svg = $svg.removeAttr("xmlns:a");

              // Replace image with new SVG
              $img.replaceWith($svg);
            },
            "xml"
          );
        });
      }, 1000);

    });
  }
  OnClickHamerburg() {
    this.IsCollapseSideMenu = !this.IsCollapseSideMenu;
    this.isCollapseMenu.emit(this.IsCollapseSideMenu);
  }
  onClickFooterMenu(tab: string) {
    this.IsHomeActive = false;
    this.IsActNowActive = false;
    this.IsAddActive = false;
    this.IsLoyaltyActive = false;
    this.IsMenuActive = false;

    switch (tab) {
      case 'Home': {
        this.IsHomeActive = true;
        this.IsActNowActive = false;
        this.IsAddActive = false;
        this.IsLoyaltyActive = false;
        this.IsMenuActive = false;
        this.GoToHome();
        break;
      }
      case 'Act': {
        this.IsActNowActive = true;
        this.IsHomeActive = false;
        this.IsAddActive = false;
        this.IsLoyaltyActive = false;
        this.IsMenuActive = false;
        break;
      }
      case 'Add': {
        this.IsAddActive = true;
        this.IsHomeActive = false;
        this.IsActNowActive = false;
        this.IsLoyaltyActive = false;
        this.IsMenuActive = false;
        this.OpenOthersMenu();
        break;
      }
      case 'Loyalty': {
        this.IsLoyaltyActive = true;
        this.IsHomeActive = false;
        this.IsActNowActive = false;
        this.IsMenuActive = false;
        this.IsAddActive = false;
        break;
      }
      case 'Menu': {
        this.IsMenuActive = true;
        this.IsLoyaltyActive = false;
        this.IsHomeActive = false;
        this.IsActNowActive = false;
        this.IsAddActive = false;
        this.OpenOthers();
        break;
      }
      default: {
        // this.IsHomeActive = true;
        break;
      }
    }
  }
  OpenOthersMenu() {
    $(".fixed-bottom.navbar .navbar-nav .nav-item").removeClass("active");
    $("#IdOthersMenu").addClass("f-active");
    $("#IdOtherImage").addClass("f-active");
    $("#OthersMenuUl").slideToggle();
    $('#overlay').css('display', 'block');
  }
  OpenOthers() {
    $(".fixed-bottom.navbar .navbar-nav .nav-item").removeClass("active");
    $("#IdOthersMenu").addClass("f-active");
    $("#IdOtherImage").addClass("f-active");
    $("#OthersMenuUl2").slideToggle();
    $('#overlay').css('display', 'block');
  }
  LogOut() {

    this.LoginService.Logout(this.LoginToken).subscribe((res: any) => { });
    sessionStorage.removeItem("hioio23ionk");
    sessionStorage.setItem('hioio23ionk', 'False');
    sessionStorage.removeItem("Session_SubBrokerId");
    sessionStorage.removeItem("Session_SubBrokerCode");
    sessionStorage.removeItem("`huR,8@RK9^??b4 -.hfs!!uR,XX");
    //if (this.UserType == '2') {
    //this.router.navigate(["/cp-login"], { relativeTo: this.route });
    //}
    //else {


    this.Logout.LogoutUser();
    //}
  }
  GoToHome() {
    if (this.UserType == '1') {
      this.router.navigate(['/dashboard'], { relativeTo: this.route });
    }
    else if (this.UserType == '2') {
      this.router.navigate(['/dashboard'], { relativeTo: this.route });
    }
    else {
      this.router.navigate(['/dashboard'], { relativeTo: this.route });
    }
  }
  GoToProfile() {
    if (!this.IsCorpRM) {
      if (this.UserType == '3') {
        this.Loader.show();
        this.TransactionService.GetClientUCCDetails(this.CBID).subscribe(
          (response: any) => {
            let res = JSON.parse(this.EncrdecrService.getDecr(response))
            this.Loader.hide();
            if (res != null) {
              if (
                res.Message != null &&
                res.Message != "" &&
                res.Message.indexOf("Not Authorized") > -1
              ) {
                return;
              }
              else {
                if (res.ArrayOfResponse.length > 0) {
                  let G_UCCCode = res.ArrayOfResponse[0].ClientCode;
                  sessionStorage.setItem('N1QJHdOkO7Hz8QC', this.EncrdecrService.setEncr(this.CBID.toString()));
                  sessionStorage.setItem('W113TKilrF1u1gL', this.EncrdecrService.setEncr(G_UCCCode.toString()));
                  this.router.navigate(['/client-profile'], { relativeTo: this.route });
                }
                else {
                  sessionStorage.setItem('3erT@Qr65TdXwq1', this.EncrdecrService.setEncr(this.CBID));
                  this.router.navigate(['/Client-Profile-Preview/Preview'], { relativeTo: this.route });
                }
              }
            }
          },
          (err) => {
            this.Loader.hide();
          }
        );
      }
      else if (this.UserType == '2') {
        let loginId = this.EncrdecrService.getDecr(sessionStorage.getItem("F44sGAGh2xwkpUL"))
        sessionStorage.setItem("Khgo235hklk", "true");
        sessionStorage.setItem("RpkW60j5hsYGPRA", "IFD");
        sessionStorage.setItem('7X5pOZwlTjPjtb4D0p', this.EncrdecrService.setEncr(loginId));
        this.router.navigateByUrl('/onboarding-preview');
      }
    }

  }

  ShowQRCode() {
    this.CopyTest = "Copy URL"

    const json = {
      "EmpCode": this.CBID,
      "UserType": this.UserType
    }
    let data = JSON.stringify(json);
    this.LoginService.GetQRCode(data).subscribe(
      (response: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(response))
        console.log(res);
        if (res.ArrayOfResponse != null && res.ArrayOfResponse.length > 0) {
          this.QRCodeImage = "data:image/png;base64," + res.ArrayOfResponse[0].Base64String;
          this.QRCodeURL = res.ArrayOfResponse[0].URL
          $("#QRCode").modal({ backdrop: "static", keyboard: false });

        }
        else {
          this.QRCodeImage = null;
        }
      });
  }
  DownloadQRCode() {

    try {
      let url = this.QRCodeImage.split("base64,")[1];
      if (url != null && url != "" && url != undefined) {
        // var a = url.split('|')[1];

        const b64toBlob1 = (url, contentType = '', sliceSize = 512) => {
          const byteCharacters1 = atob(url);
          const byteArrays1 = [];

          for (let offset = 0; offset < byteCharacters1.length; offset += sliceSize) {
            const slice1 = byteCharacters1.slice(offset, offset + sliceSize);

            const byteNumbers1 = new Array(slice1.length);
            for (let i = 0; i < slice1.length; i++) {
              byteNumbers1[i] = slice1.charCodeAt(i);
            }

            const byteArray1 = new Uint8Array(byteNumbers1);
            byteArrays1.push(byteArray1);
          }

          const blob12 = new Blob(byteArrays1, { type: contentType });

          return blob12;
        }

        const blob1 = b64toBlob1(url, 'png');
        //const blob1 = b64toBlob1(url, fileType);
        const blobUrl1 = URL.createObjectURL(blob1);

        // FileSaver.saveAs(blobUrl1, `Doc_${Type}.${fileType}`);

        FileSaver.saveAs(blobUrl1, `MyQR.png`);
      }

      else {

      }
    }
    catch (err) {

    }
  }

  CopyURL() {
    copy(this.QRCodeURL);
    this.CopyTest = "Copied"
    setTimeout(() => {
      this.CopyTest = "Copy URL"
    }, 5000);
  }
  onClickOutSide(event) {
    $(document).mouseup(function (e) {
      var container = $("#IdOthersMenu");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        // $('#overlay').css('display', 'none');
        $("#OthersMenuUl").slideUp();
        $("#OthersMenuUl2").slideUp();
        $('#overlay').slideUp();
      }
    });
  }
  CloseMenu() {
    $("#OthersMenuUl").slideUp();
    $("#OthersMenuUl2").slideUp();
    $('#overlay').css('display', 'none');
  }

  onclickOverlay() {
    $('#overlay').css('display', 'none');
    $("#OthersMenuUl").hide();
    $("#OthersMenuUl2").hide();
  }


  //Added by Saddam
  ReplaceChar(value: string) {
    let removeSpace = '';
    if (value != null && value != '' && value != undefined) {
      removeSpace = value.trim();
    }
    if (value.includes(' ')) {
      removeSpace = value.replace(/ /g, '_');
      value = removeSpace;
    }
    if (value.includes('/')) {
      removeSpace = value.replace('/', '_');
    }
    return removeSpace;
  }

  //Added by Saddam
  GetImgSource(flag: string) {

    if (flag != null && flag != '' && flag != undefined) {
      flag = flag.trim();
    }
    let imgSrc = '';
    switch (flag) {
      case 'Home':
        imgSrc = '../../../assets/images/Sidebar/Home_new.svg';
        break;

      case 'Dashboard Client':
        imgSrc = '../../../assets/images/Sidebar/Dashboard-Client.svg';
        break;

      case 'Dashboard IFD':
        imgSrc = '../../../assets/images/Sidebar/Dashboard-IFD.svg';
        break;

      case 'Online Transaction':
      case 'MF':
        imgSrc = '../../../assets/images/Sidebar/Online_Transaction.svg';
        break;

      case 'Goal Based Investing':
        imgSrc = '../../../assets/images/Sidebar/Goal_Based_Investing.svg';
        break;

      case 'FD':
        imgSrc = '../../../assets/images/Sidebar/FD.svg';
        break;

      case 'Subbroker':
        imgSrc = '../../../assets/images/Sidebar/Subbroker.svg';
        break;

      case 'Campaign Management':
        imgSrc = '../../../assets/images/Sidebar/Campaign_Management.svg';
        break;

      case 'SGB':
        imgSrc = '../../../assets/images/Sidebar/SGB.svg';
        break;

      case 'IPO/NCD':
        imgSrc = '../../../assets/images/Sidebar/NCD.svg';
        break;

      case 'Clients':
        imgSrc = '../../../assets/images/Sidebar/Clients.svg';
        break;

      case 'Explore Funds':
        imgSrc = '../../../assets/images/Sidebar/Explore_Funds.svg';
        break;

      case 'Corporate IFD':
        imgSrc = '../../../assets/images/mapping.svg';
        break;

      case 'Reports':
        imgSrc = '../../../assets/images/Sidebar/Reports.svg';
        break;

      case 'Reconciliation':
        imgSrc = '../../../assets/images/Sidebar/Reconciliation.svg';
        break;

      case 'CoBranding':
        imgSrc = '../../../assets/images/JM/Box.svg';
        break;

      case 'Client Documents':
        imgSrc = '../../../assets/images/JM/Single_Upload_IPO.svg';
        break;

      case 'Act Now':
        imgSrc = '../../../assets/images/Sidebar/Reconciliation.svg';
        break;

      case 'Franchise Onboarding':
        imgSrc = '../../../assets/images/JM/Menu-Plus.svg';
        break;

    }
    return imgSrc;
  }

  //notification toggle
  toggleDropdown() {

    this.isDropdownOpen = !this.isDropdownOpen;
    if (sessionStorage.getItem('hioio23ionk') != null && sessionStorage.getItem('hioio23ionk') != undefined && sessionStorage.getItem('hioio23ionk') != '') {
      if (sessionStorage.getItem('hioio23ionk') == 'True') {
        this.onClickNotificationIcon = false;
      }
    }
    else {
      sessionStorage.setItem('hioio23ionk', 'True')
      this.onClickNotificationIcon = false;
    }
  }

  //notification is on and user click outside close the notification
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false; // Close the dropdown if clicked outside
    }
  }




  NotificationBind() {

    let User_ID = "";
    let id = sessionStorage.getItem('F44sGAGh2xwkpUL');
    if (id != null && id != undefined && id != '') { User_ID = this.EncrdecrService.getDecr(id); }
    let json = { "User_Type": this.UserType, "User_ID": User_ID }
    let data = JSON.stringify(json);
    this.LoginService.GetNotificationsDetails(data).subscribe(
      (data_res: any) => {
        let res_str = this.EncrdecrService.getDecr(data_res);
        let res = JSON.parse(res_str);
        console.log("notification", res);
        this.NotificationData = res.ArrayOfResponse;
        // Update hasNotifications based on the length of NotificationData
        this.hasNotifications = this.NotificationData.length > 0;
        console.log("Notification value" + this.onClickNotificationIcon)
      }, (error: any) => { console.log(error) }
    );
  }

  FetchSBLogo() {
    // if mobile login that time this session return true 
    if (sessionStorage.getItem("sdjrubvfaoieen") == "True") {
      let userid: string;
      let usertype: Number;
      if (sessionStorage.getItem("eraevrrttrgsrde") != null && sessionStorage.getItem("eraevrrttrgsrde") != "" && sessionStorage.getItem("eraevrrttrgsrde") != undefined) {
        let _userid = sessionStorage.getItem("eraevrrttrgsrde").toString();
        if (_userid != undefined && _userid != null && _userid != '') {
          userid = this.EncrdecrService.getDecr(_userid)
        }
      }
      if (sessionStorage.getItem("L2D3506kIHSk3E0") != null && sessionStorage.getItem("L2D3506kIHSk3E0") != "" && sessionStorage.getItem("L2D3506kIHSk3E0") != undefined) {
        let _usertype = sessionStorage.getItem("L2D3506kIHSk3E0").toString();
        if (_usertype != undefined && _usertype != null && _usertype != '') {
          usertype = parseInt(this.EncrdecrService.getDecr(_usertype));
        }
      }
      if (usertype != 1) {
        let objreq = {
          UserContact: (userid),
          UserType: (usertype)
        }
        let strobj = JSON.stringify(objreq);
        this.Loader.show();
        this.LoginService.GetFetchSBLogo(strobj).subscribe((res: any) => {
          this.Loader.hide();
          if (IsValidArray(res.ArrayOfResponse)) {
            if (res.ArrayOfResponse[0].SBLogoBase64 != null) {
              let x = res.ArrayOfResponse[0]
              if (x.SBLogoBase64 != null && x.SBLogoBase64 != "" && x.SBLogoBase64 != undefined) {
                sessionStorage.setItem('SBLoginBase64PP', x.SBLogoBase64);
                this.SBLogoBase64 = x.SBLogoBase64
              }
            }
          }
        },
          (error: any) => {
            this.Loader.hide();
          })
      }
    }
    else {
      if (sessionStorage.getItem("SBLoginBase64PP") != null && sessionStorage.getItem("SBLoginBase64PP") != "null" && sessionStorage.getItem("SBLoginBase64PP") != "" && sessionStorage.getItem("SBLoginBase64PP") != undefined) {
        let _SBLogoBase64 = sessionStorage.getItem("SBLoginBase64PP");
        if (_SBLogoBase64 != undefined && _SBLogoBase64 != null && _SBLogoBase64 != '') {
          this.SBLogoBase64 = _SBLogoBase64
        }
      }
    }
  }
}
