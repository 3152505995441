<div class="CommonFooterGray" *ngIf='CompanyName == "AU" && !IsMobile'>

</div>
<div class="CommonFooterWhite" *ngIf='CompanyName == "AU" && !IsMobile'>

</div>
<div class="CommonFooter pre" *ngIf='CompanyName == "Mahindra" && !IsMobile'>
    <div class="CopyrightText">
        <div>&#169; 2021 Mahindra Finance | CIN-L65921MH1991PLC059642 | <a href="https://www.mahindrafinance.com/privacy-and-policy" target="_blank">Privacy Policy</a>| <a (click)="ShowDisclaimer()">Disclaimer</a> | <a (click)="OpenContactUs()" class="CursorPointer"> Contact Us </a></div>
        <div class="SocialIcons">
            <a href="https://www.facebook.com/mahindrafin/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
            <a href="https://twitter.com/MahindraFin" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
            <a href="https://www.linkedin.com/company/mahindra-finance/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
            <a href="https://www.instagram.com/mahindrafin/?igshid=1wcwnkle0d8jd" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
            <a href="https://www.youtube.com/user/MahindraFinRise" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a>
        </div>
    </div>
</div>
<!-- <div class="CommonFooter pre CommonFooterIFA" *ngIf='CompanyName == "IFA"'>
    Powered by &nbsp; <a href="http://optimumfintech.com/" target="_blank">Optimum Financial Solutions Pvt. Ltd.</a>
</div> -->
<div class="modal" id="DisclaimerModal" appDisabledRightClick>
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: 750px;">
        <div class="modal-content AlertModalContentCls">
            <div class="modal-header AlertModalHeader">
                <h5 class="modal-title">Disclaimer</h5>
            </div>
            <div class="modal-body AlertModalBody">
                Mahindra Finance intends to provide clear and unbiased information about investment products and services. The information and data mentioned here are generic in nature. Our efforts are to offer unbiased, accurate and responsible data to the best of our ability. We are however, in no manner or form responsible for any discrepancies in the data published on our website.
            <br/>
            <br/>
            There is no intention or purpose of violating any intellectual property or ancillary rights. All the information provided on this portal is subject to its discretion and is likely to change without any notice. Though, any changes in public utility will be communicated on our portal.
            <br/>
            <br/>
            Although we have tried to maintain high standards in quality, clarity and accuracy of the material posted on the portal and it is our constant endeavor to ensure the accuracy of the data compiled and represented in this website and cannot be held liable for any misrepresentation due to delay in receiving updates by third parties or network errors, snags in system, maintenance shutdown or any other unforeseen reasons. Due to the above reasons, we have no financial liability to any user on account of use of the information provided on this website. The information is represented as is without and warranties either express or implied .Employees, partners, and associated staff of Mahindra Finance are not accountable for any loss, harm or damage that occurs or may occur or arise from the usage of information from the portal. You are advised to read through Terms and Conditions and give consent before doing any transaction, verify the veracity / appropriateness of the information before taking any decisions, and exercising your discretion.
            <br/>
            <br/>
            You shall be solely responsible for the authenticity of the document(s) uploaded here. We have the discretion for acceptance or rejection of any such information, document(s) uploaded here.
        </div>
            <div class="modal-footer AlertModalFooter" style="padding-top: 15px !important;">
                <button type="button" data-dismiss="modal" class="CursorPointer">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ContactUsModal" appDisabledRightClick>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content AlertModalContentCls">
        <div class="modal-header AlertModalHeader">
          <h5 class="modal-title">Contact Us</h5>
        </div>
        <div class="modal-body AlertModalBody">
          <p>
            <b>Welcome to Mahindra Finance Investment Solutions support.  Our endeavour is to provide superior customer service.</b>
            <br/>
            <br/>
            <b>Contact Us (Toll free) :</b> 18002669266 ( Mon � Sat, 9:30 am to 6:30 pm).
            <br/>
            <b>Write to us at :</b>  cs.investmentsolutions@mahindra.com.
            <br/>

            <b>Visit our Website :</b> https://www.mahindrafinance.com/investment-solutions
          </p>
        </div>
        <div class="modal-footer AlertModalFooter">
          <button type="button" data-dismiss="modal" class="CursorPointer">Close</button>
        </div>
      </div>
    </div>
  </div>
