<app-header-pre-login></app-header-pre-login>
<div class="LoginOuter HDFC_LoginBg">
    <div class="MainOuter">
        <div class="card mt-3 tab-card">
            <div class="UnderLineTabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab"
                            aria-controls="One" aria-selected="true">Mobile</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two"
                            aria-selected="false">Email</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " id="three-tab" data-toggle="tab" href="#three" role="tab"
                            aria-controls="Three" aria-selected="false">PAN</a>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade px-4 pt-5 show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                    <div class="row">
                        <div class="col-md-12 mx-auto">
                            <div class="step-opne">
                                <form autocomplete="off" [formGroup]="MobileLoginForm">
                                    <div class="row">
                                        <div class="col TbxMarginBtm">
                                            <div class="has-float-label">
                                                <input id="Mobile" type="text" formControlName="Mobile"
                                                    class="form-control CommonTextBox"
                                                    [ngClass]="{ 'is-invalid': isMobileLoginFormSubbmitted && j.Mobile.errors }"
                                                    placeholder="&nbsp;" maxlength="10" (change)="SendOtpToMobile()"
                                                    (keypress)="isNumber($event)" />
                                                <label class="lable" for="Mobile">Mobile Number<span
                                                        class="ColorRed">*</span></label>
                                                <div *ngIf="isMobileLoginFormSubbmitted && j.Mobile.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="j.Mobile.errors.required">Please enter mobile number
                                                    </div>
                                                    <div *ngIf="j.Mobile.errors.pattern">Enter Valid mobile number.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col TbxMarginBtm">
                                            <div class="has-float-label">

                                                <!-- <show-hide-password btnStyle="" [btnOutline]="false"> -->
                                                <input id="MobileOTP" type="password" formControlName="MobileOTP"
                                                    (keypress)="isNumber($event)" class="form-control CommonTextBox"
                                                    placeholder="&nbsp;" maxlength="6"
                                                    [ngClass]="{ 'is-invalid': isMobileLoginFormSubbmitted && j.MobileOTP.errors }" />
                                                <label class="lable" for="MobileOTP">Enter OTP<span class="ColorRed">
                                                        *</span></label>
                                                <div *ngIf="isMobileLoginFormSubbmitted && j.MobileOTP.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="j.MobileOTP.errors.required">Please enter OTP</div>
                                                    <div *ngIf="j.MobileOTP.errors.pattern">Enter 6 Digit OTP Number
                                                    </div>
                                                </div>
                                                <!-- </show-hide-password> -->
                                            </div>
                                            <span routerLinkActive="active" class="ForgetPassLink"
                                                (click)="ResendOtpToMobile()">
                                                Resend OTP
                                            </span>
                                        </div>
                                    </div>
                                    <div class="text-center SubmitMarginTop">
                                        <button class="CommonSubmitButton"
                                            [disabled]='j.Mobile.value == "" || j.MobileOTP.value == ""'
                                            (click)="OnMobileLogin()">Login</button>
                                    </div>
                                    <div *ngIf='!IsMobile' class="col-12">
                                        <div class="CreateAccount">
                                            <div *ngIf='IsAllowLogin' class="w-100 text-center">
                                                If you are a new client of {{WelcomeText}},
                                                <span class="BlueText" routerLink="/client-preOnboading"
                                                    routerLinkActive="active">click here to create a new
                                                    account.</span><br />

                                                If you are an existing {{WelcomeText}} client and don't have a
                                                password
                                                <span class="BlueText" routerLink="/generate-password"
                                                    routerLinkActive="active">Click Here</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade px-4 pt-5 " id="two" role="tabpanel" aria-labelledby="two-tab">
                    <div class="row">
                        <div class="col-md-12 mx-auto">
                            <div class="step-opne">
                                <form autocomplete="off" [formGroup]="EmailLoginForm">
                                    <div class="row">
                                        <div class="col TbxMarginBtm">
                                            <div class="has-float-label">
                                                <input id="Email" type="text" formControlName="Email" autofocus
                                                    class="form-control CommonTextBox"
                                                    [ngClass]="{ 'is-invalid': isEmailFormSubbmitted && e.Email.errors }"
                                                    placeholder="&nbsp;" (change)="SendOtpToEmail()" />
                                                <label class="lable" for="Email">Enter Email<span class="ColorRed">
                                                        *</span></label>
                                                <div *ngIf="isEmailFormSubbmitted && e.Email.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="e.Email.errors.required">Please enter Email</div>
                                                    <div *ngIf="e.Email.errors.pattern">Enter valid email ID</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col TbxMarginBtm">
                                            <div class="has-float-label">
                                                <!-- <show-hide-password btnStyle="" [btnOutline]="false"> -->
                                                <input id="EmailOTP" type="text" formControlName="EmailOTP"
                                                    (keypress)="isNumber($event)" class="form-control CommonTextBox"
                                                    placeholder="Enter OTP" maxlength="6" placeholder="&nbsp;"
                                                    [ngClass]="{ 'is-invalid': isEmailFormSubbmitted && e.EmailOTP.errors }" />
                                                <label class="lable" for="EmailOTP">Enter OTP<span class="ColorRed">
                                                        *</span></label>
                                                <div *ngIf="isEmailFormSubbmitted && e.EmailOTP.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="e.EmailOTP.errors.required">please enter otp</div>
                                                    <div *ngIf="e.EmailOTP.errors.pattern">Enter 6 digit OTP number
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- </show-hide-password> -->
                                            <span routerLinkActive="active" class="ForgetPassLink"
                                                (click)="onClickReSendEmailOTP()">
                                                Resend OTP
                                            </span>
                                        </div>
                                    </div>
                                    <div class="text-center SubmitMarginTop">
                                        <button class="CommonSubmitButton"
                                            [disabled]='e.Email.value == "" || e.EmailOTP.value == ""'
                                            (click)="onEmailLoginClick()">Login</button>
                                    </div>
                                    <div *ngIf='!IsMobile' class="col-12">
                                        <div class="CreateAccount">
                                            <div *ngIf='IsAllowLogin' class="w-100 text-center">
                                                If you are a new client of {{WelcomeText}},
                                                <span class="BlueText" routerLink="/client-preOnboading"
                                                    routerLinkActive="active">click here to create a new
                                                    account.</span><br />

                                                If you are an existing {{WelcomeText}} client and don't have a
                                                password
                                                <span class="BlueText" routerLink="/generate-password"
                                                    routerLinkActive="active">Click Here</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade px-4 pt-5 " id="three" role="tabpanel" aria-labelledby="two-tab">
                    <div class="row">
                        <div class="col-md-12 mx-auto">
                            <div class="step-opne">
                                <form autocomplete="off" [formGroup]="LoginForm" (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col TbxMarginBtm">
                                            <div class="has-float-label">
                                                <input id="Username" type="text" formControlName="Username"
                                                    oninput="this.value = this.value.toUpperCase()" autofocus
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': LoginSubmitted && r.Username.errors }"
                                                    placeholder="&nbsp;" maxlength="10"
                                                    class="form-control CommonTextBox" />
                                                <label class="lable" for="Username">Login Id<span class="ColorRed">
                                                        *</span></label>
                                                <div *ngIf="LoginSubmitted && r.Username.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="r.Username.errors.required">Please enter your pan
                                                        number.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col TbxMarginBtm">
                                            <div class="has-float-label">

                                                <show-hide-password btnStyle="" [btnOutline]="false">
                                                    <input id="Password" type="password" formControlName="Password"
                                                        placeholder="&nbsp;"
                                                        class="form-control CommonTextBox passwordtxt" maxlength="20" />
                                                    <label class="lable" for="Password">Enter Password<span
                                                            class="ColorRed">
                                                            *</span></label>
                                                    <div *ngIf="LoginSubmitted && r.Password.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="r.Password.errors.required">Please enter your
                                                            password.
                                                        </div>
                                                        <div *ngIf="r.Password.errors.pattern">Password contains atleast
                                                            1
                                                            numeric , 1 uppercase, 1 lowercase and 1 special character
                                                        </div>
                                                        <!--Password contains atleast 1 number, 1 uppercase, 1 lowercase and 1 special charactor (@$!%*?&#)-->
                                                    </div>
                                                </show-hide-password>
                                            </div>

                                            <span routerLink="/generate-password" routerLinkActive="active"
                                                class="ForgetPassLink" (click)="SendUserType()">
                                                Generate / Forgot Password?
                                            </span>
                                        </div>
                                    </div>
                                    <div class="text-center SubmitMarginTop">
                                        <button class="CommonSubmitButton"
                                            [disabled]='r.Username.value == "" || r.Password.value == ""'>Login</button>
                                    </div>
                                    <div *ngIf='!IsMobile' class="col-12">
                                        <div class="CreateAccount">
                                            <div *ngIf='IsAllowLogin' class="w-100 text-center">

                                                If you are a new client of {{WelcomeText}},
                                                <span class="BlueText" routerLink="/client-preOnboading"
                                                    routerLinkActive="active">click here to create a new
                                                    account.</span><br />

                                                If you are an existing {{WelcomeText}} client and don't have a
                                                password
                                                <span class="BlueText" routerLink="/generate-password"
                                                    routerLinkActive="active">Click Here</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- The Modal End -->
<div class="modal" id="AlertModalLogin" appDisabledRightClick>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content AlertModalContentCls">
            <div class="modal-header AlertModalHeader">Select Client</div>
            <div id="AlertModalContentv" class="modal-body AlertModalBody">

                <div class="tab-content TabContentData px-0">
                    <div id="Clients" class="tab-pane fade show active">
                        <div class="TableDataMainData MainDataLRPadding pt-15">
                            <div class="table-responsive">
                                <table id="ClientsTable" class="CommonTable ClientsTable" *ngIf="isMobileListDisplay">
                                    <thead>
                                        <tr>
                                            <th>Name.</th>
                                            <th>PAN Number</th>
                                            <th>Mobile Number</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let client of ClientData">
                                            <td>{{client.FirstName}}</td>
                                            <td>{{client.PANNo}}</td>
                                            <td>{{client.MobileNo}}</td>
                                            <td>
                                                <button class="BtnCommon"
                                                    style="background-color: var(--primary-color);"
                                                    (click)="OnClickClientSelection(client)">Login</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer AlertModalFooter">

            </div>
        </div>
    </div>
</div>

<div class="modal" id="AlertModalLoginEmail" appDisabledRightClick>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content AlertModalContentCls">
            <div class="modal-header AlertModalHeader">Select Client</div>
            <div id="AlertModalContentv5" class="modal-body AlertModalBody">

                <div class="tab-content TabContentData px-0">
                    <div id="Clients" class="tab-pane fade show active">
                        <div class="TableDataMainData MainDataLRPadding pt-15">
                            <div class="table-responsive">
                                <table id="ClientsTable" class="CommonTable ClientsTable" *ngIf="isEmailListDisplay">
                                    <thead>
                                        <tr>
                                            <th>Name.</th>
                                            <!-- <th>PAN Number</th> -->
                                            <th>Email Id</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let client of ClientDataBasedOnEmail">
                                            <td>{{client.FirstName}}</td>
                                            <!-- <td>{{client.PANN}}</td> -->
                                            <td>{{client.EmailId}}</td>
                                            <td>
                                                <button class="BtnCommon"
                                                    style="background-color: var(--primary-color);"
                                                    (click)="BasedonEmailSelection(client)">Login</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer AlertModalFooter">

            </div>
        </div>
    </div>
</div>