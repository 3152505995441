<div  class="ClsTransactionOTPModel p-0 ModalNPS" appDisabledRightClick>
    <div class="modal-dialog">
        <div class="modal-content AlertModalContentCls MainBgOtp">
            <div class="modal-body p-0">
                <div class="MainBg">
                <div class="MainInnerFormWithoutFlex" style="background-color: transparent;">
                    <div class="OTPOuter BoxShadow text-center">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="ResetOTP()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                        <div class="head">Verification</div>
                        <div class="mob" *ngIf="CompanyName=='AU'">OTP has been sent to your registered Mobile no {{MobileNo}} and Email ID {{EmailID}}</div>
                        <div class="mob" *ngIf="CompanyName!='AU'">OTP has been sent to your registered Email ID.</div>
                        <div class="leb">Enter OTP</div>
                        <div>
                            <form autocomplete="off" [formGroup]="OTPForm">
                                <ng-otp-input #ngOtpInput
                                [config]="config"
                                    [ngClass]="{ 'is-invalid': OTPFormSubmitted && o.OTP.errors }"
                                    (onInputChange)="onOtpChange($event)"></ng-otp-input>
                                <!-- (keypress)="ValidatePattern('Number',$event)" -->
                                <div *ngIf="OTPFormSubmitted && o.OTP.errors" class="invalid-feedback">
                                    <div *ngIf="o.OTP.errors.required">Please enter OTP</div>
                                </div>
                            </form>
                        </div>

                        <div>
                            <button class="res" (click)='ResendOTP()' disabled='true'><strong>Resend OTP</strong></button>
                        </div>
                        <div class="time">
                            <div id='Time'></div>
                        </div>
                        <!-- <div *ngIf="OFSClient==4">This order has been reported to exchange. Please enter the OTP to proceed and make payment. In case the payment is not completed, the order will remain open with exchange till T+1 working day and shall be adjusted against any other payment on FIFO (first in first out) basis.</div> -->
                        <div><button type="submit" class="CommonSubmitButton" (click)="onSubmitOTP();"
                                [disabled]="!OTPForm.valid">Submit</button></div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="modal mdlCls" id="AlertModalprofilemodels" appDisabledRightClick data-backdrop='static' data-keyboard='false'>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content AlertModalContentCls">
        <div class="modal-header AlertModalHeader">Important Message</div>
        <div id="AlertModalContentmodels" class="modal-body AlertModalBody"></div>
        <div class="modal-footer AlertModalFooter">
          <button type="button" data-dismiss="modal" class="CursorPointer">Okay</button>
        </div>
      </div>
    </div>
  </div>

