import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EncrdecrService } from '../Auth/encrdecr.service';
import { ConvertToJSONService } from './convert-to-json.service';
const headers = { headers: new HttpHeaders({ 'content-type': 'application/json' }) }
@Injectable({
  providedIn: 'root'
})
export class ClientDataService {
  private WelcomeText: any;
  private CompanyName: any;
  ServerUrl = environment.ServerUrl;


  constructor(private http: HttpClient, private ConvertToJSON: ConvertToJSONService,
    private EncrdecrService: EncrdecrService) { }
  SetWelcomeText(message: string) {
    this.WelcomeText = message;
  }
  GetWelcomeText() {
    return this.WelcomeText;
  }
  SetCompanyName(name: string) {
    this.CompanyName = name;
  }
  GetCompanyName() {
    return this.CompanyName;
  }

  clearData() {
    this.WelcomeText = '';
    this.CompanyName = '';
  }
  getCompanyDetails(CompanyId) {
    let Url = `${this.ServerUrl}/api/CompanyDetails/Details`;
    const QueryString = `Flag=${this.EncrdecrService.setEncr('CompanyWise')}&CompanyId=${this.EncrdecrService.setEncr(CompanyId)}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(Url, body, headers);
  }
  MahindraAnalyticcode(UserType, userID, PathId) {
    let id = '';
    if (UserType == '1') {
      id = userID;
    }
    if (UserType == '3') {
      id = this.alphabetPosition(userID)
    }
    let url = ''
    if (PathId != '') {
      url = `${window.location.pathname}/${PathId}`;
    }
    else {
      url = window.location.pathname;
    }
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'ID': id,
      'pageTitle': url
    });
  }

  alphabetPosition(text) {
    var result = "";
    for (var i = 0; i < text.length; i++) {
      if (isNaN(text[i])) {
        var code = text.toUpperCase().charCodeAt(i)
        if (code > 64 && code < 91) {
          let rr: any = (code - 64);
          if (rr.toString().length == 1) {
            rr = `0${rr}`
          }
          result += rr;
        }
      }
      else {
        result += text[i]
      }
    }
    return result;
  }
}
