
          <a (click)="OpenContactUs();" class="CursorPointer">
            Contact Us
            <!-- <i class="fa fa-id-badge text-red" style="background-color: #fff; font-size: 20px;"></i> -->
          </a>
        

<div *ngIf="IsContactUsOpen" [@openClose]="IsContactUsOpen ? 'open' : 'closed'" class="MyAccountOuter">
  <p>
    <b>Welcome to Mahindra Finance Investment Solutions support.  Our endeavour is to provide superior customer service.</b>
    <br/>
    <br/>
    <b>Contact Us (Toll free) :</b> 18002669266 ( Mon – Sat, 9:30 am to 6:30 pm).
    <br/>
    <b>Write to us at :</b>  cs.investmentsolutions@mahindra.com.
    <br/>

    <b>Visit our Website :</b> https://www.mahindrafinance.com/investment-solutions
  </p>
</div>
<div class="modal" id="ContactUsModal" appDisabledRightClick>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">
        <h5 class="modal-title">Contact Us</h5>
      </div>
      <div class="modal-body AlertModalBody">
        <p>
          <b>Welcome to Mahindra Finance Investment Solutions support.  Our endeavour is to provide superior customer service.</b>
          <br/>
          <br/>
          <b>Contact Us (Toll free) :</b> 18002669266 ( Mon – Sat, 9:30 am to 6:30 pm).
          <br/>
          <b>Write to us at :</b>  cs.investmentsolutions@mahindra.com.
          <br/>
      
          <b>Visit our Website :</b> https://www.mahindrafinance.com/investment-solutions
        </p>
      </div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" data-dismiss="modal" class="CursorPointer">Close</button>
      </div>
    </div>
  </div>
</div>
