import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EncrdecrService } from '../Auth/encrdecr.service';
import { ProfileService } from '../services/profile.service';
declare var $: any;
@Component({
  selector: 'app-client-assistance-map',
  templateUrl: './client-assistance-map.component.html',
  styleUrls: ['./client-assistance-map.component.scss']
})
export class ClientAssistanceMapComponent implements OnInit {
  constructor(private profileService: ProfileService, private EncrdecrService: EncrdecrService, private router : Router) { }
  AllIFD: any;
  ClientBasicInfoId = "";
  ngOnInit(): void {
    this.GetShowAllIFD();
  }
  GetShowAllIFD() {
    if (sessionStorage.getItem("F44sGAGh2xwkpUL") != undefined && sessionStorage.getItem("F44sGAGh2xwkpUL") != "") {
      this.ClientBasicInfoId = this.EncrdecrService.getDecr(sessionStorage.getItem("F44sGAGh2xwkpUL"))
      let Request = {
        MappingId: "",
        ClientId: this.ClientBasicInfoId,
        Flag: "AllIFD"
      }
      this.profileService.GetClientAssistanceMapping(Request).subscribe(
        (response: any) => {
          let res = JSON.parse(this.EncrdecrService.getDecr(response))
          if (res.ArrayOfResponse.length > 0) {
            let info = res.ArrayOfResponse[0];
            if (info.ResultMsg == "Success") {
              this.AllIFD = info.PinCodeMatchSB;
            }
            else {
              alert("Failed")
            }
          }
          else{
            alert("Failed")
          }
        }
      );
    }
  }
  onClickSelect(id:any)
  {
    let Request = {
      MappingId: id,
      ClientId: this.ClientBasicInfoId,
      Flag: "MappingSB"
    }
    this.profileService.GetClientAssistanceMapping(Request).subscribe(
      (response: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(response))
        if (res.ArrayOfResponse.length > 0) {
          let info = res.ArrayOfResponse[0];
          if (info.ResultMsg == "Success") {
            this.CallAlertModal("Your assistance assignment was completed successfully!")
            // this.router.navigate(['client-assistance-map'])
            this.router.navigate(['dashboard']);
          }
          else {
            this.CallAlertModal("Your assistance assignment was Failed, Please try again");
          }
        }
        else
        {
          this.CallAlertModal("Your assistance assignment was Failed, Please try again")
        }
      }
    );
  }
  CallAlertModal(msg: any) {
    $("#AlertModal").modal({ backdrop: 'static', keyboard: false });
    $('#AlertModalContent').text(msg);
  }
}
