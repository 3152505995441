import { environment } from 'src/environments/environment';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EncrdecrService } from '../../Auth/encrdecr.service';
import { CustomLoaderService } from '../../services/custom-loader.service';
import { TransactionService } from '../../services/transaction.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VariableService } from 'src/app/services/variable.service';
import { ProfileService } from "../../services/profile.service";
declare var $: any;

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @Input() OpenModel: boolean;
  @Output() OnOTP: EventEmitter<any> = new EventEmitter()
  @Output() CallResendOTP: EventEmitter<any> = new EventEmitter()
  OTPForm: FormGroup;
  OTPFormSubmitted: boolean = false;
  G_ClientBasicInfoId: string;
  OtpTimer: number = 180;
  OtpTimeRemening: any = "3:00";
  OTP_Timer_Function: any;
  MobileNo : string = '';
  EmailID : string = '';
  CompanyName:string = '';
  config = {
    length:6,
    inputClass:'OTPBox',
    allowNumbersOnly:true,
    inputStyles:{
      'text-security' : 'disc',
      '-webkit-text-security' : 'disc',
      '-moz-text-security' : 'disc'
    }
  }
  constructor(private fb: FormBuilder,
    private Loader: CustomLoaderService,
    private EncrdecrService: EncrdecrService,
    private transaction: TransactionService,
    private router: Router,
    public bsModalRef: BsModalRef,
    private profileService: ProfileService,
    private appVariable: VariableService,
    private clientProfileService: ProfileService) {

    this.OTPForm = this.fb.group({
      OTP: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  G_SubBrokerId:any
  UserType : any



  ngOnInit() {

    this.StartTimer();
    let MobileNumber = sessionStorage.getItem('mobdfghj');
    let Emailddress = sessionStorage.getItem('embdfghj');
    if(MobileNumber!= null && MobileNumber != '' && MobileNumber != undefined)
    {
    this.appVariable.MobileNo = MobileNumber;
    }
    if(Emailddress!= null && Emailddress != '' && Emailddress != undefined)
    {
    this.appVariable.EmailId = Emailddress;
    }
    this.CompanyName = environment.CompayName;
    var loaderInterval = setInterval(() => {
      let str = document.readyState;
      if (str == 'complete') {
        if(this.appVariable.MobileNo != null && this.appVariable.MobileNo != '' && this.appVariable.MobileNo != undefined){
          this.MobileNo = this.ValueMasking(this.appVariable.MobileNo,"Mobile");
        }
        if(this.appVariable.EmailId != null && this.appVariable.EmailId != '' && this.appVariable.EmailId != undefined){
          this.EmailID = this.ValueMasking(this.appVariable.EmailId,"Email");
        }
        clearInterval(loaderInterval)
      }
    }, 1000);

    let tempClientBasicInfoId = sessionStorage.getItem('N1QJHdOkO7Hz8QC');

    if (tempClientBasicInfoId != null && tempClientBasicInfoId != undefined) {

      this.G_ClientBasicInfoId = this.EncrdecrService.getDecr(sessionStorage.getItem('N1QJHdOkO7Hz8QC'));
    }
    if(this.G_ClientBasicInfoId == null || this.G_ClientBasicInfoId == "" || this.G_ClientBasicInfoId == undefined){
      this.G_ClientBasicInfoId = sessionStorage.getItem('KgxufWuE7G2cMlZTfzX');
    }
  }
  get o() {
    return this.OTPForm.controls;
  }
  ResetOTP() {
    this.bsModalRef.hide();
    this.ClearIntervalOTP()
  }
  ClearIntervalOTP() {
    this.ngOtpInput.setValue('');
    $('#Time').text(this.OtpTimeRemening);

    clearInterval(this.OTP_Timer_Function);
  }
  onSubmitOTP() {

    if(sessionStorage.getItem('MjTFLjHtTGH86dfjh')== 'FDVerification'){
      const request = {
        "VERIFICATION_ID" : this.G_ClientBasicInfoId,
        "Trans_Type": 'FDVerification',
        "Flag": "V",
        "OTP": this.OTPForm.controls['OTP'].value
      };
  var json = JSON.stringify(request);
      this.clientProfileService.SendOTP(json).subscribe(response => {
        let data = JSON.parse(this.EncrdecrService.getDecr(response))
        if (data.Message == "Verified") {// && id == "1" && SecreteCode == this.OTPForm.controls["OTP"].value
          this.Loader.hide();
          this.bsModalRef.content.onClose.next(true);
          this.bsModalRef.hide();
          this.ClearIntervalOTP();
        }
        else {
          this.Loader.hide();
          this.ngOtpInput.setValue('');;
          this.CallAlertModal("Invalid OTP Code");
        }
      });
    }
    else{
      const request = {
        "ClientBasicInfoId": this.EncrdecrService.setEncr(this.G_ClientBasicInfoId),
        "Flag": this.EncrdecrService.setEncr("V"),
        "OTP": this.EncrdecrService.setEncr(this.OTPForm.controls['OTP'].value)
      };
      const json = JSON.stringify(request);
      this.transaction.sendTransactionOTP(json).subscribe(data => {

        console.log(data.Message);
        let code = this.EncrdecrService.getDecr(data.Refference_No);
        let id = code.split('|')[0];
        let SecreteCode = code.split('|')[2];
        if (data.ID == 1 && id == "1" && SecreteCode == this.OTPForm.controls["OTP"].value) {
          this.Loader.hide();
          this.bsModalRef.content.onClose.next(true);
          this.bsModalRef.hide();
          this.ClearIntervalOTP();
        }
        else {
          this.Loader.hide();
          this.ngOtpInput.setValue('');;
          this.CallAlertModal("Invalid OTP Code");
        }
      });
    }

  }
  onOtpChange(e) {
    let val = e;
    let v = '';
    v = v + val;
    this.OTPForm.controls["OTP"].setValue(v);
  }
  ResendOTP() {
    // this.CallResendOTP.emit(true);
    this.ClearIntervalOTP();
    this.StartTimer();
    this.bsModalRef.content.action.next(true);
  }
  ValueMasking(value, Flag) {
    let MaskValue = "";
    if (Flag == "Mobile") {
      MaskValue = value.replace(/\d(?=\d{4})/g, "X");
    }else if (Flag == "Email") {
      let index = value.indexOf("@");
      MaskValue =
        value.substring(0, 2) +
        "XXXXXXXX" +
        value.substring(index, value.length); //- 1
    }
    return MaskValue;
  }
  CallAlertModal(msg: any) {
    $("#AlertModalprofilemodels").modal({
      backdrop:'static',
      keyboard:false
    });
    $('#AlertModalContentmodels').text(msg);
  }
  StartTimer() {
    $(".res").removeClass("act").prop("disabled", true);
    let t = this.OtpTimer;
    this.OTP_Timer_Function = setInterval(function () {
      //
      if (t >= 0) {
        const minutes = Math.floor(t / 60);
        let seconds: any = t % 60;
        if (seconds < 10) {
          seconds = `0${seconds}`;
        }

        $("#Time").text(`0${minutes}:${seconds}`);
        console.log($("#Time").val())
        t -= 1;
        if (t == 0) {
          $(".res").addClass("act").prop("disabled", false);
        }
      }
    }, 1000);
  }

}
