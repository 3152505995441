import { EncrdecrService } from './../../Auth/encrdecr.service';
import { isNullOrUndefined } from '../../validation';
import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import {  BsModalRef } from 'ngx-bootstrap/modal';
import { CustomLoaderService } from "../../services/custom-loader.service";
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-digital-kyc-model',
  templateUrl: './digital-kyc-model.component.html',
  styleUrls: ['./digital-kyc-model.component.scss']
})
export class DigitalKYCModelComponent implements OnInit {
 KYC_UTURL: string=""
 G_ClientBasicInfoId:string=""
 TOKEN_ID:string=""
  constructor(
    private Loader: CustomLoaderService,
    private bsModalRef: BsModalRef,
    private EncrdecrService: EncrdecrService,
    private clientProfileService: ProfileService,
    private LoginService: LoginService,

  ) { }

  ngOnInit() {
    
      if(!isNullOrUndefined(sessionStorage.getItem("YsMpKKZ5xJxJJ/bgZfA==")) && sessionStorage.getItem("YsMpKKZ5xJxJJ/bgZfA==")!='')
      {
        this.KYC_UTURL=this.EncrdecrService.getDecr(sessionStorage.getItem("YsMpKKZ5xJxJJ/bgZfA=="))
      }
      let tempClientBasicInfoId = sessionStorage.getItem("N1QJHdOkO7Hz8QC");
      if(tempClientBasicInfoId != null && tempClientBasicInfoId != undefined)
        {
          this.G_ClientBasicInfoId = this.EncrdecrService.getDecr(sessionStorage.getItem("N1QJHdOkO7Hz8QC")); //Session_ClientBasicInfoId

          this.TOKEN_ID=this.EncrdecrService.getDecr(sessionStorage.getItem("`huR,8@RK9^??b4 -.hfs!!uR,XX"));
      }

  }

  HideModal(){
    this.bsModalRef.content.onClose.next(true);
    this.bsModalRef.hide();
  }

  SubmitClientConcern()
  {
    window.open(this.KYC_UTURL,"_blank");
    const request={
      Clientbasicinfoid:this.G_ClientBasicInfoId,
      BSESchemeCode: "",
      RPConfirmationFlag:'Y',
      Flag:"digitalKYCConfirmation"
    }

    this.clientProfileService.UpdatehighRPConfirmation(JSON.stringify(request)).subscribe((res) => {
      if(res.ID==0) {
        //this.CallAlertModal(res.Message)
      }


    },(error) => {
      this.Loader.hide();
    });

    this.SessionExpired(this.TOKEN_ID);
  }



  SessionExpired(LoginToken) {
    this.LoginService.Logout(LoginToken).subscribe(
      (res: any) => {
        const sessionvalue = sessionStorage.getItem('nvjsd212djskd$#21ksdj');
        sessionStorage.clear();
        localStorage.clear();
        sessionStorage.setItem('logo', '../../assets/images/Logo_AUBank.svg');
        sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("4"));
        sessionStorage.setItem('Mnuoisns6wqgswj2$#', "1");
        window.location.href = 'https://www.aubank.in/netbanking/logout';
      }
    );
  }
}
