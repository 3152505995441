<div class="modal-dialog modal-dialog-centered m-0">
  <div class="modal-content AlertModalContentCls">
    <div class="modal-header AlertModalHeader" id="modal-basic-title">Important Message
      <!-- <button type="button" class="close" (click)="HideModal()">&times;</button> -->
    </div>
    <div  class="modal-body AlertModalBody margin-left">
        <ul>
          <li>
            Your PAN, Mobile Number & E-mail Id will be shared for KYC, with your consent, with UTI MF for further processing
          </li>
          <li>
            You will be leaving AU Bank webpage and entering a website operated by third party i.e., UTI Mutual Fund (MF)
          </li>
          <li>
            AU Bank does not control such websites & bears no responsibility for services offered by UTI MF
          </li>
        </ul>
    </div>
    <div class="modal-footer AlertModalFooter">
      <button type="button" data-dismiss="modal" class="CursorPointer" (click)="SubmitClientConcern()"> Continue </button>
    </div>
  </div>
</div>

<!-- <div class="modal" id="KYcAlertMode" appDisabledRightClick>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">Important Message</div>
      <div class="modal-body AlertModalBody" id="KYCAlterMsg">
      </div>
      <div class="modal-footer AlertModalFooter">
        <button  type="button" data-dismiss="modal" class="CursorPointer">okay</button>
      </div>
    </div>
  </div>
</div> -->


