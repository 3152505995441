import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientFilter'
})
export class ClientFilterPipe implements PipeTransform {

  transform(value: any, clientFilter: string): any { 
         
    //let FinalArray: any;
    let FinalArray= [];
    let InnerSearch = [];
    if (clientFilter === "-1") {
      FinalArray = value;
    } else {
      switch (clientFilter) {
        case '0':
          value.forEach(element => {
            if (element.UCCCode != '') {
              InnerSearch.push(element);
            }
          });
          break;
        case '1':
          value.forEach(element => {
            if (element.UCCCode == '') {
              InnerSearch.push(element);
            }
          });
          break;
        case '2':
          value.forEach(element => {
            if (element.URNMandateId != '') {  //with URN
              InnerSearch.push(element);
            }
          });
          break;
        case '3':
          value.forEach(element => {
            if (element.URNMandateId == '') { //without URN
              InnerSearch.push(element);
            }
          });
          break;
        case '4':
          value.forEach(element => {
            if (element.NachMandateId != '') { //with NACH
              InnerSearch.push(element);
            }
          });
          break;
        case '5':
          value.forEach(element => {
            if (element.NachMandateId == '') { //without NACH
              InnerSearch.push(element);
            }
          });
          break;
        case '6':
          value.forEach(element => {
            if (element.EmailId == '') { //without Email
              InnerSearch.push(element);
            }
          });
          break;
        case '7':
          value.forEach(element => {
            if (element.MobileNo == '') { //without Mobile
              InnerSearch.push(element);
            }
          });
          break;
        case '8':         
          // value.forEach(element => {
          //   if (element.DateOfBirth == '') { //without DOB
          //     InnerSearch.push(element);
          //   }
          // });
          break;
        case '9':
          value.forEach(element => {
            if (element.BirthdayStatus == 'Today') { //Today
              InnerSearch.push(element);
            }
          });
          //-----------------------------
          // value.forEach(element => {
          //   var str = element.DateOfBirth;
          //   var newstr = str.replace("T00:00:00", "");
          //   var ddMM = newstr.split('-');
          //   let birthDate = ddMM[1] + '/' + ddMM[2];            
          //   if (birthDate == Today) { //Today
          //     InnerSearch.push(element);              
          //   }
          // });
          break;
        case '10':   
        value.forEach(element => {
          if (element.BirthdayStatus == 'Tomorrow') {  //Tomorrow
            InnerSearch.push(element);
          }
        });
        //-----------------------------                                    
          // value.forEach(element => {            
          //   var str = element.DateOfBirth;
          //   var newstr = str.replace("T00:00:00", "");
          //   var ddMM = newstr.split('-');
          //   let birthDate = ddMM[1] + '/' + ddMM[2];            
          //   if (birthDate == Tomorrow) { //Tomorrow
          //     InnerSearch.push(element);              
          //   }           
          // });
          break;
        case '11':   
        value.forEach(element => {
          if (element.BirthdayStatus == 'Yesterday') { //Yesterday
            InnerSearch.push(element);
          }
        });
        //-----------------------------                                    
          // value.forEach(element => {
          //   var str = element.DateOfBirth;
          //   var newstr = str.replace("T00:00:00", "");
          //   var ddMM = newstr.split('-');
          //   let birthDate = ddMM[1] + '/' + ddMM[2];
          //   if (birthDate == Yesterday) { //Yesterday
          //     InnerSearch.push(element);              
          //   }           
          // });
          break;
      }
      FinalArray = InnerSearch;      
    }
    ////console.log(FinalArray);
    return FinalArray;
  }
}
