import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EncrdecrService } from 'src/app/Auth/encrdecr.service';
import { CustomLoaderService } from 'src/app/services/custom-loader.service';
import { HdfcSsoService } from '../services/hdfc-sso.service';

import { environment } from 'src/environments/environment';
declare var $: any;


@Component({
  selector: 'app-hdfcsso',
  templateUrl: './hdfcsso.component.html',
  styleUrls: ['./hdfcsso.component.scss']
})
export class HdfcssoComponent implements OnInit {

  constructor(
    private Loader: CustomLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private EncrdecrService: EncrdecrService,
    private hdfcsso: HdfcSsoService,
  ) { }


  ngOnInit() {
    this.Loader.show();
    this.route.queryParams.subscribe(params => {
      debugger;
      this.Loader.hide();
      if (params.UserData) {
        let data = {
          data: params.UserData.toString().replace(/\s/g, '+')
        }

        console.log('this.data', JSON.stringify(data));
        let str_reqData = JSON.stringify(data);

        if (str_reqData) {
          this.ValidateUser(str_reqData);
        } else {
          this.CallAlertModal('There seems to be some issue, please try again later.');
        }
      }
      else {
        this.CallAlertModal('There seems to be some issue, please try again later.');
      }
    },
      (err: any) => {
        this.Loader.hide();
        this.CallAlertModal('There seems to be some issue, please try again later.');
      }
    );
  }

  ValidateUser(data: any) {
    this.Loader.show();

    this.hdfcsso.Validateclient(data).subscribe(
      (res: any) => {
        debugger;
        console.log('HDFC SSO Response Data===>>', JSON.stringify(res));
        this.Loader.hide();
        if (res.code == 200) {

          sessionStorage.setItem('Tgsh@#3734fjfskshh==', '173.212.234.56');
          sessionStorage.setItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF', this.EncrdecrService.setEncr("1"));
          sessionStorage.setItem('`huR,8@RK9^??b4 -.hfs!!uR,XX', res.TOKEN_ID);
          sessionStorage.setItem('m5JkoXISmYRAIuY', res.USER_ID);
          sessionStorage.setItem('F44sGAGh2xwkpUL', res.LOGIN_ID); //Session_SubBrokerId
          sessionStorage.setItem('V4WGROuLLBE6t98', res.USER_NAME); //Session_UserName
          sessionStorage.setItem('V8hpPfITcxhEI9x', res.EMAIL_ID); //Session_UserEmail
          sessionStorage.setItem('Xe003SRRyYEoOC2', res.MOBILE); //Session_UserMobile

          if (res.RazorCustId !== null && res.RazorCustId !== undefined && res.RazorCustId !== 'null') {
            sessionStorage.setItem('pOWgAF62qFXnZMn', res.RazorCustId); //RazorCustId
          }
          else {
            sessionStorage.setItem('pOWgAF62qFXnZMn', ''); //RazorCustId
          }
          sessionStorage.setItem('Hldq31TLYwRbLJ8', res.SubBrokerCode); //Session_SubBrokerCode
          sessionStorage.setItem('L2D3506kIHSk3E0', res.USER_TYPE); //Session_UserType
          sessionStorage.setItem('qAkZrQYLWNBSlki', res.LOGIN_ID); //Session_CBId
          sessionStorage.setItem('pi4ZU2P3M8JE4q7', ''); //Session_B64CompanyLogo

          if (res.Family_ID !== null && res.Family_ID !== undefined && res.Family_ID !== 'null') {
            sessionStorage.setItem('hn0bHyGhfpGtrPe', res.Family_ID);  //Session_Family_ID
          }
          switch (this.EncrdecrService.getDecr(res.USER_TYPE)) {
            case "2":
              sessionStorage.setItem('m5JkoXISmYRAIuY', res.SubBrokerCode); //Session_UserId
              if (res.RazorePaymentStatus) {
                window.location.href = '/dashboard';
              }
              else {
                this.Loader.hide();
                sessionStorage.setItem('nbj%98LS74nkjsn~@&4njrkd`!kd*294', res.RazorePaymentStatus)
                window.location.href = '/dashboard';
              }
              break;
            case "3":
              sessionStorage.setItem('m5JkoXISmYRAIuY', res.USER_ID); //Session_UserId
              sessionStorage.setItem('UZT6qHaDZSz66kx', res.LOGIN_ID); //Session_ClientBasicInfoId
              sessionStorage.setItem('N1QJHdOkO7Hz8QC', res.LOGIN_ID); //Session_ClientBasicInfoId
              window.location.href = '/client-dashboard';
              break;
          }
        }
        else {
          this.CallAlertModal('There seems to be some issue, please try again later.');
        }
      },
      (err: any) => {
        this.Loader.hide();
        this.CallAlertModal('There seems to be some issue, please try again later.');
      }
    )
  }

  CallAlertModal(msg: any) {
    $('#AlertModalSSO').modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#AlertModalSSOContent').text(msg);
  }

  GoToLoginScreen() {
    // this.router.navigate(['/cp-login']);    
    window.location.href = environment.HDFCFranchiseURL + "login-rp";
  }
}

