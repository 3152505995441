import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-logout',
  templateUrl: './mobile-logout.component.html',
  styleUrls: ['./mobile-logout.component.scss']
})
export class MobileLogoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
   
  }

}
