<div class="modal-header AlertModalHeader">Terms & Conditions</div>
<div class="modal-body AlertModalBody" style="height:450px; overflow-y:scroll">
  <div>
    Having read and understood the content of the SID / SAI of the scheme, I/we hereby apply for units of the
    scheme. I have read and understood the terms, conditions, details, rules and regulations governing the scheme.
    I/We hereby declare that the amount/s invested in the scheme is through legitimate sources only and is not
    designed for the purpose of the contravention of any Act, Rules, Regulations, Notifications or Directives of the
    provisions of the Income Tax Act, Anti Money Laundering Laws, Anti-Corruption Laws or any other applicable laws
    enacted by the Government of India from time to time. I/we have not received nor have been induced by any rebate
    or gifts, directly or indirectly in making this investment. I/We confirm that the funds invested in the Scheme,
    legally belongs to me/us. In the event that the “Know Your Client” process is not completed by me/us to the
    satisfaction of the Mutual Fund, (I/we hereby authorize the Mutual Fund, to redeem the funds invested in the
    Scheme, in favour of the applicant, at the applicable NAV prevailing on the date of such redemption and
    undertake such other action with such funds that may be required by the law.) The ARN holder has disclosed to
    me/us all the commissions (trail commission or any other mode), payable to him for the different competing
    Schemes of various Mutual Funds amongst which the Scheme is being recommended to me/ us. I/We confirm that I/We
    do not have any existing Micro SIP/Lumpsum investments which together with the current application will result
    in aggregate investments exceeding Rs. 50,000 in a year (Applicable for Micro investment only.) For NRIs only -
    I / We confirm that I am/ we are Non Residents of Indian nationality/origin and that I/We have remitted funds
    from abroad through approved banking channels or from funds in my/ our Non Resident External / Non Resident
    Ordinary / FCNR account. I/We confirm that details provided by me/us are true and correct. We understand that
    Mahindra & Mahindra Financial Services Ltd (MMFSL) has tied up with various vendors for routing the transaction
    and the payment to the AMCs. We understand and accept that the transaction will be completed only on receipt of
    the application and the funds by the AMC. We will not hold MMFSL responsible for failure of the transaction due
    to any reasons.
  </div>
</div>
