import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { ConvertToJSONService } from './convert-to-json.service';
import { EncrdecrService } from './../Auth/encrdecr.service';
const headers = {
  headers: new HttpHeaders({ 'content-type': 'application/json' })
}
@Injectable({
  providedIn: 'root'
})
export class NseOnboardingService {

  ServerUrl = environment.ServerUrl;

  isGenerateIIN: boolean;
  constructor(private http: HttpClient, private ConvertToJSON: ConvertToJSONService, private EncrdecrService: EncrdecrService,) { }

  GetToken() {

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);

    const tokenOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Basic ${TOKEN}`,
      })
    }
    return tokenOption;
  }
  GetTokenNonJSON() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers: new HttpHeaders({
        'Authorization': `Basic ${TOKEN}`,
      })
    }
    return tokenOption;
  }

  NSECountryList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('COUNTRY_LIST_NSE');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    console.log("NSECountryList Master URL is " + url);
    console.log("NSECountryList Master body is " + JSON.stringify(body));
    return this.http.post<any>(url, body, token);
  }
  NSEStateList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('STATE_LIST_NSE');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    console.log("NSEStateList Master URL is " + url);
    console.log("NSEStateList Master body is " + JSON.stringify(body));
    return this.http.post<any>(url, body, token);
  }
  NSEBankList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('Bank_list');
    let REFF_ID2 = this.EncrdecrService.setEncr('BankCode');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}&REFF_ID2=${REFF_ID2}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    console.log("NSE Bank List Master URL is " + url);
    console.log("NSE Bank List Master body is " + JSON.stringify(body));
    return this.http.post<any>(url, body, token);
  }
  NSEClientBankList(refId: number): Observable<any> {
    let s_url = `${this.ServerUrl}/api/DropDownNSE/Details?TRANS_TYPE=Bank_list&REFF_ID=${refId}&CON_STR=OptiCloud`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  NSERelationShipList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('RELATION_LIST');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    console.log("NSERelationShipList Master URL is " + url);
    console.log("NSERelationShipList Master body is " + JSON.stringify(body));
    return this.http.post<any>(url, body, token);
  }
  SegmentList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('Segment');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    return this.http.post<any>(url, body, token);
  }
  NSEOccupationList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('OCCUPATION_LIST_NSE');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    console.log("NSEOccupationList Master URL is " + url);
    console.log("NSEOccupationList Master body is " + JSON.stringify(body));
    return this.http.post<any>(url, body, token);
  }
  NSEIdentificationTypeList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('IDTYPE_LIST_NSE');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    console.log("NSEIdentificationTypeList Master URL is " + url);
    console.log("NSEIdentificationTypeList Master body is " + JSON.stringify(body));
    return this.http.post<any>(url, body, token);
  }
  NSEAddressTypeList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('ADDRESSTYPE_LIST_NSE');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    return this.http.post<any>(url, body, token);
  }
  NSESourceOfIncomeList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('SOURCEOFINCOME_LIST_NSE');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    return this.http.post<any>(url, body, token);
  }
  NSEAccountTypeList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('ACCOUNTTYPE_LIST_NSE');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    console.log("NSEAccountTypeList Master URL is " + url);
    console.log("NSEAccountTypeList Master body is " + JSON.stringify(body));
    return this.http.post<any>(url, body, token);

  }
  NSEGrossAnnualIncomeList() {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('GROSS_INCOME');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    console.log("NSEGrossAnnualIncomeList Master URL is " + url);
    console.log("NSEGrossAnnualIncomeList Master body is " + JSON.stringify(body));
    return this.http.post<any>(url, body, token);
  }
  NSENationalityList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('NationalityNSE');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    return this.http.post<any>(url, body, token);
  }
  HoldingTypeList(): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('HOLDINGTYPE');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    return this.http.post<any>(url, body, token);
  }


  //Generate Mobile OTP
  GenerateOTP(formData): Observable<any> {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/OTP_Birla/Details`;
    return this.http.post<any>(url, formData, tokenOption);
  }

  //Verify OTP
  VerifyOTP(formData): Observable<any> {
    const tokenOption = this.GetToken();
    return this.http.post<any>(`${this.ServerUrl}/api/OTP_Birla/Details`, formData, tokenOption);
  }

  //Submit Basicdetails
  PostBasicDetails(formData): Observable<any> {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/ClientOnlineofflineBoarding/Details_Post_OnlineOfflineBirla_Mahindra`;
    console.log('Basic Details URL' + url);
    console.log('Basic Details bodsy' + formData);
    const body = { data: this.EncrdecrService.setEncr(formData) };
    return this.http.post<any>(url, body, tokenOption);
  }

  //Submit Basicdetails
  GenerateIINSingle(Firstholder, PanNo, LoginUserId, TaxStatus, ISMinor, GuardianName, GuardianDOB, FirstName): Observable<any> {

    let s_url = `${this.ServerUrl}/api/IINDetails/GenerateIIN?FisrtHolderId=${Firstholder}&HoldingType=SI&PANNO=${PanNo}&LoginUserId=${LoginUserId}&TaxStatus=${TaxStatus}&IsMinor=${ISMinor}&GuardianName=${GuardianName}&GuardianDOB=${GuardianDOB}&FirstName=${FirstName}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    console.log("Generate IIN URL " + url);
    console.log("Generate IIN Body " + JSON.stringify(body));
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  //Submit GenerateIIN
  GenerateIINJoint(Firstholder, secondholder, HoldingType, LoginUserId, TaxStatus): Observable<any> {

    let s_url = `${this.ServerUrl}/api/IINDetails/GenerateIIN?FisrtHolderId=${Firstholder}&SecondHolderId=${secondholder}&HoldingType=${HoldingType}&LoginUserId=${LoginUserId}&TaxStatus=${TaxStatus}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetJointHolderList(ClientBasicInfoId, LoginUserId, EmpId, SubbrokerId): Observable<any> {
    let s_url = `${this.ServerUrl}/api/SubbrokerManageClient/GetSubbrokerManageClient?TRANS_TYPE=JointHolder&SubbrokerCode=${SubbrokerId}&Flag=6&FirstName=subbroker&ClientUCCStatus=&ClientBasicInfoId=${ClientBasicInfoId}&USER_ID=${LoginUserId}&EMP_ID=${EmpId}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(s_url, body, tokenOption).pipe();
  }
  //risk profile questionlist
  GetRiskProfileQuestionList(): Observable<any> {
    const token = this.GetToken();
    let s_url = `${this.ServerUrl}/api/RiskProfile/GetDetails?TRANS_TYPE=MstQuestion`;
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  //risk profile
  GetRiskProfileAnswerList(QuestionId): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr('AnsByQueId');
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}&REFF_ID=${QuestionId}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    return this.http.post<any>(url, body, token);
  }

  GetRiskProfileScoreAndName(formData: any): Observable<any> {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/RiskProfile/RiskProfileScore`;
    return this.http.post<any>(url, formData, tokenOption);
  }

  AddRiskProfile(formData: any): Observable<any> {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/RiskProfile/Details`;
    return this.http.post<any>(url, formData, tokenOption);
  }

  GetRiskProfileByClient(ClientBasicInfoId: any): Observable<any> {
    let s_url = `${this.ServerUrl}/api/RiskProfile/GetDetails?TRANS_TYPE=QueAnsByClient&ClientBasicInfoId=${ClientBasicInfoId}`
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  //PAN
  CheckPAN(formData: any): Observable<any> {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/ClientOnBoarding/Details`;
    return this.http.post<any>(url, formData, tokenOption);
  }
  //PAN
  CheckKYC(PAN: any): Observable<any> {
    let s_url = `${this.ServerUrl}/api/ClientOnlineofflineBoarding/GetKYCDetails?PANNO=${PAN}`;
    //return this.http.get<any>(url)
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  //upload document
  UploadDocument(formData: any): Observable<any> {
    const tokenOption = this.GetTokenNonJSON();
    let url = `${this.ServerUrl}/api/ClientOnlineofflineBoarding/UploadDocument`;
    return this.http.post<any>(url, formData, tokenOption);
  }

  //Client NSE
  NSEJoinClientList(SubBrokerId: any, UserType: any): Observable<any> {

    let s_url = `${this.ServerUrl}/api/ReportFilter/ReportDetails?TRANS_TYPE=${this.EncrdecrService.setEncr('CLIENT_LIST')}&LOGIN_ID=${this.EncrdecrService.setEncr(SubBrokerId)}&USER_TYPE=${this.EncrdecrService.setEncr(UserType)}&ZONE_ID=&REGION_ID=&BRANCH_ID=&SB_ID=&EMP_ID=&FAMILY_ID=&CLIENT_ID=0`;

    const headers = this.GetToken();
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  NSEClientList(SubbrokerId: any, UserType, RoleId): Observable<any> {
    //let url= `${this.ServerUrl}/api/ClientList/Details?&SB_ID=${SubbrokerId}`;
    let s_url = '';
    if (UserType == 2) {
      s_url = `${this.ServerUrl}/api/ClientListNSE/Details?&SB_ID=${SubbrokerId}`;
    }
    else if (UserType == 1 && RoleId == 6) {
      s_url = `${this.ServerUrl}/api/ClientListNSE/Details?&EmployeeCode=${SubbrokerId}`;
    }
    else if (UserType == 1 && RoleId >= 1 && RoleId <= 5) {
      s_url = `${this.ServerUrl}/api/ClientListNSE/Details?&ClientBasicInfoId=${SubbrokerId}`;
    }
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetClientProfile(ClientBasicInfoId: any): Observable<any> {
    //
    let url = `${this.ServerUrl}/api/ClientOnBoardingBSE/ClientProfileDetails`;
    let QueryString = `ClientBasicInfoId=${this.EncrdecrService.setEncr(ClientBasicInfoId)}`;


    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let UserType = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    let t = token + "@" + UserId + "|" + UserType + "|" + IpAddress;
    console.log("token ::" + t);
    const TOKEN = btoa(token + "@" + UserId + "|" + UserType + "|" + IpAddress);
    console.log("Token -> " + TOKEN);
    const tokenOption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Basic ${TOKEN}`, })
    }
    return this.http.post<any>(url, body, tokenOption);
  }

  GenerateNSEMandate(formData: any): Observable<any> {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/NSEClientMandate/MandateRegistration`;
    return this.http.post<any>(url, formData, tokenOption);
  }

  UploadIINForm(formData: any): Observable<any> {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/NSEUploadImage/UploadbyteImageAOF`;
    return this.http.post<any>(url, formData, tokenOption);
  }

  PrintIINForm(ClientBasicInfoId: any): Observable<any> {
    let url = `${this.ServerUrl}/api/NSEUploadImage/DownloadIINForm?ClientBasicInfoId=${ClientBasicInfoId}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    // const url = s_url.split('?')[0];
    // const QueryString = s_url.split('?')[1];
    // const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.get<any>(url, tokenOption).pipe();
  }

  DownloadNachForm(ClientBasicInfoId: any, IIN: any, uniquerefno: any): Observable<any> {
    let url = `${this.ServerUrl}/api/NSE_NachUpload/DownloadNachForm?ClientBasicInfoId=${ClientBasicInfoId}&IIN=${IIN}&unique_ref_no=${uniquerefno}&HoldingType=SI`;
    //return this.http.get<any>(url)
    const tokenOption = this.GetToken();
    // const url = s_url.split('?')[0];
    // const QueryString = s_url.split('?')[1];
    // const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    const body = this.ConvertToJSON.QueryStringToJSON(url.split('?')[1]);
    return this.http.post<any>(url.split('?')[0], body, tokenOption).pipe();
  }

  ListUploadIINNatch(IIN: any, ClientBasicInfoId): Observable<any> {
    let url = `${this.ServerUrl}/api/NSE_NachUpload/MandateDetails?TRANS_TYPE=Bank_inFormation&trans_Mode=MFD&IIN=${IIN}&ClientBasicInfoId=${ClientBasicInfoId}`;
    //return this.http.get<any>(url)
    console.log(url);
    const tokenOption = this.GetToken();
    const body = this.ConvertToJSON.QueryStringToJSON(url.split('?')[1]);    // const url = s_url.split('?')[0];
    // const QueryString = s_url.split('?')[1];
    // const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url.split('?')[0], body, tokenOption).pipe();
  }

  ListUploadIINNatchJoin(IIN: any, ClientBasicInfoId, TransType): Observable<any> {
    let url = `${this.ServerUrl}/api/NSE_NachUpload/MandateDetails?TRANS_TYPE=${TransType}&trans_Mode=MFD_SI_JO&IIN=${IIN}&ClientBasicInfoId=${ClientBasicInfoId}`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    // const url = s_url.split('?')[0];
    // const QueryString = s_url.split('?')[1];
    // const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    const body = this.ConvertToJSON.QueryStringToJSON(url.split('?')[1]);
    return this.http.post<any>(url.split('?')[0], body, tokenOption).pipe();
  }

  GenerateAccess(formData: any): Observable<any> {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/SubbrokerManageClient/GetLoginAcess`;
    return this.http.post<any>(url, formData, tokenOption);
  }

  // GetText():Observable<any>{
  //   let url= `http://localhost:12853/api/Blog/BlogText/`;
  //   console.log(url);
  //   return this.http.get<any>(url)
  // }
  PostKYC(formData): Observable<any> {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/KYCRegistration/Details`;
    return this.http.post<any>(url, formData, tokenOption);
  }

  GetClientSignature(formData): Observable<any> {
    const tokenOption = this.GetToken();
    const url = `${this.ServerUrl}/api/UCCCreation/AOFUpload`;
    return this.http.post<any>(url, formData, tokenOption);

  }
  GetClientRMName(ClientBasicInfoId: number): Observable<any> {
    let s_url = `${this.ServerUrl}/api/DropDownNSE/GetClientRMName?TRANS_TYPE=ClientRMName&REFF_ID=${ClientBasicInfoId}&CON_STR=OptiCloud`;
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);

    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  SendIINFATCAActivationLink(body: any): Observable<any> {
    let s_url = `${this.ServerUrl}/api/IINDetails/SendIINActivationMail`;
    const tokenOption = this.GetToken();
    return this.http.post<any>(s_url, body, tokenOption).pipe();
  }
  GetIINStatus(body: any): Observable<any> {
    let s_url = `${this.ServerUrl}/api/IINDetails/GetIINStatus`;
    const tokenOption = this.GetToken();
    return this.http.post<any>(s_url, body, tokenOption).pipe();
  }
  GetDropdownList(trans): Observable<any> {
    const token = this.GetToken();
    let trans_type = this.EncrdecrService.setEncr(trans);
    let SUrl = `${this.ServerUrl}/api/DropDown/ListDetails?TRANS_TYPE=${trans_type}`;
    let url = SUrl.split('?')[0];
    let body = this.ConvertToJSON.QueryStringToJSON(SUrl.split('?')[1]);
    console.log("NSECountryList Master URL is " + url);
    console.log("NSECountryList Master body is " + JSON.stringify(body));
    return this.http.post<any>(url, body, token);
  }

  GetRPDataofClient(ClientBasicInfoId: any, trans_Type): Observable<any> {

    let s_url = `${this.ServerUrl}/api/RiskProfile/GetDetails?TRANS_TYPE=${trans_Type}&ClientBasicInfoId=${ClientBasicInfoId}`
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  PostSendMailtoClientForEsign(formData: any): Observable<any> {
    const tokenOption = this.GetToken();
    let url = `${this.ServerUrl}/api/CampaignManagement/SendMailtoClientForEsign`;
    let request = { data: this.EncrdecrService.setEncr(formData) }
    return this.http.post<any>(url, request, tokenOption);
  }

}
