import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { NseOnboardingService } from './../../services/nse-onboarding.service';
import { ProfileService } from '../../services/profile.service';
import { EncrdecrService } from '../../Auth/encrdecr.service';
import { IsValidPAN, IsValidMobile, IsValidPinCode, IsMICRCode, NumberOnly, CharacterANDSpaceOnly, IFSCCode, IsValidDate, CharacterAndOptionalSpace, IsValidName, IsValidAddress } from '../../validation';
import { TransactionService } from '../../services/transaction.service';
import { TokenExpireService } from '../../services/token-expire.service';
import { CustomLoaderService } from '../../services/custom-loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';


declare var $: any;

@Component({
  selector: 'app-client-profile-model',
  templateUrl: './client-profile-model.component.html',
  styleUrls: ['./client-profile-model.component.scss']
})
export class ClientProfileModelComponent implements OnInit {
  clientDetailsForm: FormGroup;
  clientDetailsFormSubmitted: boolean = false;
  // bsModelRef?: BsModalRef;



  OccupationDataList = [
    {
      "TextField": "Business",
      "ValueField": "01"
    },
    {
      "TextField": "Service",
      "ValueField": "02"
    },
    {
      "TextField": "Professional",
      "ValueField": "03"
    },
    {
      "TextField": "Agriculturist",
      "ValueField": "04"
    },
    {
      "TextField": "Retired",
      "ValueField": "05"
    },
    {
      "TextField": "Housewife",
      "ValueField": "06"
    },
    {
      "TextField": "Student",
      "ValueField": "07"
    },
    {
      "TextField": "Others",
      "ValueField": "08"
    }

  ]

  IncomeListData = [
    {
      "TextField": "Below 1 Lakh",
      "ValueField": "31"
    },
    {
      "TextField": "> 1 <=5 Lacs",
      "ValueField": "32"
    },
    {
      "TextField": ">5 <=10 Lacs",
      "ValueField": "33"
    },
    {
      "TextField": ">10 <= 25 Lacs",
      "ValueField": "34"
    },
    {
      "TextField": "> 25 Lacs < = 1 Crore",
      "ValueField": "35"
    },
    {
      "TextField": "Above 1 Crore",
      "ValueField": "36"
    }
  ]

  IsNominaee: boolean;
  UCCMessage: string = '';
  checked = true;
  showGaurdian: string;
  IsFormSubmitted: boolean = false;
  RelationListwithHolder: any;
  ClientMobileNo: string;
  ClientEmailId: string;

  RiskProfileList: any = [];
  constructor(
    private fb: FormBuilder, private NseOnboardingService: NseOnboardingService,
    private BsModalService: BsModalService,
    private clientProfileService: ProfileService,
    private EncrdecrService: EncrdecrService,
    private TransactionService: TransactionService,
    private Loader: CustomLoaderService,
    private Logout: TokenExpireService,
    private bsModalRef: BsModalRef,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.clientDetailsForm = this.fb.group({
      NomineeName: ['', [Validators.required]],
      GuardianName: ['',],
      POAExpire: [''],
      Occupation: ['', [Validators.required]],
      GrossAnnualIncome: ['', [Validators.required]],
      Country: ['Indian'],
      NomineeRelation: ['', Validators.required],
      GuardianRelation: [''],
      PoliticalExposure: ['', [Validators.required]],
      NomineeDateofBirth: ['', Validators.required],
      ClientRiskProfileId: ['', [Validators.required]],
      IsMinor: [''],
      Nominaeeshow: [''],
      clientCode: [''],
      Isnominateminor: [''],
      Relationwithmobholder: ['', [Validators.required]],
      RelationwithEmailHolder: ['', [Validators.required]],
      NomineePAN: [''],
      signature: ['', [Validators.required]],
      BirthCountry: ['IN', [Validators.required]]
    })
  }

  //variable declaration
  OccupationTypeList: any = [];
  SourceofIncomeList: any = [];
  CountryList: any = [];
  G_SubBrokerCode: string;
  G_ClientBasicInfoId: any;
  IsMinor: boolean = false;
  OFS_Client: number = 0;
  IsMobile: boolean = false;
  ClientPanNo: string = '';
  RelationList: any = [];
  QuestionList: any[];
  QustionArr: any = [];
  GeneratedUCCCode: any;
  G_UCCCode: string = "0";
  IsIFA: boolean = false;
  FullName: any;
  MobileNo: any;
  Email: any;
  DOB: any;
  Fatcasuccess: boolean;
  Nomuneesuccess: boolean;
  IsUCCGenerated: boolean = true;
  IsAOFUploaded: boolean = true;


  ngOnInit() {
    this.Loader.show();
    // this.clientDetailsForm.controls['PoliticalExposure'].setValue("0");
    // this.clientDetailsForm.controls['PoliticalExposure'].setValue("0");
    // this.clientDetailsForm.controls['Country'].setValue("India");
    //this.clientDetailsForm.controls['BirthCountry'].setValue("IN");

    this.clientDetailsForm.controls['Nominaeeshow'].setValue("true");

    var tempcbid = this.EncrdecrService.getDecr(sessionStorage.getItem('N1QJHdOkO7Hz8QC'));
    this.G_ClientBasicInfoId = tempcbid;
    this.IsIFA = sessionStorage.getItem('Vbhs@#%LMKIo=') == 'C' ? false : true;

    if (sessionStorage.getItem('Hldq31TLYwRbLJ8') != null && sessionStorage.getItem('Hldq31TLYwRbLJ8') != undefined) {
      this.G_SubBrokerCode = this.EncrdecrService.getDecr(sessionStorage.getItem('Hldq31TLYwRbLJ8'));
    }
    this.OFS_Client = parseInt(
      this.EncrdecrService.getDecr(
        sessionStorage.getItem("Abodhj#@jdkd/%^82ncdbcnggshd!@#$")
      )
    );
    this.clientProfileService.GetClientInfo(this.G_ClientBasicInfoId).subscribe(
      (response: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(response))
        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            // this.Logout.LogoutUser();
            return;
          }
          else {
            let BasicDetails = res.ArrayOfResponse[0].personalDetails[0].active[0].firstApplicant[0];
            this.ClientPanNo = this.EncrdecrService.getDecr(BasicDetails.panno);
            this.FullName = BasicDetails.Investorname;
            this.MobileNo = BasicDetails.Mobno;
            this.Email = BasicDetails.emailid;
            let FatcaDetails = res.ArrayOfResponse[0].fatcaDetails[0].firstApplicant[0];
            if (res.ArrayOfResponse[0].TransactionDetails.length > 0) {
              let TransactionDetails = res.ArrayOfResponse[0].TransactionDetails[0];
              //this.TransactionDetailsForm.controls['ClientcanREDEEM'].setValue(TransactionDetails.RedeemFlag);
              $('.CustomSelectCanRedeem').val(TransactionDetails.RedeemFlag.toString()).trigger('change');
              //this.TransactionDetailsForm.controls['ClientcanSWITCH'].setValue(TransactionDetails.SwitchFlag);
              $('.CustomSelectCanSwitch').val(TransactionDetails.SwitchFlag.toString()).trigger('change');
              if (TransactionDetails.ClientBasicInfoId != null && TransactionDetails.ClientBasicInfoId != '') {
                this.GeneratedUCCCode = TransactionDetails.ClientBasicInfoId;
              }
              $('.CustomSelectRelationwithmobholder').val(FatcaDetails.RelationWithMobileHolder).trigger('change');
              $('.CustomSelectRelationwithEmailHolder').val(FatcaDetails.RelationWithEmailHolder).trigger('change');
              let ClientMobileNo = this.EncrdecrService.getDecr(BasicDetails.Mobno);
              this.ClientMobileNo = this.ValueMasking(ClientMobileNo, 'Mobile');
              let ClientEmailId = this.EncrdecrService.getDecr(BasicDetails.emailid);
              this.ClientEmailId = this.ValueMasking(ClientEmailId, 'Email');

              if (FatcaDetails.FATKABirthCountry != undefined && FatcaDetails.FATKABirthCountry != '' && FatcaDetails.FATKABirthCountry != null) {
                $('.CustomeselectBirthCountry').val("IN").trigger('change');
              }
              else {
                $('.CustomeselectBirthCountry').val("IN").trigger('change');
              }
              // setTimeout(() => {
              //   $('.CustomSelectRelationwithmobholder').val(FatcaDetails.RelationWithMobileHolder).trigger('change');
              // }, 1000);
              // setTimeout(() => {
              //   $('.CustomSelectRelationwithEmailHolder').val(FatcaDetails.RelationWithEmailHolder).trigger('change');
              // }, 1000);
              this.Loader.hide();
            }
            else {
              this.Loader.hide();
            }
          }
        }
        else {
          this.Loader.hide();
        }
      },
      err => {
        this.Loader.hide();
      })

    this.BindRelation();
    this.GetFatcaRelationshiplist();
    this.BindCountry();
    this.BindRiskProfileList();

    // this.GetUCCCode(this.G_ClientBasicInfoId);
    let d = new Date();
    // this.callmodel();
  }

  get j() {
    return this.clientDetailsForm.controls;
  }
  get fd() {
    return this.clientDetailsForm.controls;
  }

  OnBlurDOB(e) {
    //////
    if (e.target.value != '') {
      this.clientDetailsForm.controls['DOB'].setValue(e.target.value)
    }
    else {
      this.clientDetailsForm.controls['DOB'].setValue(this.clientDetailsForm.controls['DOB'].value);
    }
  }

  BindRelation() {
    this.clientProfileService.GetRelationList().subscribe(
      (data_res: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(data_res));

        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            return;
          }
          else {
            let a = res.ArrayOfResponse;
            let b = a.slice(1);
            this.RelationList = b;
            this.Loader.hide();
          }
        }
        else {
          this.Loader.hide();
        }
      },
      err => {
        this.Loader.hide();
      });
  }
  isnomineenameshow: boolean = true
  Isnominateminorshow: boolean = true
  isGuardianNameshow: boolean = false
  isGuardianRelationshow: boolean = false
  isNomineeRelationshow: boolean = true
  isNomineeDateofBirthshow: boolean = true
  isnomineeLableshow: boolean = true
  nomineedetailshide() {

    if (this.clientDetailsForm.controls['Nominaeeshow'].value == false) {
      this.isnomineenameshow = false; //nominee name
      this.isNomineeDateofBirthshow = false// Nominee Date of Birth
      this.isNomineeRelationshow = false// Nominee Relation
      this.Isnominateminorshow = false;//Is Nominee Minor show
      this.isGuardianNameshow = false;// Guardina Name
      this.isGuardianRelationshow = false;// Guardian Relation
      this.isnomineeLableshow = false;
      //#region Remove validations
      this.clientDetailsForm.controls.NomineeName.clearValidators();
      this.clientDetailsForm.controls.NomineeName.updateValueAndValidity();
      this.clientDetailsForm.controls.NomineeDateofBirth.clearValidators();
      this.clientDetailsForm.controls.NomineeDateofBirth.updateValueAndValidity();
      this.clientDetailsForm.controls.NomineeRelation.clearValidators();
      this.clientDetailsForm.controls.NomineeRelation.updateValueAndValidity();
      //#endregion
      this.SkipNomineeDetails();
    }
    else if (this.clientDetailsForm.controls['Nominaeeshow'].value == true) {
      this.isnomineenameshow = true
      this.isNomineeDateofBirthshow = true
      this.isNomineeRelationshow = true
      this.Isnominateminorshow = true
      this.isnomineeLableshow = false;
      //#region Set Validations
      this.clientDetailsForm.controls.NomineeName.setValidators([Validators.required]);
      this.clientDetailsForm.controls.NomineeName.updateValueAndValidity();
      this.clientDetailsForm.controls.NomineeDateofBirth.setValidators([Validators.required]);
      this.clientDetailsForm.controls.NomineeDateofBirth.updateValueAndValidity();
      this.clientDetailsForm.controls.NomineeRelation.setValidators([Validators.required]);
      this.clientDetailsForm.controls.NomineeRelation.updateValueAndValidity();
      $(".CustomeselectBirthCountry").val("IN").trigger("change");
      //#endregion
    }

  }


  ngAfterViewInit() {

    $(".clsCommonDatePicker").datepicker({
      autoclose: true,
      endDate: '0',
      format: "dd/mm/yyyy",
      todayBtn: 'linked',
      todayHighlight: true,
      orientation: "auto bottom",
    });
    var date = new Date();
    $(".clsPOAExpireDate").datepicker({
      autoclose: true,
      startDate: date,
      format: "dd/mm/yyyy",
      todayBtn: 'linked',
      todayHighlight: true,
      orientation: "auto bottom",
    });

    $(".clsDatePickerDOB").on('change', (e: any) => this.clientDetailsForm.controls['DOB'].setValue(e.target.value));
    $(".clsDatePickerNomineeDOB").on('change', (e: any) => this.clientDetailsForm.controls['NomineeDateofBirth'].setValue(e.target.value));

    $('select').select2();

    $('.CustomSelectGender').select2({
      minimumResultsForSearch: -1
    });
    $('.CustomSelectGenderFD').select2({
      minimumResultsForSearch: -1
    });
    $('.CustomSelectPoliticalExp').select2({
      minimumResultsForSearch: -1
    });
    $('.CustomSelectCanRedeem').select2({
      minimumResultsForSearch: -1
    });
    $('.CustomSelectCanSwitch').select2({
      minimumResultsForSearch: -1
    });
    $(".CustomSelectNomRel").attr("data-placeholder", "Relationship With Nominee");
    $(".CustomSelectNomRel").select2();
    $(".CustomSelectGuarRel").attr("data-placeholder", "Relationship With Guardian");
    $(".CustomSelectGuarRel").select2();
    $(".CustomSelectPoliticalExp").attr("data-placeholder", "Are You Politically Exposed?");
    $(".CustomSelectPoliticalExp").select2();
    $(".CustomSelectOccupation").attr("data-placeholder", "Select Occupation");
    $(".CustomSelectOccupation").select2();
    $(".CustomSelectCountry").attr("data-placeholder", "Select Country of Birth");
    $(".CustomSelectCountry").select2();
    $(".CustomSelectAnnIncome").attr("data-placeholder", "Income Slab");
    $(".CustomSelectAnnIncome").select2();
    $(".CustomSelectClientRiskProfileId").attr("data-placeholder", "Select your Risk Profile");
    $(".CustomSelectRelationwithmobholder").attr("data-placeholder", "Relationship with Mobile no. Holder");
    $(".CustomSelectRelationwithEmailHolder").attr("data-placeholder", "Relationship with Email Holder");
    $(".CustomSelectClientRiskProfileId").select2();
    $(".CustomSelectRelationwithmobholder").select2();
    $(".CustomSelectRelationwithEmailHolder").select2();
    $('.CustomSelectGender').on('change', (e: any) => this.clientDetailsForm.controls['Gender'].setValue(e.target.value));
    $('.CustomSelectClientState').on('change', (e: any) => this.clientDetailsForm.controls['State'].setValue(e.target.value));
    $('.CustomSelectNomRel').on('change', (e: any) => this.clientDetailsForm.controls['NomineeRelation'].setValue(e.target.value));
    $('.CustomSelectGuarRel').on('change', (e: any) => this.clientDetailsForm.controls['GuardianRelation'].setValue(e.target.value));
    $('.CustomSelectBankName').on('change', (e: any) => this.clientDetailsForm.controls['BankName'].setValue(e.target.value));
    $('.CustomSelectAccType').on('change', (e: any) => this.clientDetailsForm.controls['AccountType'].setValue(e.target.value));
    $('.CustomSelectBankState').on('change', (e: any) => this.clientDetailsForm.controls['BankState'].setValue(e.target.value));
    $('.CustomSelectPoliticalExp').on('change', (e: any) => this.clientDetailsForm.controls['PoliticalExposure'].setValue(e.target.value));
    $('.CustomSelectIdType').on('change', (e: any) => this.clientDetailsForm.controls['IdentificationType'].setValue(e.target.value));
    $('.CustomSelectOccupation').on('change', (e: any) => this.clientDetailsForm.controls['Occupation'].setValue(e.target.value));
    $('.CustomSelectCountry').on('change', (e: any) => this.clientDetailsForm.controls['Country'].setValue(e.target.value));
    $('.CustomSelectAnnIncome').on('change', (e: any) => this.clientDetailsForm.controls['GrossAnnualIncome'].setValue(e.target.value));
    $('.CustomSelectCanRedeem').on('change', (e: any) => this.clientDetailsForm.controls['ClientcanREDEEM'].setValue(e.target.value));
    $('.CustomSelectCanSwitch').on('change', (e: any) => this.clientDetailsForm.controls['ClientcanSWITCH'].setValue(e.target.value));
    $('.CustomSelectNomRelFD').on('change', (e: any) => this.clientDetailsForm.controls['NomineeRelation'].setValue(e.target.value));
    $('.CustomSelectGenderFD').on('change', (e: any) => this.clientDetailsForm.controls['Gender'].setValue(e.target.value));
    $('.POIDocumentSelect').on('change', (e: any) => this.clientDetailsForm.controls['POIDocumentName'].setValue(e.target.value));
    $('.POADocumentSelect').on('change', (e: any) => this.clientDetailsForm.controls['POADocumentName'].setValue(e.target.value));
    $(".clsPOAExpireDate").on('change', (e: any) => this.clientDetailsForm.controls['POAExpire'].setValue(e.target.value));
    $('.CustomSelectClientBank').on('change', (e: any) => this.clientDetailsForm.controls['ClietBankAccount'].setValue(e.target.value));
    $('.CustomSelectClientRiskProfileId').on('change', (e: any) => this.clientDetailsForm.controls['ClientRiskProfileId'].setValue(e.target.value));
    $('.CustomSelectRelationwithmobholder').on('change', (e: any) => this.clientDetailsForm.controls['Relationwithmobholder'].setValue(e.target.value));
    $('.CustomSelectRelationwithEmailHolder').on('change', (e: any) => this.clientDetailsForm.controls['RelationwithEmailHolder'].setValue(e.target.value));
    $('.CustomeselectBirthCountry').on('change', (e: any) => this.clientDetailsForm.controls['BirthCountry'].setValue(e.target.value));
  };

  modalClose() {
    this.bsModalRef.hide();
  }

  CallAlertModal(msg: any) {
    $("#AlertModalprofilemodels").modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#AlertModalContentmodels').text(msg);
  }
  CallUCCAlertModal(msg: any) {
    $("#UCCSuccessModel").modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#AlertUCCSuccessModel').text(msg);
  }
  CallAlertErrorModal(msg: any) {
    $("#ErrorRedirectModel").modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#ErrorRedirectContentmodels').text(msg);
  }
  ageCalculator(dob: any) {

    var txtDOB = dob.split("/");
    var dateString = txtDOB[2] + '/' + txtDOB[1] + '/' + txtDOB[0];
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  IsNomineeMinor(event: any) {
    if (event.target.value != '') {
      this.clientDetailsForm.controls['NomineeDateofBirth'].setValue(event.target.value)
    }
    else {
      this.clientDetailsForm.controls['NomineeDateofBirth'].setValue(this.clientDetailsForm.controls['NomineeDateofBirth'].value);
    }
    let age = this.ageCalculator(event.target.value);
    if (age < 18) {
      this.IsMinor = true;
      this.clientDetailsForm.get('GuardianName').setValidators([Validators.required, Validators.pattern(CharacterANDSpaceOnly)]);
      this.clientDetailsForm.get('GuardianName').updateValueAndValidity();
    }
    else {
      this.IsMinor = false;
      this.clientDetailsForm.get('GuardianName').clearValidators();
      this.clientDetailsForm.get('GuardianName').updateValueAndValidity();
    }
  }
  RelodePage() {
    location.reload();
  }


  GetUCCCode(cbid) {
    this.TransactionService.GetClientUCCDetails(cbid).subscribe(
      (response: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(response))
        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            // this.Loader.hide();
            // this.Logout.LogoutUser();
            return;
          }
          else {
            if (res.ArrayOfResponse.length > 0) {
              this.G_UCCCode = res.ArrayOfResponse[0].ClientCode;
            }
          }
        }
      })
  }


  CheckKYCStatus() {


    let Pan = '', Mobile = '', EmailID = '';
    if (this.OFS_Client == 4) {
      Pan = this.ClientPanNo;
      Mobile = this.MobileNo;
      EmailID = this.Email;
    } else {
      Pan = this.ClientPanNo;
      Mobile = this.MobileNo;
      EmailID = this.Email;
    }
    let PANNo = Pan;//this.EncrdecrService.setEncr(Pan);
    let MobileNo = Mobile;//this.EncrdecrService.setEncr(Mobile);
    this.clientProfileService.CheckAUKYC(PANNo, MobileNo).subscribe(data => {
      if (data != null) {
        if (data.Message == "KYC verified." && data.Message != null) {

          this.clientProfileService.GetGenerateUCC(this.G_ClientBasicInfoId).subscribe(
            (response: any) => {
              let res = JSON.parse(this.EncrdecrService.getDecr(response))
              if (res != null) {
                if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
                  this.Loader.hide();
                  this.Logout.LogoutUser();
                  return;
                }
                else if (res.Message.includes('FILE CONTAIN SPECIAL CHARACTERS')) {
                  this.Loader.hide();
                  this.CallAlertModal('Sorry! Your data contains special character. Please contact your Branch / RM.');
                }
                else {

                  if (res.Code !== "") {
                    this.UCCMessage = res.Message;
                    this.GeneratedUCCCode = res.Code;
                    // if (this.G_UCCCode == null || this.G_UCCCode == undefined || this.G_UCCCode == '' || this.G_UCCCode == '0') {
                    //   this.G_UCCCode = res.Code;
                    // }
                    this.IsUCCGenerated = true;
                    if (this.OFS_Client != 4) {
                      const formData: FormData = new FormData();
                      formData.append('UCCCode', res.Code);
                      formData.append('Flag', 'AOFWithoutSignature');

                      this.clientProfileService.PostAOFForm(formData).subscribe(
                        (result: any) => {

                          if (result != null) {
                            if (result.Message != null && result.Message != '' && result.Message.indexOf('Not Authorized') > -1) {
                              this.Loader.hide();
                              this.Logout.LogoutUser();
                              return;
                            }
                            else {

                              if (result.Message === 'File Uploaded Successfully.') {
                                this.Loader.hide();
                                this.bsModalRef.hide();
                                this.CallAlertModal(this.UCCMessage);
                              }
                              else {
                                this.Loader.hide();
                                this.CallAlertErrorModal(result.Message)
                              }

                            }
                          }
                          else {
                            this.Loader.hide();
                          }
                        },
                        err => {
                          this.Loader.hide();
                        })
                    } else {
                      this.Loader.hide();
                      this.IsAOFUploaded = true;
                      this.CallUCCAlertModal(res.Message);
                    }
                  }
                  else {
                    this.Loader.hide();
                    this.IsUCCGenerated = false;
                    this.CallAlertErrorModal(res.Message);
                  }
                }
              }
              else {
                this.Loader.hide();
              }
            },
            err => {
              this.Loader.hide();
            }
          );
        }
        else {

          this.Loader.hide();
          this.CallAlertErrorModal("UCC Is Not Created As KYC Not Registered with CRA");

        }
      }
      else {
        this.Loader.hide();
        // this.SetActiveTab('GenerateINN');
        this.CallAlertErrorModal("UCC Is Not Created As KYC Not Registered with CRA");

      }
    },
      err => {
        this.Loader.hide();
      });
  }

  GenerateUCC() {
    if (this.IsIFA) {
      $('#GenerateUCCModal').modal({
        backdrop: 'static',
        keyboard: false
      });
      this.clientProfileService.GetGenerateUCC(this.G_ClientBasicInfoId).subscribe(
        (response: any) => {
          let res = JSON.parse(this.EncrdecrService.getDecr(response))
          if (res != null) {
            if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
              this.Loader.hide();
              this.Logout.LogoutUser();
              return;
            }
            else if (res.Message.includes('FILE CONTAIN SPECIAL CHARACTERS')) {
              this.Loader.hide();
              this.CallAlertModal('Sorry! Your data contains special character. Please contact your Branch / RM.');
            }
            else if (res.Message.includes('FAILED: LOGIN PASSWORD EXPIRED. KINDLY RESET LOGIN PASSWORD')) {
              this.Loader.hide();
              this.CallAlertModal('FAILED: LOGIN PASSWORD EXPIRED. KINDLY RESET LOGIN PASSWORD');
            }
            else {
              $('#GenerateUCCModal').modal('hide');
              if (res.Code !== "") {
                this.GeneratedUCCCode = res.Code;
                if (this.G_UCCCode == null || this.G_UCCCode == undefined || this.G_UCCCode == '' || this.G_UCCCode == '0') {
                  this.G_UCCCode = res.Code;
                }
                this.IsUCCGenerated = true;
                if (this.OFS_Client != 4) {
                  const formData: FormData = new FormData();
                  formData.append('UCCCode', this.G_UCCCode);
                  formData.append('Flag', 'AOFWithoutSignature');

                  this.clientProfileService.PostAOFForm(formData).subscribe(
                    (result: any) => {
                      if (result != null) {
                        if (result.Message != null && result.Message != '' && result.Message.indexOf('Not Authorized') > -1) {
                          this.Loader.hide();
                          this.Logout.LogoutUser();
                          return;
                        }
                        else {
                          if (result.Message === 'File Uploaded Successfully.') {
                            this.Loader.hide();
                            this.CallAlertModal('Profile save successfully');
                            this.IsAOFUploaded = true;
                            this.bsModalRef.hide();
                          }
                          else {
                            this.Loader.hide();
                            this.CallAlertErrorModal(result.Message);
                            this.IsAOFUploaded = false;
                          }
                          $('#GenerateUCCInfoModal').modal({
                            backdrop: 'static',
                            keyboard: false
                          });
                        }
                      }
                      else {
                        this.Loader.hide();
                      }
                    },
                    err => {
                      this.Loader.hide();
                    })
                } else {
                  this.Loader.hide();
                  this.IsAOFUploaded = true;
                }
              }
              else {
                this.Loader.hide();
                this.IsUCCGenerated = false;
                $('#GenerateUCCInfoModal').modal({
                  backdrop: 'static',
                  keyboard: false
                });
                //this.CallAlertModal(res.Message);
              }
            }

          }
          else {
            this.Loader.hide();
          }
        },
        err => {
          this.Loader.hide();
        }
      );
      // } else {
      // this.CheckKYCStatus();
      // this.LoadKYC(1); //1 means if Client's KYC verified then UCC generation method directally called other wise UCC Generatio tab Activated
    }
    else {
      this.CheckKYCStatus();
    }


  }

  onSubmitDetails() {

    this.clientDetailsFormSubmitted = true;
    if (this.clientDetailsForm.invalid) {
      this.Loader.hide();

      return;
    } else {
      this.Loader.show();

      if (this.clientDetailsForm.controls['ClientRiskProfileId'].value == '6') {   //5 means Not Sure RiskProfile
        this.PostBasicDetails();
      } else {
        this.AddRiskProfile();
      }
    }
  }
  PostBasicDetails() {
    let Pan = '';
    let TempDataBody = {
      "ClientBasicInfoId": this.G_ClientBasicInfoId,
      "FirstHolderPANNO": this.EncrdecrService.setEncr(Pan),
      //this.EncrdecrService.setEncr(this.BasicDetailsForm.controls['FirstHolderPANNO'].value),
      "SecondHolderPANNO": "",
      "HoldingType": "SI",
      "SubBrokerCode": this.G_SubBrokerCode,
      "EmployeeCode": "",
      "Flag": "FatcaDetails",
      "CreatedBy": "1",
      "CreatedSourceName": "onboarding",
      "FatcaDetails": {
        "First": {
          "OccupationId": this.clientDetailsForm.controls['Occupation'].value,
          "AnnualIncome": this.clientDetailsForm.controls['GrossAnnualIncome'].value,
          "IndentificationType": "C",
          "PoliticalExposure": this.clientDetailsForm.controls['PoliticalExposure'].value,
          "RelationWithMobileNoHolder": this.clientDetailsForm.controls['Relationwithmobholder'].value,
          "RelationWithEmailHolder": this.clientDetailsForm.controls['RelationwithEmailHolder'].value,
          "FATKABirthCountry": this.clientDetailsForm.controls['BirthCountry'].value,
        }
      }
    };
    let DataBody = JSON.stringify(TempDataBody);
    console.log('lineno:772 ' + DataBody)
    this.clientProfileService.PostBasicDetails(DataBody).subscribe(
      (response: any) => {
        let result = JSON.parse(this.EncrdecrService.getDecr(response))
        if (result != null) {
          if (result.Message != null && result.Message != '' && result.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            return;
          }
          if (result.Message.includes('success')) {
            //Nominee Details
            if (this.OFS_Client == 4) {
              Pan = this.ClientPanNo;
              // MobileNo = this.ClientMobileNo;
              // EmailID = this.ClientEmailId;
            }
            else {
              Pan = this.clientDetailsForm.controls['FirstHolderPANNO'].value;
              // MobileNo = this.BasicDetailsForm.controls['MobileNo'].value);
              // EmailID = this.BasicDetailsForm.controls['Email'].value;
            }
            let TempsDataBody = {
              "ClientBasicInfoId": this.G_ClientBasicInfoId,
              "FirstHolderPANNO": this.EncrdecrService.setEncr(Pan),
              //this.EncrdecrService.setEncr(this.BasicDetailsForm.controls['FirstHolderPANNO'].value),
              "SecondHolderPANNO": "",
              "HoldingType": "SI",
              "SubBrokerCode": this.G_SubBrokerCode,
              "EmployeeCode": "",
              "Flag": "NomineeDetails",
              "CreatedBy": "1",
              "CreatedSourceName": "onboarding",
              "NomineeDetails": {
                "First": {
                  "NomineeName": this.clientDetailsForm.controls['NomineeName'].value,
                  "NomineeIsMinor": this.clientDetailsForm.controls['Isnominateminor'].value,
                  "Allocation": "100",
                  "RelationshipId": parseInt(this.clientDetailsForm.controls['NomineeRelation'].value),
                  "GardianName": this.clientDetailsForm.controls['GuardianName'].value,
                  "NomineeDOB": this.clientDetailsForm.controls['NomineeDateofBirth'].value,
                  "NomineePan": this.EncrdecrService.setEncr(this.clientDetailsForm.controls['NomineePAN'].value),
                  "GuardianRelation": parseInt(this.clientDetailsForm.controls['GuardianRelation'].value),
                  // "NomineeAddress": this.clientDetailsForm.controls['NomineeAddress'].value
                }
              }
            };
            let DatasBody = JSON.stringify(TempsDataBody);
            this.clientProfileService.PostBasicDetails(DatasBody).subscribe(
              (response: any) => {
                let result = JSON.parse(this.EncrdecrService.getDecr(response))
                if (result != null) {

                  if (result.Message != null && result.Message != '' && result.Message.indexOf('Not Authorized') > -1) {
                    this.Loader.hide();
                    return;
                  }
                  if (result.Message.includes('success')) {
                    this.Loader.hide();
                    $('#NotSureRiskProfile').modal({
                      backdrop: 'static',
                      keyboard: false
                    });
                  } else {
                    this.Loader.hide();

                    this.CallAlertErrorModal(result.Message);
                  }
                }
                else {
                  this.Loader.hide();
                }
              },
              err => {
                this.Loader.hide();
              });
          }
          else {
            this.Loader.hide();
            this.CallAlertErrorModal(result.Message);
          }
        }
        else {
          this.Loader.hide();
        }
      },
      err => {
        this.Loader.hide();
      });
  }
  AddRiskProfile() {
    let Pan = '';
    let TempDataBody = {
      "ClientBasicInfoId": this.G_ClientBasicInfoId,
      "FirstHolderPANNO": this.EncrdecrService.setEncr(Pan),
      //this.EncrdecrService.setEncr(this.BasicDetailsForm.controls['FirstHolderPANNO'].value),
      "SecondHolderPANNO": "",
      "HoldingType": "SI",
      "SubBrokerCode": this.G_SubBrokerCode,
      "EmployeeCode": "",
      "Flag": "FatcaDetails",
      "CreatedBy": "1",
      "CreatedSourceName": "onboarding",
      "FatcaDetails": {
        "First": {
          "OccupationId": this.clientDetailsForm.controls['Occupation'].value,
          "AnnualIncome": this.clientDetailsForm.controls['GrossAnnualIncome'].value,
          "IndentificationType": "C",
          "PoliticalExposure": this.clientDetailsForm.controls['PoliticalExposure'].value,
          "RelationWithMobileNoHolder": this.clientDetailsForm.controls['Relationwithmobholder'].value,
          "RelationWithEmailHolder": this.clientDetailsForm.controls['RelationwithEmailHolder'].value,
          "FATKABirthCountry": this.clientDetailsForm.controls['BirthCountry'].value,
        }
      }
    };
    let DataBody = JSON.stringify(TempDataBody);
    console.log('884.' + DataBody)
    this.clientProfileService.PostBasicDetails(DataBody).subscribe(
      (response: any) => {
        let result = JSON.parse(this.EncrdecrService.getDecr(response))
        if (result != null) {
          if (result.Message != null && result.Message != '' && result.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            return;
          }
          if (result.Message.includes('success')) {
            //Nominee Details
            if (this.OFS_Client == 4) {
              Pan = this.ClientPanNo;
              // MobileNo = this.ClientMobileNo;
              // EmailID = this.ClientEmailId;
            } else {
              Pan = this.clientDetailsForm.controls['FirstHolderPANNO'].value;
              // MobileNo = this.BasicDetailsForm.controls['MobileNo'].value);
              // EmailID = this.BasicDetailsForm.controls['Email'].value;
            }
            let TempsDataBody = {
              "ClientBasicInfoId": this.G_ClientBasicInfoId,
              "FirstHolderPANNO": this.EncrdecrService.setEncr(Pan),
              //this.EncrdecrService.setEncr(this.BasicDetailsForm.controls['FirstHolderPANNO'].value),
              "SecondHolderPANNO": "",
              "HoldingType": "SI",
              "SubBrokerCode": this.G_SubBrokerCode,
              "EmployeeCode": "",
              "Flag": "NomineeDetails",
              "CreatedBy": "1",
              "CreatedSourceName": "onboarding",
              "NomineeDetails": {
                "First": {
                  "NomineeName": this.clientDetailsForm.controls['NomineeName'].value,
                  "NomineeIsMinor": this.IsMinor,
                  "Allocation": "100",
                  "RelationshipId": parseInt(this.clientDetailsForm.controls['NomineeRelation'].value),
                  "GardianName": this.clientDetailsForm.controls['GuardianName'].value,
                  "NomineeDOB": this.clientDetailsForm.controls['NomineeDateofBirth'].value,
                  "NomineePan": this.EncrdecrService.setEncr(this.clientDetailsForm.controls['NomineePAN'].value),
                  "GuardianRelation": parseInt(this.clientDetailsForm.controls['GuardianRelation'].value),
                  // "NomineeAddress": this.clientDetailsForm.controls['NomineeAddress'].value
                }
              }
            };
            let DatasBody = JSON.stringify(TempsDataBody);
            this.clientProfileService.PostBasicDetails(DatasBody).subscribe(
              (response: any) => {
                let result = JSON.parse(this.EncrdecrService.getDecr(response))
                if (result != null) {
                  if (result.Message != null && result.Message != '' && result.Message.indexOf('Not Authorized') > -1) {
                    this.Loader.hide();
                    return;
                  }
                  if (result.Message.includes('success')) {
                    //Risk Profile

                    let request = {
                      "ClientbasicinfoId": this.G_ClientBasicInfoId.toString(),
                      "Trans_Type": "UpdateMstRiskProfileId",
                      "ClientRiskProfileId": this.clientDetailsForm.controls['ClientRiskProfileId'].value,
                    }
                    let DataaBody = JSON.stringify(request);

                    this.NseOnboardingService.AddRiskProfile(DataaBody).subscribe(
                      (result: any) => {
                        if (result.Message == "Success") {
                          this.GenerateUCC()
                        }
                        else {
                          this.Loader.hide();
                          this.CallAlertErrorModal("Profile not Saved");
                        }
                      },
                      err => {
                        this.Loader.hide();
                      });
                  }
                  else {
                    this.Loader.hide();
                    this.CallAlertErrorModal(result.Message);
                  }
                }
                else {
                  this.Loader.hide();
                }
              },
              err => {
                this.Loader.hide();
              });
          }
          else {
            this.Loader.hide();
            this.CallAlertErrorModal(result.Message);
          }
        }
      },
      err => {
        this.Loader.hide();
      });
  }
  IsNomineeMinorClick(e) {

    var checked = e.target.checked;
    if (checked) {
      this.isGuardianNameshow = true;
      this.isGuardianRelationshow = true;
      this.clientDetailsForm.controls['GuardianName'].setValidators([Validators.required]);
      this.clientDetailsForm.controls['GuardianName'].updateValueAndValidity();
      this.clientDetailsForm.controls['GuardianRelation'].setValidators(Validators.required);
      this.clientDetailsForm.controls['GuardianRelation'].updateValueAndValidity();
    } else {
      this.isGuardianNameshow = false;
      this.isGuardianRelationshow = false;
      this.clientDetailsForm.controls['GuardianName'].clearValidators();
      this.clientDetailsForm.controls['GuardianName'].updateValueAndValidity();
      this.clientDetailsForm.controls['GuardianRelation'].clearValidators();
      this.clientDetailsForm.controls['GuardianRelation'].updateValueAndValidity();
    }
  }
  OnContinueClick() {
    sessionStorage.setItem(
      "N1QJHdOkO7Hz8QC",
      this.EncrdecrService.setEncr(this.G_ClientBasicInfoId)
    );
    this.bsModalRef.content.action.next(true);
    this.bsModalRef.hide();
  }
  OpenTermsModal(TermsAndCondition) {
    sessionStorage.setItem('JIhgy76J7=8j+', 'pop');
    this.modalService.open(TermsAndCondition);
  }
  Oncheckboxclick(e) {
    if (e.target.checked) {
      this.checked = false;
    }
    else {
      this.checked = true;
    }

  }

  ValidatePattern(e) {


    const k = (e.which) ? e.which : e.keyCode;
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32) {

    }
    else {
      e.preventDefault();
    }

  }
  CloseAndRedirect() {
    this.modalClose();
    this.router.navigate(["/dashboard"], {
      relativeTo: this.route,
    });
  }
  HideOnePager() {
    this.bsModalRef.hide();
  }
  GetFatcaRelationshiplist() {
    this.clientProfileService.GetRelationwithholderlist().subscribe(
      (data_res: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(data_res));

        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            this.Logout.LogoutUser();
            return;
          }
          else {
            setTimeout(() => {
              this.RelationListwithHolder = res.ArrayOfResponse;
              this.Loader.hide();
            }, 2000);
          }
        }
      },
      err => {
        this.Loader.hide();
      });
  }
  ValueMasking(value, Flag) {

    let MaskValue = "";
    if (Flag == 'Mobile') {
      MaskValue = value.replace(/\d(?=\d{4})/g, "X");
    }
    else if (Flag == 'PAN') {
      MaskValue = value.substring(0, 1) + 'XXXXXXX' + value.substring(8); //display only first and last two charcter
    }
    else if (Flag == 'Email') {
      let index = value.indexOf('@');
      MaskValue = value.substring(0, 2) + 'XXXXXXXX' + value.substring(index, value.length);// - 1
    }
    else if (Flag == 'AccNo') {
      MaskValue = 'XXXXXXXX' + value.substring(value.length - 4);
    }
    return MaskValue;
  }
  BindCountry() {

    this.clientProfileService.GetCountryList().subscribe(
      (data_res: any) => {
        let res = JSON.parse(this.EncrdecrService.getDecr(data_res));

        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            this.Logout.LogoutUser();
            return;
          }
          else {
            let a = res.ArrayOfResponse;
            let b = a.slice(1);
            this.CountryList = b;
          }
        }
      });
  }
  SkipNomineeDetails() {
    this.CallNomineeSkipModal('You have chosen to opt-out from nomination. As per SEBI guidelines, you will need to submit a physical declaration for each folio with respective AMC for opting out of nomination. In case this declaration is not provided, redemptions will not be allowed from the scheme. Please reach out to your nearest branch for the declaration form.');
    //this.SetActiveTab('BankDetails');
  }
  CallNomineeSkipModal(msg: any) {
    $("#OnePagerNomineeDeclarationModel").modal({
      backdrop: 'static',
      keyboard: false
    });
    $('#OnePagerNomineeDeclarationModelContentmodels').text(msg);
  }
  NomineeDeclaration() {
    const request = {
      ClientBasicInfo: this.G_ClientBasicInfoId,
      NomineeDeclarationFlag: 'Yes'
    }
    this.clientProfileService.UpdateNomineeDeclaration(JSON.stringify(request)).subscribe((response) => {

      let res = JSON.parse(this.EncrdecrService.getDecr(response))
      if (res.ID == 0) {
        this.CallAlertModal(res.Message)
      }
    }, (error) => {
      this.Loader.hide();
    });
  }

  BindRiskProfileList() {

    this.clientProfileService.GetRiskProfileDetails("getRiskProfileListForOnePager", '').subscribe((data_res) => {
      let res_str = this.EncrdecrService.getDecr(data_res);
      let data = JSON.parse(res_str);
      if (data.ArrayOfResponse.length > 0) {
        this.RiskProfileList = data.ArrayOfResponse;
      }

    })

  }
}
