<div class="Main_{{Companyname}}" appDisabledRightClick>
  <app-header-jm (isCollapseMenu)="FnIsCollapseSideMenu($event)"></app-header-jm>
  <router-outlet></router-outlet>
</div>
<div class="modal" id="AlertModal" appDisabledRightClick>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">Important Message</div>
      <div id="AlertModalContent" class="modal-body AlertModalBody"></div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" data-dismiss="modal" class="CursorPointer">Okay</button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="SessionModal" appDisabledRightClick>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">Important Message</div>
      <div class="modal-body AlertModalBody">
        Your session has been expired.
      </div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" data-dismiss="modal" class="CursorPointer"
          routerLinkActive='active'>Okay</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="CommonInfoModal" appDisabledRightClick>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">Important Message</div>
      <div id="InfoModalContent" class="modal-body AlertModalBody InfoModal"></div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" data-dismiss="modal" class="CursorPointer">Okay</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="NotAuthmodal" appDisabledRightClick>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">Important Message</div>
      <div id="NotAuthmodalContent" class="modal-body AlertModalBody"></div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" data-dismiss="modal" class="CursorPointer" routerLink="/employee-dashboard"
          routerLinkActive="active">Okay</button>
      </div>
    </div>
  </div>
</div>


<a href="javascript:" id="return-to-top" appDisabledRightClick><img
    src="../{{CommonBaseHref}}assets/images/icon-arrow-up.svg" /></a>

<div *ngIf="Loader.visible" appDisabledRightClick>
  <div class="overlay overlay_white">
    <img [src]='loader' [style.width]="loaderWidth" [style.height]="loaderHeight" />
  </div>
</div>


<div class="modal" id="RedirectDashBoardModal" data-backdrop="static" data-keyboard="false" appDisabledRightClick>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content AlertModalContentCls">
      <div class="modal-header AlertModalHeader">Important Message</div>
      <div id="RedirectDashBoardModalContent" class="modal-body AlertModalBody"></div>
      <div class="modal-footer AlertModalFooter">
        <button type="button" data-dismiss="modal" class="CursorPointer" (click)="RedirectTODashBoard()">Okay</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="DisableTranAlertModal1" appDisabledRightClick>
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content AlertModalContentCls">
          <div class="modal-header AlertModalHeader">Welcome</div>
          <div class="modal-body AlertModalBody">
              <!-- <span *ngIf="UserType==3">
                  To begin your wealth creation journey, set up your account by clicking the button below.
              </span>
              <span *ngIf="UserType!=3"> -->
                  Please complete profile to enable online MF transactions.
              <!-- </span> -->
          </div>
          <div class="modal-footer AlertModalFooter">
              <button type="button" data-dismiss="modal" class="CursorPointer" (click)='GoToClientProfile()'>
                Complete Profile</button>
          </div>
      </div>
  </div>
</div>
