import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { LoginService } from '../services/login.service';
import { MainHeaderMenuService } from '../services/main-header-menu.service';
import { EncrdecrService } from './encrdecr.service';
import { CustomLoaderService } from '../services/custom-loader.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private EncrdecrService: EncrdecrService, private authService: AuthService, private router: Router, public ShowHideHeaderMenu: MainHeaderMenuService, private LoginService: LoginService, private Loader: CustomLoaderService,) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    let ClientID = next.queryParams.Client;
    if (ClientID) {

      let res = JSON.parse(this.EncrdecrService.getDecr(ClientID.toString().replace(/\s/g, '+').toString()));
      if (res != '' && res != null && res != undefined) {
        sessionStorage.setItem('5V5uSYBoBVqAvBIBqUEBhv6ELFF', this.EncrdecrService.setEncr("1"));
        sessionStorage.setItem('YKHkxDnoSoOe6PdTjKrqfm4z6', this.EncrdecrService.setEncr("19"));
        sessionStorage.setItem('`huR,8@RK9^??b4 -.hfs!!uR,XX', res.TOKEN_ID);
        sessionStorage.setItem('F44sGAGh2xwkpUL', res.LOGIN_ID); // Session_SubBrokerId
        sessionStorage.setItem('V4WGROuLLBE6t98', res.USER_NAME); // Session_UserName
        sessionStorage.setItem('V8hpPfITcxhEI9x', res.EMAIL_ID); // Session_UserEmail
        sessionStorage.setItem('3erT@Qr65TdXwq1', res.LOGIN_ID);//Added by Bhushan
        sessionStorage.setItem('Xe003SRRyYEoOC2', res.MOBILE); // Session_UserMobile
        sessionStorage.setItem('Hldq31TLYwRbLJ8', res.SubBrokerCode); // Session_SubBrokerCode
        sessionStorage.setItem('L2D3506kIHSk3E0', res.USER_TYPE); // Session_UserType
        sessionStorage.setItem('qAkZrQYLWNBSlki', res.LOGIN_ID); // Session_CBId
        sessionStorage.setItem('pi4ZU2P3M8JE4q7', ''); // Session_B64CompanyLogo
        sessionStorage.setItem('hn0bHyGhfpGtrPe', res.Family_ID);  // Session_Family_ID
        sessionStorage.setItem('m5JkoXISmYRAIuY', res.USER_ID); // Session_UserId
        sessionStorage.setItem('eraevrrttrgsrde', res.USER_ID);
        sessionStorage.setItem('UZT6qHaDZSz66kx', res.LOGIN_ID); // Session_ClientBasicInfoId
        sessionStorage.setItem('N1QJHdOkO7Hz8QC', res.LOGIN_ID);
        sessionStorage.setItem('ySxterqMDG7RY2qTpuzrfA', this.EncrdecrService.setEncr("1"));
        sessionStorage.setItem('AqVk7klfa+b0nlVjO8Y90I=', res.RiskProfile); //Aggressive/Moderate etc
        sessionStorage.setItem('aaIHl0pIiYe71gyyxC/5xA==', res.RiskProfileStatus);//Active or Expired
        sessionStorage.setItem('LWHTWnSDkv4+qaQXsO6tHYU=', res.RiskProfileLevel); //risk Level of client
        sessionStorage.setItem('6l0NqN5kkIZCaDvdzUfYVK8g=', res.RiskProfileInsertedDate);
        sessionStorage.setItem("kioh298hKh2kj", res.CorpIFDStatus);//CorpIFDFlag -- for corporate IFD
        sessionStorage.setItem("23khokl2k35hl", res.PrimarySBCode);//PrimarySubBrokerCode -- for corporate IFD
        sessionStorage.setItem("ZhdfjU423HG878", res.IsChildSB); //IsChildSB : Boolean Value -- Added by Saddam
        sessionStorage.setItem('pOWgAF62qFXnZMn', res.RazorCustId); //RazorCustId 
        sessionStorage.setItem('currentstatus', res.CurrentStatus);
        sessionStorage.setItem('nbj%98LS74nkjsn~@&4njrkd`!kd*294', res.RazorePaymentStatus);
        sessionStorage.setItem('5gE4QWzFXWKewRC', res.ROLEID); // Role_ID
        sessionStorage.setItem('sdjrubvfaoieen', "True"); // mobile login

        try {
          var G_UserType = parseInt(this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0')));
          if (G_UserType == 3) {            
            window.location.href = '/dashboard';
          }
          else if (G_UserType == 2) {            
            window.location.href = '/dashboard';
          }
          else {            
            window.location.href = '/dashboard';
          }

        } catch (error) {

        }
      }
      else {
        window.location.href = '/login-corp';
      }
    }
    else {
      const token = this.authService.isAuthenticated();
      if (token) {
        this.ShowHideHeaderMenu.show();
        return true;
      }
      else {
        this.ShowHideHeaderMenu.hide();
        return this.router.parseUrl("/login-corp");
      }
    }
  }
}
