import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { EncrdecrService } from '../../Auth/encrdecr.service';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-riskprofile-model',
  templateUrl: './riskprofile-model.component.html',
  styleUrls: ['./riskprofile-model.component.scss']
})
export class RiskprofileModelComponent implements OnInit {

  constructor(
    private BsModalService: BsModalService,
    private bsModalRef: BsModalRef,
    private modalService: NgbModal,
    private EncrdecrService: EncrdecrService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  EnableRiskProfile:string='';
  RiskProfileStatus:string='';
  UserType:string='';
  RiskProfile:string='';
  LastRPUpdateDate:string='';
  ngOnInit() {
    if(sessionStorage.getItem('cIe+MhBUozWO3+mKZd2to=')!=null && sessionStorage.getItem('cIe+MhBUozWO3+mKZd2to=')!=undefined){

      this.EnableRiskProfile= sessionStorage.getItem('cIe+MhBUozWO3+mKZd2to=');
      this.RiskProfileStatus= this.EncrdecrService.getDecr(sessionStorage.getItem('aaIHl0pIiYe71gyyxC/5xA=='));
      this.RiskProfile=this.EncrdecrService.getDecr(sessionStorage.getItem('AqVk7klfa+b0nlVjO8Y90I='));
      this.LastRPUpdateDate= this.EncrdecrService.getDecr(sessionStorage.getItem('6l0NqN5kkIZCaDvdzUfYVK8g='))
    }

    if (sessionStorage.getItem('L2D3506kIHSk3E0') != undefined
      && sessionStorage.getItem('L2D3506kIHSk3E0') != null) {
        this.UserType = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    }
  }

  HideRiskModalPopup(){
    this.bsModalRef.hide();
  }

  CallClientProfile(){
    sessionStorage.setItem("J1hcKSsVOAGi13tEWI9TVtZYRzE=",'RiskProfile')
    this.router.navigate(["/client-profile"], {
      relativeTo: this.route,
    });
    this.bsModalRef.hide();
  }

}
