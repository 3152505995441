import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConvertToJSONService } from './convert-to-json.service';
import { ClientService } from './client.service';
const headers = { headers: new HttpHeaders({ 'content-type': 'application/json' }) }

@Injectable({
  providedIn: 'root'
})
export class HdfcSsoService {

  ServerUrl = environment.ServerUrl;
  companyName = environment.CompayName;


  constructor(
    private http: HttpClient,
    private conjson: ConvertToJSONService,
    private client: ClientService,

  ) { }


  // let Value = JSON.parse(data);
  // let url = `${this.ServerUrl}api/HDFCSSO/HDFCSSODetails?USER_ID=${Value.pan}&TOKEN_ID=${Value.Token}`;
  // const QueryString = url.split('?')[1];
  // const body = this.conjson.QueryStringToJSON(QueryString);
  // const req = JSON.stringify(body)


  Validateclient(data: any) {
    debugger;
    let s_url = `${this.ServerUrl}api/HDFCSSO/HDFCSSODetails`;
    const req = JSON.stringify(data)
    return this.http.post<any>(s_url, data, headers).pipe();
  }

  CheckToken(data: any) {
    const token = this.client.GetToken();
    const url = `${this.ServerUrl}api/HDFCSSO/HDFCSSODetails`;
    return this.http.post<any>(url, data, token);
  }
}


// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';
// import { ConvertToJSONService } from './convert-to-json.service';
// const headers = { headers: new HttpHeaders({ 'content-type': 'application/json' }) }
// @Injectable({
//   providedIn: 'root'
// })
// export class NsdlSsoService {

//   ServerUrl = environment.ServerUrl;
//   CompanyName=environment.CompayName;

//   constructor(
//     private http: HttpClient,
//     ) { }

//   Validateclient(data:any){
//     let s_url = `${this.ServerUrl}api/NSDLSSO/NSDLSSODetails`;
//     return this.http.post<any>(s_url, data,headers).pipe();
//   }
// }
