<!-- <app-header-jm></app-header-jm> -->
<div class="row">
    <div class="col-12">
      <div class='w-100'>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12 InputBoxOuter" *ngIf="isShowClientFilter">
            <div class="CheckAgree">
              <label class="Cbx_container">
                <input type="radio" [checked]="FilterType=='byClient'" name='RdbReportTypeEmp' value="byClient"
                  (change)="onCheck('byClient')">
                <span class="checkmark"></span>
                <label>
                  &nbsp;&nbsp;Search by Investor
                </label>
              </label>
            </div>
          </div>
          <div [ngClass]="UserType =='1' && isShowEmpSubBrokerFilter ? 'd-flex' : 'd-none'"
            class="col-lg-3 col-md-4 col-sm-12 col-xs-12 InputBoxOuter">
            <div class="CheckAgree">
              <label class="Cbx_container">
                <input type="radio" name='RdbReportTypeEmp' [checked]="FilterType=='byEmpSubroker'" value="byEmpSubroker"
                  (change)="onCheck('byEmpSubroker')">
                <span class="checkmark"></span>
                <label>
                  &nbsp;&nbsp;Search by Employee
                </label>
              </label>
            </div>
          </div>
          <div [ngClass]="UserType=='1' && isShowHiearchyFilter ? 'd-flex' : 'd-none'"
            class="col-lg-3 col-md-4 col-sm-12 col-xs-12 InputBoxOuter BtmPad">
            <div class="CheckAgree">
              <label class="Cbx_container">
                <input type="radio" name='RdbReportTypeEmp' [checked]="FilterType=='byHierachy'" value="byHierachy"
                  (change)="onCheck('byHierachy')">
                <span class="checkmark"></span>
                <label>
                  &nbsp;&nbsp;Search by Hierarchy
                </label>
              </label>
            </div>
          </div>
        </div>
      </div>
      <form autocomplete="off" [formGroup]="clientFilterForm">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter"
            [ngClass]="ChildSubbrokerData !==undefined && UserType == '2' && ChildSubbrokerData.length>0 && IsShowSubbroker ? 'd-block' : 'd-none'">
            <div class="has-float-label">
              <select class="form-control CommonSelectBox TbxMarginBtm ChildSubbrokerSelect"
                formControlName="ChildSubbrokerId">
                <option value="" disabled='true'>Child Subbroker/RM Name</option>
                <option *ngFor="let item of ChildSubbrokerData" [value]="item.SubbrokerId">
                  {{item.SubbrokerName}}</option>
              </select>
              <label>Child Subbroker/RM Name</label>
  
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter"
            [ngClass]="isdisplayFamilyName ? 'd-block' : 'd-none'">
            <div class="has-float-label">
              <select data-placeholder="Select Family" class="form-control CommonSelectBox TbxMarginBtm FamilySelect"
                formControlName="FamilyId">
                <option value="" >Select Family</option>
                <option value="Deselect">No Family selected</option>
                <option *ngFor="let item of FamilyData" [value]="item">
                  {{item}}</option>
              </select>
              <label>Family Name</label>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter"
            [ngClass]="isDisplayClientName ? 'd-block' : 'd-none'">
            <div class="has-float-label">
              <div [ngClass]="isMultiSelect ? 'd-none' : 'd-block'">
                <select data-placeholder="Select Investor" class="form-control CommonSelectBox TbxMarginBtm ClientSelect"
                  formControlName="ClientBasicInfoId">
                  <option value="" disabled='true' selected>Select Investor</option>
                  <option *ngFor="let item of ClientData" [value]="item.ClientBasicInfoId">
                    {{item.clientname}}</option>
                </select>
               
              </div>
              <div [ngClass]="isMultiSelect ? 'd-block' : 'd-none'">
                <ng-multiselect-dropdown-angular7 [ngModelOptions]="{standalone: true}" id="ddlClient"
                  [placeholder]="'Select Investor Name'" [data]="ClientData" [(ngModel)]="selectedItems"
                  [settings]="ClientDataSetting" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)"
                  (onSelectAll)="onItemSelectDeselectAll($event,0)" (onDeSelectAll)="onItemSelectDeselectAll($event,1)">
                </ng-multiselect-dropdown-angular7>
              </div>
              <label>Investor Name</label>
            </div>
          </div>
  
        </div>
        <div class="w-100">
          <div class='w-100'>
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter"
                [ngClass]="isDisplayEmployeeName ? 'd-block' : 'd-none'">
                <div class="has-float-label">
                  <select class="form-control CommonSelectBox TbxMarginBtm EmployeeSelect" formControlName="EmpId">
                    <option value="" disabled='true'>Select Employee</option>
                    <option *ngFor="let item of EmployeeData" [value]="item.EmpId">
                      {{item.EmpFirstName}}</option>
                  </select>
                  <label>Employee Name</label>
                  <!-- <div *ngIf="l.ClientBasicInfoId.errors" class="invalid-feedback">
                <div *ngIf="l.ClientBasicInfoId.errors.required">Please select client
                </div>
              </div> -->
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter"
                [ngClass]="isDisplaySubBrokerName ? 'd-block' : 'd-none'">
                <div class="has-float-label">
                  <select class="form-control CommonSelectBox TbxMarginBtm SubbrokerSelect" formControlName="SubbrokerId">
                    <option value="" disabled='true'>Select Subbroker</option>
                    <option *ngFor="let item of SubbrokerData" [value]="item.SubbrokerId">
                      {{item.SubbrokerName}}</option>
                  </select>
                  <label>Subbroker Name</label>
                 
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter"
                [ngClass]="isDisplaySubBrokerName && ChildSubbrokerData !==undefined && ChildSubbrokerData.length>0 ? 'd-block' : 'd-none'">
                <div class="has-float-label">
                  <select class="form-control CommonSelectBox TbxMarginBtm ChildSubbrokerSelect"
                    formControlName="ChildSubbrokerId">
                    <option value="" disabled='true'>Child Subbroker/RM Name</option>
                    <option *ngFor="let item of ChildSubbrokerData" [value]="item.SubbrokerId">
                      {{item.SubbrokerName}}</option>
                  </select>
                  <label>Child Subbroker/RM Name</label>
                
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter"
                [ngClass]="isDisplayEmpSubBrokerFamilyName  ? 'd-block' : 'd-none'">
                <div class="has-float-label">
                  <select class="form-control CommonSelectBox TbxMarginBtm FamilySelect" formControlName="FamilyId">
                    <option value="">Select Family</option>
                    <option value="Deselect">No Family selected</option>
                    <option *ngFor="let item of FamilyData" [value]="item">
                      {{item}}</option>
                   
                  </select>
                  <label>Family Name</label>
                
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter"
                [ngClass]="isDisplayEmpSubClientName ? 'd-block' : 'd-none'">
                <div class="has-float-label">
                  <div [ngClass]="isMultiSelect ? 'd-none' : 'd-block'">
                    <select class="form-control CommonSelectBox TbxMarginBtm ClientSelect"
                      formControlName="ClientBasicInfoId">
                      <option value="" disabled='true'>Select Investor</option>
                      <option *ngFor="let item of ClientData" [value]="item.ClientBasicInfoId">
                        {{item.clientname}}</option>
                    </select>
                   
                  </div>
                  <div [ngClass]="isMultiSelect ? 'd-block' : 'd-none'">
                    <ng-multiselect-dropdown-angular7 [ngModelOptions]="{standalone: true}" id="ddlClient"
                      [placeholder]="'Select Investor Name'" [data]="ClientData" [(ngModel)]="selectedItems"
                      [settings]="ClientDataSetting" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)"
                      (onSelectAll)="onItemSelectDeselectAll($event,0)"
                      (onDeSelectAll)="onItemSelectDeselectAll($event,1)">
                    </ng-multiselect-dropdown-angular7>
                  </div>
                  <label>Investor Name</label>
                </div>
              </div>
  
            </div>
            <div class="row" [ngClass]="(FilterType =='byHierachy' && UserType=='1') ? 'd-flex' : 'd-none'">
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter">
                <div class="has-float-label">
                  <select class="form-control CommonSelectBox TbxMarginBtm HierarchySelect" formControlName="HierarchyId">
                    <option value="" disabled='true'>Select Hierarchy</option>
                    <option *ngFor="let item of HierarchyData" [value]="item.HierarchyName">
                      {{item.HierarchyName}}</option>
                  </select>
                  <label>Hierarchy Name</label>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter">
                <div class="has-float-label">
                  <select class="form-control CommonSelectBox TbxMarginBtm PlaceSelect" formControlName="PlaceId">
                    <option value="" disabled='true'>Select Entity</option>
                    <option *ngFor="let item of PlaceDataALL" [value]="item.PlaceName">
                      {{item.PlaceName}}</option>
                  </select>
                  <label>Entity Name</label>
                  <!-- <div *ngIf="l.ClientBasicInfoId.errors" class="invalid-feedback">
                <div *ngIf="l.ClientBasicInfoId.errors.required">Please select client
                </div>
              </div> -->
                </div>
              </div>
  
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter">
                <div class="has-float-label">
                  <select class="form-control CommonSelectBox TbxMarginBtm EmployeeSelect" formControlName="EmpId">
                    <option value="" disabled='true'>Select Employee</option>
                    <option *ngFor="let item of EmployeeDataH" [value]="item.EmpId">
                      {{item.EmpFirstName}}</option>
                  </select>
                  <label>Employee Name</label>
                  <!-- <div *ngIf="l.ClientBasicInfoId.errors" class="invalid-feedback">
              <div *ngIf="l.ClientBasicInfoId.errors.required">Please select client
              </div>
            </div> -->
                </div>
              </div>
  
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter">
                <div class="has-float-label">
                  <select class="form-control CommonSelectBox TbxMarginBtm SubbrokerSelect" formControlName="SubbrokerId">
                    <option value="" disabled='true'>Select Subbroker</option>
                    <option *ngFor="let item of SubbrokerData" [value]="item.SubbrokerId">
                      {{item.SubbrokerName}}</option>
                   
                  </select>
                  <label>Subbroker Name</label>
                  
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter"
                [ngClass]="ChildSubbrokerData !==undefined && ChildSubbrokerData.length>0 ? 'd-block' : 'd-none'">
                <div class="has-float-label">
                  <select class="form-control CommonSelectBox TbxMarginBtm ChildSubbrokerSelect"
                    formControlName="ChildSubbrokerId">
                    <option value="" disabled='true'>Child Subbroker/RM Name</option>
                    <option *ngFor="let item of ChildSubbrokerData" [value]="item.SubbrokerId">
                      {{item.SubbrokerName}}</option>
                  </select>
                  <label>Child Subbroker/RM Name</label>
                  
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 InputBoxOuter" *ngIf="HierarchyClient">
                <div class="has-float-label">
                  <div [ngClass]="isMultiSelect ?  'd-none' : 'd-block'">
                    <select class="form-control CommonSelectBox TbxMarginBtm ClientSelect"
                      formControlName="ClientBasicInfoId">
                      <option value="" disabled='true'>Select Investor</option>
                      <option *ngFor="let item of ClientData" [value]="item.ClientBasicInfoId">
                        {{item.clientname}}</option>
                    </select>
                  </div>
                  <div [ngClass]="isMultiSelect ? 'd-block' : 'd-none'">
                    <ng-multiselect-dropdown-angular7 [ngModelOptions]="{standalone: true}" id="ddlClient"
                      [placeholder]="'Select Investor Name'" [data]="ClientData" [(ngModel)]="selectedItems"
                      [settings]="ClientDataSetting" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemSelect($event)"
                      (onSelectAll)="onItemSelectDeselectAll($event,0)"
                      (onDeSelectAll)="onItemSelectDeselectAll($event,1)">
                    </ng-multiselect-dropdown-angular7>
                  </div>
                  <label>Investor Name</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  