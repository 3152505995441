import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MainHeaderMenuService {
  private ShowHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {}
  get isShowHeader() {
    return this.ShowHeader.asObservable();
  }
  hide() {
    this.ShowHeader.next(false);
  }
  show() {
    this.ShowHeader.next(true);
  }
}
