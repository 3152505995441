import { Component, HostListener, OnInit } from '@angular/core';
import { ClientDataService } from '../services/client-data.service';
declare var $:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  CompanyName:string = '';
  IsMobile:boolean = false;
  constructor(private ClientDataService:ClientDataService,) { }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.SetViewPort(event.target.innerWidth)
  }
  ngOnInit() {
    this.CompanyName = this.ClientDataService.GetCompanyName();
    this.SetViewPort(window.innerWidth);
  }
  OpenContactUs() {
    // $(".modal").modal('hide');
    $("#ContactUsModal").modal({
      backdrop : 'static',
      keyboard : false
    });
  }
  SetViewPort(w){
    if(w <= 767){
      this.IsMobile = true;
    }
    else{
      this.IsMobile = false;
    }
  }
  ShowDisclaimer(){
    $("#DisclaimerModal").modal({
      backdrop:'static',
      keyboard:false
    });
  }
}
